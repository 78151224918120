import BaseForm from '../../infraestructure/BaseForm'
import AuthorizationViewModel from '../../../core/features/authorization/AuthorizationViewModel';

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            whiteLabelSettings: null,
            formData: {
                username: '',
                password: ''
            },
            isLoading: false,
            rules: {
                username: [
                    { required: true, message: 'Usuario/Correo requerido.', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Contraseña requerido.', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    created() {
        this.viewModel = new AuthorizationViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindLoginView()
        },
        bindLoginViewResponse(response) {
            this.setWhiteLabelSettings(response.data)
            this.whiteLabelSettings = this.getWhiteLabelSettings()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.isLoading = true
                    this.viewModel.doLogin(this.formData)
                    return true
                }
                return false
            })
        },
        onLoginResponse(response) {
            this.setToken(response.data.token);
            this.viewModel.getCurrentSession();
        },
        onFinCurrentSessionResponse(response) {
            this.setSession(response.data);
            this.setProfilePermissions(response.data);
            
            setTimeout(() => {
                this.isLoading = false
            }, 400);
            setTimeout(() => {
                this.$router.go({ name: "ModuleHomeView" });
            }, 600);
        }
    }
}