import { EventBus } from '../../../core/helpers/EventsHelper';
import BaseForm from '../../infraestructure/BaseForm'
import ProductViewModel from '../../../core/features/products/ProductsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Products',
            viewModel: null,
            collectionProductQr: [],
            brands: [],
            productCategories: [],
            productPackingTypes: [],
            productPackingUnits: [],
            warehouses: [],
            formData: {
                nameFilter: '',
                descriptionFilter: '',
                skuIntFilter: '',
                skuFilter: '',
                packingTypeFilter: '',
                packingUnitFilter: '',
                categoryFilter: '',
            },
            modalProductQr: {
                isVisible: false,
                isLoading: false,
                title: 'QRS DE PRODUCTOS',
                onAcceptProductQr: 'onAcceptProductQr'
            },
            pdfFileName: null,
            batchGroups: [],
            batchPosition: 0,
            pdfStrings: [],
            rules: {}
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ProductViewModel(this)
        this.viewModel.bindFormProductQrView()
    },
    methods: {
        onSearch() {
            this.$refs['formData'].validate((valid) => {
                if (valid) {
                    this.viewModel.findProductQrProductCollection();
                }
            });
        },
        onFindProductQrProductResponse(response) {
            this.collectionProductQr = response;
        },
        onFindProductCategories(data) {
            this.viewModel.findProductCategories(`Name=${data}`)
        },
        onFindProductCategoriesResponse(response) {
            this.productCategories = response.data;
        },
        onFindProductPackingUnits(data) {
            this.viewModel.findProductPackingUnits(`Name=${data}`)
        },
        onFindProductPackingUnitsResponse(response) {
            this.productPackingUnits = response.data;
        },
        onFindProductPackingTypes(data) {
            this.viewModel.findProductPackingTypes(`Name=${data}`)
        },
        onFindProductPackingTypesResponse(response) {
            this.productPackingTypes = response.data;
        },
        onAccept() {
            this.formData = null;
            EventBus.$emit(this.conf.onAcceptProductQr, null);
        },
        onPrintPdf() {
            this.conf.isLoading = true
            this.viewModel.printProductQrToPdf(this.viewModel.getQueryParameters(this.filters))
        },
        onProductQrToPdfResponse(response) {
            this.pdfFileName = response.fileName
            this.batchGroups = this.viewModel.getRequestProductQrsChunks(response.data)
            if(this.batchGroups.length > 0){
                this.viewModel.batchProductsQr(this.batchGroups[this.batchPosition])
            } else {
                this.conf.isLoading = false
                this.notifyWarning('No se encontraron registros con los parametros de búsqueda seleccionados.')
            }
        },
        async onProductBatchResponse(response){  
            this.batchPosition += 1
            this.pdfStrings.push(response.data)
            if(this.batchGroups.length <= this.batchPosition){
                this.batchPosition = 0
                await this.viewModel.mergePdfFiles(this.pdfStrings, this.pdfFileName, () => {
                    this.conf.isLoading = false
                    this.notifySuccess("Etiquetas generadas.")
                    this.pdfStrings = []
                });
            } else {
                this.viewModel.batchProductsQr(this.batchGroups[this.batchPosition])
            }
        },
        onProductQrToPdfError(error){
            this.conf.isLoading = false
            this.onError(error)
        },
        onFilterChange(data, field) {
            this.addFilter(data, field, '=');
        },
        mapRiskLabel(criticalLavelStatus) {
            return this.viewModel.mapCriticalLevelStatus(criticalLavelStatus)
        },
        mapRiskStyle(criticalLavelStatus) {
            return this.viewModel.mapCriticalLevelStyle(criticalLavelStatus)
        }
    }
}