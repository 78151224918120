import BaseModel from '../../infraestructure/BaseModel';

export default class Product extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            productPackingUnitId: (this.productPackingUnit) ? this.productPackingUnit.id : null,
            qty: this.qty,
            updateState: this.updateState
        }
    }

}