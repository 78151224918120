<script>
import ShoppingCartComponent from "../features/ShoppingCartComponent";
export default {
  name: "ShoppingCartComponent",
  extends: ShoppingCartComponent,
};
</script>

<template>
  <div class="shopping-cart">
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <div
        v-loading="isBadgeLoading"
        class="shopping-cart__icon"
        @click="onChangeViewItems"
      >
        <el-badge
          :value="totalProducts"
          class="item"
          type="primary"
        >
          <i class="el-icon-shopping-cart-2" />
        </el-badge>
      </div>
      <div
        v-if="showItems()"
        v-loading="isLoading"
        class="shopping-cart__items"
      >
        <div class="shopping-cart__list">
          <el-row
            v-for="(item, index) in cartItems"
            :key="index"
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              v-if="item.product"
              :span="12"
            >
              <p>{{ item.product.name }}</p>
            </el-col>
            <el-col
              v-if="item.product"
              :span="7"
              align="center"
            >
              <el-input-number
                v-model="item.quantity"
                size="mini"
                :min="1"
                @change="onQuantityChange(item)"
              />
            </el-col>
            <el-col
              v-if="item.product"
              :span="4"
              align="center"
            >
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="onRemoveItem(item)"
              />
            </el-col>
          </el-row>
        </div>
        <el-row
          type="flex"
          class="row-bg shopping-cart__total"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Responsable"
              prop="responsible"
            >
              <el-select
                v-model="formData.responsible"
                placeholder="seleccionar"
                value-key="id"
                size="small"
                filterable
                clearable
                remote
                :remote-method="onFindResponsibles"
                :popper-append-to-body="true"
              >
                <el-option
                  v-for="item in responsibles"
                  :key="item.id"
                  :label="item.getFullName()"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg shopping-cart__finish"
          :gutter="20"
        >
          <el-col
            :span="24"
            align="center"
          >
            <el-button-group>
              <el-button
                size="medium"
                icon="el-icon-circle-plus-outline"
                type="primary"
                @click="onCreateProductRequest('formData')"
              >
                Finalizar Orden
              </el-button>
              <el-button
                size="medium"
                icon="el-icon-delete"
                type="default"
                @click="onRemoveAllItemsCart"
              >
                Vaciar Orden
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
