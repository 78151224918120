import BaseViewModel from "../../infraestructure/BaseViewModel";
import MeasureUnit from "./MeasureUnit";

export default class MeasureUnitViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new MeasureUnit(data, "create");
    }

    edit(data) {
        return new MeasureUnit(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.measureUnits
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.measureUnits
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.measureUnits
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.measureUnits
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.measureUnits
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.measureUnits.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }
    
    export() {
        this.api.measureUnits.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.onFilter();
    }

    bindForm() {
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
        }, 400);
    }

    bindList() {}

    //#region

    mapCollection(collection) {
        return collection.map((item) => new MeasureUnit(item));
    }

    //#endregion

}