<script>
import LoginComponent from "../features/LoginComponent";
export default {
  extends: LoginComponent,
};
</script>
<template>
  <div class="wrapper-list">
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center login-content">
              <div class="col-md-4" v-if="whiteLabelSettings">
                <div class="text-center mb-5">
                    <img
                      :src="whiteLabelSettings.companyLogo"
                      class="desktop-lgo"
                      alt="Logo"
                    />
                </div>
                <div class="card">
                  <div
                    v-loading="isLoading"
                    class="card-body"
                  >
                    <div class="text-center mb-3">
                      <h1 class="mb-2">
                        Acceso Principal
                      </h1>
                    </div>
                    <el-form
                      ref="formData"
                      :model="formData"
                      :rules="rules"
                      status-icon
                      :label-position="'top'"
                      class="mt-5"
                    >
                      <div class="input-group mb-4">
                        <el-form-item prop="username">
                          <el-input
                            v-model="formData.username"
                            placeholder="Ingresar usuario o correo"
                            class="form-control"
                            type="text"
                            prop="username"
                          >
                            <template slot="prepend">
                              <i
                                class="el-icon-user"
                                aria-hidden="true"
                              />
                            </template>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="input-group mb-4">
                        <el-form-item prop="password">
                          <el-input
                            v-model="formData.password"
                            placeholder="Ingresar contraseña"
                            class="form-control"
                            type="password"
                            @keyup.native.enter="onSubmitForm('formData')"
                          >
                            <template slot="prepend">
                              <i
                                class="el-icon-lock"
                                aria-hidden="true"
                              />
                            </template>
                          </el-input>
                        </el-form-item>
                      </div>
                      <!-- <div class="form-group">
                          <label class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                            />
                            <span class="custom-control-label"
                              >Recordarme</span
                            >
                          </label>
                        </div>-->
                      <div class="form-group text-center mb-3">
                        <el-button
                          type="primary"
                          class="btn-lg w-100 br-7"
                          @click="onSubmitForm('formData')"
                        >
                          Ingresar
                        </el-button>
                      </div>
                      <div class="form-group fs-13 text-center">
                        Olvidaste Tu Contraseña ?
                      </div>
                      <div
                        class="form-group fs-14 text-center font-weight-bold"
                      >
                        <router-link to="/recuperar-cuenta">Click aquí para recuperar tu contraseña</router-link>
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
