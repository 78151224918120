import BaseModule from '../../infraestructure/BaseModule'
import SuppliersViewModel from '../../../core/features/suppliers/SuppliersViewModel'
import _ from 'lodash';

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: SuppliersViewModel.activeStates,
            filtersSelected: {
                aliasName: '',
                email: '',
                fiscalNumber: '',
                fiscalName: '',
                isActive: null,
            },
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new SuppliersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-suppliers', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByAliasname: _.debounce(function (text) {
            (text) ? this.addFilter('Aliasname', text) : this.removeFilter('Aliasname')
            this.onFilter();
        }, 500),
        onSearchByEmail: _.debounce(function (text) {
            (text) ? this.addFilter('ContactEmail', text) : this.removeFilter('ContactEmail')
            this.onFilter();
        }, 500),
        onSearchByFiscalNumber: _.debounce(function (text) {
            (text) ? this.addFilter('FiscalNumber', text) : this.removeFilter('FiscalNumber')
            this.onFilter();
        }, 500),
        onSearchByFiscalName: _.debounce(function (text) {
            (text) ? this.addFilter('FiscalName', text) : this.removeFilter('FiscalName')
            this.onFilter();
        }, 500),
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}