export default class Brand {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name.trim(),
            description: this.description.trim(),
            isActive: this.isActive
        }
    }
}