import BaseModule from '../../infraestructure/BaseModule'
import ProductsViewModel from '../../../core/features/products/ProductsViewModel'
import _ from 'lodash';

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: ProductsViewModel.activeStates,
            filtersSelected: {
                sku: '',
                name: '',
                brand: '',
                supplier: '',
                isActive: null,
            },
            brands: [],
            suppliers: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new ProductsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-products', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchBySku: _.debounce(function (text) {
            (text) ? this.addFilter('Sku', text) : this.removeFilter('Sku')
            this.onFilter();
        }, 500),
        onSearchByName: _.debounce(function (text) {
            (text) ? this.addFilter('Name', text) : this.removeFilter('Name')
            this.onFilter();
        }, 500),
        onBrandChange(brandId) {
            (brandId) ? this.addFilter('BrandId', brandId) : this.removeFilter('BrandId')
            this.onFilter();
        },
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}