import BaseList from '../../infraestructure/BaseList'
import BrandsViewModel from '../../../core/features/brands/BrandsViewModel'

export default {
    extends: BaseList,
    data(){
        return {
            modulePermissions: 'ProductBrands',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR MARCAS',
                onUploadImport: 'on-upload-import-brands',
                onCancelImport: 'on-cancel-import-brands',
            },
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-brands', this.onFilters)
        this.onMountedEvent('on-submited-brands', this.onSearch)
        this.onMountedEvent('on-upload-import-brands', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-brands', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-brands', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-brands', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-brands', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-brands', this.onCancelImport);
    },
    created() {
        this.viewModel = new BrandsViewModel(this)
        this.onActive()
    },
    methods: { 
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate(){
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true
            })
        }
    }
}