import BaseApi from "../../infraestructure/BaseApi";

export default class ProductRequestApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    exportPdf(id) {
        return this.client.get(`${this.endPoint}/export-pdf/${id}`);
    }

    exportToExcel(id) {
        return this.client.get(`${this.endPoint}/export-excel/${id}`);
    }

    loadTheoretical(file) {
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${this.endPoint}/load-theoretical`, fd)
    }
}