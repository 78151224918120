import BaseComponent from '../../infraestructure/BaseComponent'
//import MainMenuViewModel from '../../core/features/shared/MainMenuViewModel'

export default {
    extends: BaseComponent,
    data() {
        return {
          activeLink: null,
          viewModel: null,
        };
      },
      created() {
        //this.viewModel = new MainMenuViewModel(this);
      },
      mounted() {
        this.activeLink = this.$route.path;
      },
      methods: {},
      watch: {
        $route(to, from) {
            if(to !== from) {
              this.activeLink = to.path;
            }
        }
    },
}