import BaseForm from '../../infraestructure/BaseForm';

export default {
    extends: BaseForm,
    data() {
        return {
            formData: null,
            rules: {}
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        }
    },
    methods: {
        onUpload(file) {
            this.onDoImportFile(this.conf.onUploadImport, file);
        },
        onCancel() {
            this.onDoCancel(this.conf.onCancelImport, null);
        }
    }
}