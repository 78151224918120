import BaseModel from "../../infraestructure/BaseModel";

export default class ProductInventoryTrace extends BaseModel{
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id,
            theoreticalTotal: this.theoreticalTotal,
            physicalTotal: this.physicalTotal,
            difference: this.physicalTotal - this.theoreticalTotal,
            productId: (this.product) ? this.product.id : null,
            isActive: this.isActive,
            updateState: this.updateState
        }
    }

    isEditable() {
        return this.updateState == this.UPDATE_STATUS.ADDED || this.updateState == this.UPDATE_STATUS.UPDATED;
    }
}