<script>
import PasswordRecoveryUpdateComponent from "../features/PasswordRecoveryUpdateComponent";
export default {
  extends: PasswordRecoveryUpdateComponent,
};
</script>
<template>
  <div class="wrapper-list">
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center login-content">
              <div class="col-md-4">
                <div class="text-center mb-5">
                  <!--  <img
                      src="../../../assets/images/brand/logo.png"
                      class="header-brand-img desktop-lgo"
                      alt="Exparta Solution Logo"
                    /> -->
                </div>
                <div class="card">
                  <div
                    v-loading="isLoading"
                    class="card-body"
                  >
                    <div class="text-center mb-3">
                      <h1 class="mb-2">
                        Actualizar Contraseña
                      </h1>
                    </div>
                    <el-form
                      ref="formData"
                      :model="formData"
                      :rules="rules"
                      status-icon
                      :label-position="'top'"
                      class="mt-5"
                    >
                      <div class="input-group mb-4">
                        <el-form-item prop="password">
                          <el-input
                            v-model="formData.password"
                            placeholder="Ingresar contraseña."
                            class="form-control"
                            type="password"
                            prop="password"
                          >
                            <template slot="prepend">
                              <i
                                class="el-icon-lock"
                                aria-hidden="true"
                              />
                            </template>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="input-group mb-4">
                        <el-form-item prop="passwordConfirmation">
                          <el-input
                            v-model="formData.passwordConfirmation"
                            placeholder="Confirmar contraseña."
                            class="form-control"
                            type="password"
                            prop="passwordConfirmation"
                          >
                            <template slot="prepend">
                              <i
                                class="el-icon-lock"
                                aria-hidden="true"
                              />
                            </template>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="form-group text-center mb-3" style="margin-top: 30px;">
                        <el-button
                          type="primary"
                          class="btn-lg w-100 br-7"
                          @click="onSubmitForm('formData')"
                        >
                          Enviar
                        </el-button>
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>