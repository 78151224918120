import BaseList from "../../infraestructure/BaseList";
import ProductDevolutionViewModel from "../../../core/features/productDevolutions/ProductDevolutionViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      modulePermissions: "ProductDevolutions",
      viewModel: null,
      selectedItem: null
    };
  },
  mounted() {
    this.onMountedEvent("on-filters-product-devolutions", this.onFilters);
    this.onMountedEvent("on-submited-product-devolutions", this.onSearch);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-filters-product-devolutions", this.onFilters);
    this.onBeforeDestroyEvent("on-submited-product-devolutions", this.onSearch);
  },
  created() {
    this.viewModel = new ProductDevolutionViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onFilters(filters) {
      this.filters = filters
      this.onSearch()
    },
    onCreate(){
      this.onComponentLoading(this.modalConfig, true, 'Cargando...')
      this.onComponentVisible(this.modalConfig, true)
      this.item = this.viewModel.create({
        isActive : true,
        traces: [],
      });
    },
    onChangeOption(event) {
      if (event.command === "edit") this.onEdit(event.item.id);
      if (event.command === "delete") this.onDelete(event.item.id);
      if (event.command === "export-pdf") this.onExportPdf(event.item.id);
      if (event.command === "export-excel") this.onExportExcel(event.item.id);
      this.selectedItem = event.item;
    },
    onExportPdf(id) {
        this.isListLoading = true;
        this.viewModel.exportPdf(id);
    },
    onExportPdfResponse(response) {
        setTimeout(() => {
            this.isListLoading = false;
            this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () => {
              setTimeout(() => {
                this.notifySuccess("Reporte Generado.");
              }, 400);
            });
        }, 400);
    }
  },
};
