import BaseViewModel from '../../infraestructure/BaseViewModel'
import Staff from './Staff'
import _ from 'lodash'

export default class StaffViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }
    //#region  INICIALIZADORES
    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]
    //#endregion

    //#region CRUD

    create(data) {
        return new Staff(data, 'create')
    }

    edit(data) {
        return new Staff(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.staff.create(data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
            
        } else {
            this.api.staff.update(data.id, data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.staff.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.staff.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.staff.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    import(file) {
        this.api.staff.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.staff.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            userName: '',
            email: '',
            role: '',
            isActive: null,
        };

        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Username');
        this.view.removeFilter('Email');
        this.view.removeFilter('RoleId');
        this.view.removeFilter('IsActive');
        this.view.onFilter();
    }
    //#endregion

    // #region BINDING DATA
    bindForm(formData) {
        this.api.roles.find(`Skip=0&Limit=1000000&IsActive=true`)
        .then((response) => {
            this.view.roles = response.data.data
            if (formData.state === "update") {
                // formData.roles ? [formData.roles] : [];
            }
            setTimeout(() => {
                this.view.onComponentLoading(this.view.config, false, '')
            }, 400);
        })
    }

    bindList() {
        this.api.roles.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.roles = response.data.data
            })
            .catch(this.view.onError);
    }
    //#endregion

    // #region QUERIES
    
    findRoles(criteria) {
        this.api.roles.find(criteria)
            .then((response) => this.view.onFindRoleResponse(response.data))
            .catch(this.view.onError)
    }

    // #endregion

	//#region MAPPING DATA
    
    mapCollection(collection){
        return collection.map((item) => new Staff(item))
    }

    // #endregion

	// #region VALIDATIONS
    /**
   * Metodo para verificar si password con password de confirmación son iguales
   * @param {String} value Password a confirmar 
   * @param {*} callback Metodo de respuesta a la verificación
   */
     verifyConfirmationPassword(value, callback) {
        if (_.isEmpty(value)) {
            callback(new Error('Favor de ingresar información.'))
        } else if (value !== this.view.formData.password) {
            callback(new Error('Las contraseñas no coinciden.'))
        } else {
            callback()
        }
    }
    // #endregion
}