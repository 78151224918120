<script>
import HeaderComponent from "../features/HeaderComponent";
import SessionBoxView from './SessionBoxView';
import ShoppingCartView from '../../shoppingCarts/views/ShoppingCartView';

export default {
    name: "HeaderView",
    components: {
        'session-box-view': SessionBoxView,
        'shopping-cart-view': ShoppingCartView,
    },
    extends: HeaderComponent
}
</script>

<template>
  <div class="container">
    <div class="d-flex">
      <a class="animated-arrow hor-toggle horizontal-navtoggle"><span /></a>
      <a
        class="header-brand"
        href="/"
      >
        <img
          :src="whiteLabelSettings.companyLogo"
          class="desktop-lgo"
          alt="Logo"
          v-if="whiteLabelSettings"
         />
      </a>
      <!-- SEARCH
            <div class="mt-1 d-md-block d-none">
                <form class="form-inline">
                    <div class="search-element">
                        <input type="search" class="form-control header-search mobile-view-search" placeholder="Search…" aria-label="Search" tabindex="1">
                        <button class="btn btn-primary-color" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" class="header-icon search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/></svg>
                        </button>
                    </div>
                </form>
            </div> -->
      <div
        class="d-flex order-lg-2 ms-auto main-header-end"
      >
        <button
          class="navbar-toggler navresponsive-toggler d-md-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent-4"
          aria-controls="navbarSupportedContent-4"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <i class="fe fe-more-vertical header-icons navbar-toggler-icon" />
        </button>
        <div class="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
          <div
            id="navbarSupportedContent-4"
            class="collapse navbar-collapse"
          >
            <div class="d-flex order-lg-2">
              <!-- Fullscreen -->
              <div class="dropdown  header-fullscreen d-flex">
                <a
                  id="fullscreen-button"
                  class="nav-link icon full-screen-link p-0"
                >
                  <i class="header-icon fa fa-expand" />
                </a>
              </div>
              <!-- Messages -->
              <!--
                            <div class="dropdown header-message d-flex">
                                <a class="nav-link icon" data-bs-toggle="dropdown">
                                    <i class="header-icon fa fa-envelope-o"></i>
                                    <span class="badge bg-success side-badge">5</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow  animated">
                                    <div class="dropdown-header">
                                        <h6 class="mb-0">Messages</h6>
                                        <span class="badge fs-10 bg-secondary br-7 ms-auto">New</span>
                                    </div>
                                    <div class="header-dropdown-list message-menu">
                                        <a class="dropdown-item border-bottom" href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/1.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class="mb-1 fs-13">Joan Powell</span>
                                                        <p class="fs-12 mb-1">All the best your template awesome</p>
                                                        <div class="fs-11 text-muted">
                                                            3 hours ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item border-bottom" href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/2.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class="mb-1 s-13">Gavin Sibson</span>
                                                        <p class="fs-12 mb-1">Hey! there I'm available</p>
                                                        <div class="fs-11 text-muted">
                                                            5 hour ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item border-bottom" href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/3.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class="mb-1">Julian Kerr</span>
                                                        <p class="fs-12 mb-1">Just created a new blog post</p>
                                                        <div class="fs-11 text-muted">
                                                            45 mintues ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item border-bottom" href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/4.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class=" fs-13 mb-1">Cedric Kelly</span>
                                                        <p class="fs-12 mb-1">Added new comment on your photo</p>
                                                        <div class="fs-11 text-muted">
                                                            2 days ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item border-bottom"  href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/6.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class="mb-1 fs-13">Julian Kerr</span>
                                                        <p class="fs-12 mb-1">Your payment invoice is generated</p>
                                                        <div class="fs-11 text-muted">
                                                            3 days ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="chat.html">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <span class="avatar avatar-md brround align-self-center cover-image" data-image-src="../../../assets/images/users/7.jpg"></span>
                                                </div>
                                                <div class="d-flex mt-1 mb-1">
                                                    <div class="ps-3">
                                                        <span class="mb-1 fs-13">Faith Dickens</span>
                                                        <p class="fs-12 mb-1">Please check your mail....</p>
                                                        <div class="fs-11 text-muted">
                                                            4 days ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class=" text-center p-2 pt-3 border-top">
                                        <a href="chat.html" class="fs-13 btn btn-primary btn-md btn-block">See More</a>
                                    </div>
                                </div>
                            </div>
                            -->
              <!-- Notify -->
              <!--
                            <div class="dropdown header-notify d-flex">
                                <a class="nav-link icon" data-bs-toggle="dropdown">
                                    <i class="header-icon fa fa-bell-o"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow  animated">
                                    <div class="dropdown-header">
                                        <h6 class="mb-0">Notifications</h6>
                                        <span class="badge fs-10 bg-secondary br-7 ms-auto">New</span>
                                    </div>
                                    <div class="notify-menu">
                                        <a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
                                            <div class="notifyimg  text-primary bg-primary-transparent border-primary"> <i class="fa fa-envelope"></i> </div>
                                            <div>
                                                <span class="fs-13">Message Sent.</span>
                                                <div class="small text-muted">3 hours ago</div>
                                            </div>
                                        </a>
                                        <a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
                                            <div class="notifyimg  text-secondary bg-secondary-transparent border-secondary"> <i class="fa fa-shopping-cart"></i></div>
                                            <div>
                                                <span class="fs-13">Order Placed</span>
                                                <div class="small text-muted">5  hour ago</div>
                                            </div>
                                        </a>
                                        <a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
                                            <div class="notifyimg  text-danger bg-danger-transparent border-danger"> <i class="fa fa-gift"></i> </div>
                                            <div>
                                                <span class="fs-13">Event Started</span>
                                                <div class="small text-muted">45 mintues ago</div>
                                            </div>
                                        </a>
                                        <a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4 mb-2">
                                            <div class="notifyimg  text-success  bg-success-transparent border-success"> <i class="fa fa-windows"></i> </div>
                                            <div>
                                                <span class="fs-13">Your Admin lanuched</span>
                                                <div class="small text-muted">1 daya ago</div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class=" text-center p-2">
                                        <a href="email-inbox.html" class="btn btn-primary btn-md fs-13 btn-block">View All</a>
                                    </div>
                                </div>
                            </div>
                            -->
              <!-- User -->
              <div class="dropdown profile-dropdown d-flex">
                <shopping-cart-view />
              </div>
              <div class="dropdown profile-dropdown d-flex">
                <session-box-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
