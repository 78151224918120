import BaseViewModel from "../../infraestructure/BaseViewModel";

export default class DashboardViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    findPendingProductRequests(criteria) {
        this.api.dashboard.findPendingProductRequests(criteria)
            .then((response) => this.view.onFindPendingProductRequestsResponse(response.data))
            .catch((error) => this.view.onFindPendingProductRequestsError(error));
    }

    findTotalMovements(criteria) {
        this.api.dashboard.findTotalMovements(criteria)
            .then((response) => this.view.onFindTotalMovementsResponse(response.data))
            .catch((error) => this.view.onFindTotalMovementsError(error));
    }

    findTotalUsersActive(criteria) {
        this.api.dashboard.findTotalUsersActive(criteria)
            .then((response) => this.view.onFindTotalUsersActiveResponse(response.data))
            .catch((error) => this.view.onFindTotalUsersActiveError(error));
    }

    findTopLowInventory(criteria) {
        this.api.dashboard.findTopLowInventory(criteria)
            .then((response) => this.view.onFindTopLowInventoryResponse(response.headers, response.data))
            .catch((error) => this.view.onFindTopLowInventoryError(error));
    }

}