<script>
import ModalSupplierComponent from "../features/ModalSupplierComponent";
export default {
  name: "ModalSupplierComponent",
  extends: ModalSupplierComponent,
};
</script>

<template>
  <el-dialog
    title="Proveedores"
    :visible.sync="config.isVisible"
    custom-class="modal-supplier"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
  <span
      slot="title"
      class="dialog-title"
    >
      <i class="fa fa-university" /> Proveedores
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Proveedor</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :md="6"  :lg='6' :xl='6'>
            <el-form-item label="Codigo" prop="code">
              <el-input v-model="formData.code" size="small" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :md="18" :lg='18' :xl='18'>
            <el-form-item label="Nombre/Alias" prop="aliasName">
              <el-input v-model="formData.aliasName" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :md="10" :lg='10' :xl='10'>
            <el-form-item label="Representante Legal" prop="legalResponsible">
              <el-input v-model="formData.legalResponsible" size="small" />
            </el-form-item>
          </el-col>
          <el-col :md="10" :lg='10' :xl='10'>
            <el-form-item label="R.F.C." prop="fiscalNumber">
              <el-input v-model="formData.fiscalNumber" size="small" />
            </el-form-item>
          </el-col>
          <el-col :md="4" :lg='4' :xl='4' align="center">
            <el-form-item label="¿Activo?" prop="isActive">
              <el-switch
                style="display: block"
                v-model="formData.isActive"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Si"
                inactive-text="No">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Razón Social" prop="fiscalName">
              <el-input v-model="formData.fiscalName" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :md="16" :lg='16' :xl='16'>
            <el-form-item label="Nombre Contacto" prop="contactName">
              <el-input v-model="formData.contactName" size="small" />
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg='8' :xl='8'>
            <el-form-item label="Telefono Contacto" prop="contactPhone">
              <el-input v-model="formData.contactPhone" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Correo Contacto" prop="contactEmail">
              <el-input v-model="formData.contactEmail" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>