import BaseViewModel from "../../infraestructure/BaseViewModel";
import User from "../users/User";
// import ItemCart from "./ItemCart";

export default class ShoppingCartViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    findItems() {
        this.api.shoppingCarts.find()
            .then((response) => this.view.onFindItemsResponse(response.data))
            .catch((error) => this.view.onFindItemsError(error));
    }

    addItem(item) {
        this.api.shoppingCarts.create(item)
            .then((response) => this.view.onAddItemResponse(response.data))
            .catch((error) => this.view.onAddItemError(error));
    }

    updateItem(id, item) {
        this.api.shoppingCarts.update(id, item)
        .then(() => {
            return this.api.shoppingCarts.find()
        })
        .then((response) => this.view.onUpdateItemResponse(response.data))
        .catch((error) => this.view.onUpdateItemError(error));
    }

    removeItem(item) {
        this.api.shoppingCarts.delete(item.id)
        .then(() => {
            return this.api.shoppingCarts.find()
        })
        .then((response) => this.view.onRemoveItemResponse(response.data))
        .catch((error) => this.view.onRemoveItemError(error));
    }

    removeAllItems() {
        this.api.shoppingCarts.deleteAll()
        .then(() => {
            return this.api.shoppingCarts.find()
        })
        .then((response) => this.view.onRemoveAllItemsResponse(response.data))
        .catch((error) => this.view.onRemoveAllItemsError(error));
    }

    createProductRequestReport(data) {
        var responseMessage = null;
        this.api.shoppingCarts.createProductRequestReport(data)
        .then((response) => {
            responseMessage = response.data.message
            return this.api.shoppingCarts.find()
        })
        .then((response) => {
            response.data.message = responseMessage
            this.view.onCreateProductRequestReportResponse(response.data)
        })
        .catch((error) => this.view.onCreateProductRequestReportError(error));
    }

    findResponsibles(query) {
        this.api.staff.find(query)
            .then((response) => this.view.onFindResponsiblesResponse(response.data))
            .catch(this.view.onError);
    }

    mapUsers(collection) {
        return collection.map((item) => new User(item));
    }
}