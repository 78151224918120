<script>
import FooterComponent from "../features/FooterComponent";

export default {
  name: "HeaderView",
  mixins: [FooterComponent],
};
</script>

<template>
  <div class="container">
    <div class="row align-items-center flex-row-reverse">
      <div class="col-md-12 col-sm-12 text-center">
        Copyright © {{ currentYear }}
        <a href="https://www.devandtech.com/">Devandtech</a>. Diseñado por
        <a href="https://www.devandtech.com/"> Devandtech </a> Todos los
        derechos reservados.
      </div>
    </div>
  </div>
</template>