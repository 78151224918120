import BaseList from '../../infraestructure/BaseList'
import ProductRackLocationsViewModel from '../../../core/features/productRackLocations/ProductRackLocationsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'ProductInRack',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR PRODUCTOS EN RACKS',
                onUploadImport: 'on-upload-import-product-rack-locations',
                onCancelImport: 'on-cancel-import-product-rack-locations',
            },
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-product-rack-locations', this.onFilters)
        this.onMountedEvent('on-submited-product-rack-locations', this.onSearch)
        this.onMountedEvent('on-upload-import-product-rack-locations', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-product-rack-locations', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-product-rack-locations', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-product-rack-locations', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-product-rack-locations', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-product-rack-locations', this.onCancelImport);
    },
    created() {
        this.viewModel = new ProductRackLocationsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true,
                products: [],
            })
        }
    }
}