import BaseModel from '../../infraestructure/BaseModel';

export default class Product extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            fileName: this.fileName,
            fileUrl: this.fileUrl,
            fileExtension: this.fileExtension,
            updateState: this.updateState,
            isCover: this.isCover || false,
        }
    }

}