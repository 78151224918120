import Vue from "vue";
import moment from 'moment'
import './core/helpers/ThemeHelper'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App.vue'
import router from './router'
import ElementUI from "element-ui";

import '@/sass/site.scss' // global scss
import './core/helpers/ThemeHelper'

import locale from "element-ui/lib/locale/lang/es";
import 'font-awesome/css/font-awesome.css'




Vue.use(ElementUI, { locale });

moment.locale('es')

Vue.filter('formatDate', value => moment(String(value), moment.ISO_8601).format('DD MMM YYYY'))

Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
