<script>
import ModuleSuppliersComponent from '../features/ModuleSuppliersComponent'
import ListSuppliersView from "./ListSuppliersView.vue";

export default {
  components: {
    "list-suppliers-view": ListSuppliersView,
  },
  extends: ModuleSuppliersComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-university" /> Proveedores / Empresas
        </h4>
      </div>
      <div class="page-centerheader">
        <el-drawer custom-class="filters-drawer" :visible.sync="isMoreFilterVisible" direction="rtl" size="20%">
          <template slot="title">
            <span class="title">Filtros</span> <el-button class="btn-text" type="text" @click="onClearFilters">Borrar todos los filtros</el-button>
          </template>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
                <div class="block">
                  <span class="block-title">Alias:</span>
                  <el-input
                    type="text"
                    placeholder="Buscar por alias"
                    v-model="filtersSelected.aliasName"
                    clearable
                    size="small"
                    v-on:input="onSearchByAliasname"
                  ></el-input>
                </div>
              </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
                <div class="block">
                  <span class="block-title">Correo Electronico:</span>
                  <el-input
                    type="text"
                    placeholder="Buscar por correo de contacto"
                    v-model="filtersSelected.email"
                    clearable
                    size="small"
                    v-on:input="onSearchByEmail"
                  ></el-input>
                </div>
              </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
                <div class="block">
                  <span class="block-title">R.F.C.:</span>
                  <el-input
                    type="text"
                    placeholder="Buscar por R.F.C."
                    v-model="filtersSelected.fiscalNumber"
                    clearable
                    size="small"
                    v-on:input="onSearchByFiscalNumber"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row class="row-bg wrapper-more-filters" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
                <div class="block">
                  <span class="block-title">Razón Social:</span>
                  <el-input
                    type="text"
                    placeholder="Buscar por razón social"
                    v-model="filtersSelected.fiscalName"
                    clearable
                    size="small"
                    v-on:input="onSearchByFiscalName"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row class="row-bg wrapper-more-filters" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
                <div class="block">
                  <span class="block-title">¿Activo?:</span>
                  <el-select size="small"
                    v-model="filtersSelected.isActive"
                    filterable
                    clearable
                    placeholder="Seleccionar"
                    @change="onActiveChange"
                    value-key="id">
                    <el-option v-for="item in activeStates" :key="item.id" :label="item.name" :value="item.state"></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
        <div class="btn-list">
          <el-button
            type="default"
            plain
            icon="fa fa-filter"
            size="small"
            @click="onMoreFilters"
          >
            Filtrar por:
          </el-button>
        </div>
      </div>
    </div>
    <!--End Page header-->
    <list-suppliers-view />
  </div>
</template>
