import BaseForm from '../../infraestructure/BaseForm'
import ProductInventoryViewModel from '../../../core/features/productInventories/ProductInventoryViewModel'


export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'ProductInventories',
            viewModel: null,
            responsibles: [],
            operators: [],
            productRacks: [],
            indexTraceSelected: null,
            isTableLoading: false,
            reportStatuses: [],
            selectedProducts: [],
            modalTheoretical: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR INVENTARIO TEORICO',
                onUploadImport: 'on-upload-import-load-theoretical',
                onCancelImport: 'on-cancel-import-load-theoretical',
            },
            rules: {
                reportDate: [{
                    required: true,
                    message: 'Fecha requerido.',
                    trigger: 'blur, change',
                }],
                reportStatus: [{
                    required: true,
                    message: 'Estatus requerido.',
                    trigger: 'blur, change',
                }],
                responsible: [{
                    required: true,
                    message: 'Responsable requerido.',
                    trigger: 'blur, change'
                }],
                operator: [{
                    required: true,
                    message: 'Operador requerido.',
                    trigger: 'blur, change'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.onMountedEvent('on-upload-import-load-theoretical', this.onUploadImportLoadTheoretical);
        this.onMountedEvent('on-cancel-import-load-theoretical', this.onCancelImportLoadTheoretical);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-upload-import-load-theoretical', this.onUploadImportLoadTheoretical);
        this.onBeforeDestroyEvent('on-cancel-import-load-theoretical', this.onCancelImportLoadTheoretical);
    },
    created() {
        this.viewModel = new ProductInventoryViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-product-inventory', null)
        },
        onFindResponsibles(data) {
            this.responsibles = []
            if (data)
                this.viewModel.findResponsibles(`FullSearch=${data}&IsActive=true`)
        },
        onFindResponsiblesResponse(response) {
            this.responsibles = this.viewModel.mapUsers(response.data)
        },
        onFindOperators(data) {
            this.operators = []
            if (data)
                this.viewModel.findOperators(`FullSearch=${data}&IsActive=true`)
        },
        onFindOperatorsResponse(response) {
            this.operators = this.viewModel.mapUsers(response.data)
        },
        onFindAllRacksWithProducts(query) {
            this.racks = [];
            if (query) this.viewModel.findAllRacksWithProducts(`Name=${query}&IsActive=true`);
        },
        onFindAllRacksWithProductsResponse(response) {
            this.productRacks = response.data;
        },
        onChangeRack() {
            this.formData.products = [];
        },
        onAddProductByRacks(productRacks) {
            if (productRacks.length > 0) {
                this.isTableLoading = true;
                this.viewModel.findProductByRacks(`ProductRacks=${this.viewModel.mapProductRacks(productRacks)}&IsActive=true`);
            } else {
                this.notifyWarning("Seleccione una categoría.");
            }
        },
        onFindProductByRacksResponse(response) {
            setTimeout(() => {
                let responseProducts = this.viewModel.filterDuplicateProducts(this.formData.traces, response.data);
                responseProducts.forEach(product => {
                    this.formData.addTrace({
                        physicalTotal: 0,
                        theoreticalTotal: 0,
                        products: [product],
                        product: product,
                    });
                });

                this.$forceUpdate();
                this.isTableLoading = false;
            }, 400);
        },
        onAddProduct() {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.addTrace({
                    physicalTotal: 0,
                    theoreticalTotal: 0,
                    products: []
                })
                this.isTableLoading = false
            }, 400);

        },
        onEditProduct(index) {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.setUpdateState(index)
                this.isTableLoading = false
            }, 400);
        },
        onProductFocus(index) {
            this.indexTraceSelected = index
        },
        onFindProducts(data) {
            this.formData.traces[this.indexTraceSelected].products = []
            if (data)
                this.viewModel.findProducts(`FullSearch=${data}&IsActive=true`)
        },
        onFindProductsResponse(response) {
            this.formData.traces[this.indexTraceSelected].products = response.data
        },
        onProductChange(data) {
            this.isTableLoading = true
            if (data) {
                this.viewModel.findProduct(data.id)
            } else {
                setTimeout(() => {
                    this.formData.traces[this.indexTraceSelected].theoreticalTotal = 0
                    this.formData.traces[this.indexTraceSelected].physicalTotal = 0
                    this.formData.traces[this.indexTraceSelected].products = []
                    this.isTableLoading = false
                }, 400);
            }
        },
        onProductChangeResponse(response) {
            setTimeout(() => {
                this.formData.traces[this.indexTraceSelected].product = response.data
                this.formData.traces[this.indexTraceSelected].theoreticalTotal = response.data.totalStock
                this.formData.traces[this.indexTraceSelected].racks = response.data.racks.map((item) => item.name).join(',')
                this.isTableLoading = false
            }, 400);
        },
        onRemoveProduct(index) {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.remove(index)
                this.isTableLoading = false
            }, 400);
        },
        onCancelImportLoadTheoretical() {
            this.modalTheoretical.isVisible = false
        },
        onLoadTheoretical() {
            this.modalTheoretical.isVisible = true
        },
        onUploadImportLoadTheoretical(file) {
            this.modalTheoretical.isLoading = true
            this.viewModel.loadTheoretical(file)
        },
        onLoadTheoreticalResponse(response) {
            setTimeout(() => {
                this.formData.setTraces(response.data)
                this.modalTheoretical.isLoading = false
                this.modalTheoretical.isVisible = false
            }, 400);
        },
        onLoadTheoreticalError(error) {
            setTimeout(() => {
                this.modalTheoretical.isLoading = false
                this.onError(error)
            }, 400);
        },
        onSelectChange(data){
            this.selectedProducts = data
        },
        onRemoveSelected() {
            this.formData.traces = this.viewModel.removeSelectedProducts(this.formData.traces, this.selectedProducts)
        }
    }
}