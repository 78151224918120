export default {
    storageKey: 'titanStockSessionStorage',
    jsonWebTokenKey: 'titanStockJsonWebToken',
    setSession(data){
        localStorage.setItem(this.storageKey, JSON.stringify(data));
    },
    getSession(){
        return JSON.parse(localStorage.getItem(this.storageKey));
    },
    removeSession(){
        localStorage.removeItem(this.storageKey);
    },
    isAuthenticated() {
        return localStorage.getItem(this.jsonWebTokenKey) !== null;
    },
    setToken(token){
        localStorage.setItem(this.jsonWebTokenKey, token);
    },
    getToken(){
        return localStorage.getItem(this.jsonWebTokenKey);
    },
    getSessionRole(){
        const jwt = localStorage.getItem(this.jsonWebTokenKey);
        var payload = atob(jwt.split(".")[1]);
        const items = payload.split(",");
        const role = items.find(item => item.includes("role"));
        return role.split(":")[2].replace(/['"]+/g, "");
    },
    removeToken(){
        localStorage.removeItem(this.jsonWebTokenKey);
    }
}