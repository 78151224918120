import EndPoints from './EndPoints';
import ApiWrapper from './ApiWrapper';


// API AUTHORIZATION && AUTHENTICATION
import UsersApi from '../features/users/UsersApi'
import RoleApi from '../features/roles/RoleApi'
import PermissionsApi from '../features/permissions/PermissionsApi'
// import AuthorizationApi from '../features/authorization/AuthorizationApi'
import SecurityApi from '../features/security/SecurityApi';

// EMPRESA
import StaffApi from '../features/staff/StaffApi';

// API CARGO DELIVERY SCHEDULES
import CargoReceptionsApi from '../features/cargoReceptions/CargoReceptionsApi';

// API SUPPLIERS
import SuppliersApi from '../features/suppliers/SuppliersApi';

// API PRODUCTS
import BrandsApi from '../features/brands/BrandsApi';
import ProductPackingTypesApi from '../features/productPackingTypes/ProductPackingTypesApi';
import ProductPackingUnitsApi from '../features/productPackingUnits/ProductPackingUnitsApi';
import ProductsApi from '../features/products/ProductsApi';
import ProductCategoryApi from '../features/productCategories/ProductCategoryApi';
import MeasureUnitApi from '../features/measureUnits/MeasureUnitApi';
import ProductRacksApi from '../features/productRacks/ProductRacksApi';
import ProductRackLocationsApi from '../features/productRackLocations/ProductRackLocationsApi'


// API PRODUCT REPORTS
import ProductReceptionApi from '../features/productReceptions/ProductReceptionApi';
import ProductDeliveryApi from '../features/productDeliveries/ProductDeliveryApi';
import ProductRequestApi from '../features/productRequests/ProductRequestApi';
import ProductSeekerApi from '../features/productSeeker/ProductSeekerApi';
import ProductInventoryApi from '../features/productInventories/ProductInventoryApi';
import ProductDevolutionApi from '../features/productDevolutions/ProductDevolutionApi';
import ProductWasteApi from '../features/productWastes/ProductWasteApi';

// API PRODUCT WASTES
import WasteReasonApi from '../features/wasteReasons/WasteReasonApi';
import WasteAreaApi from '../features/wasteAreas/WasteAreaApi';

// API SHOPPING CART
import ShoppingCartApi from '../features/shoppingCarts/ShoppingCartApi';

// API SETTINGS

import SettingsApi from '../features/settings/SettingsApi';

// API DASHBOARD
import DashboardApi from '../features/dashboard/DashboardApi';

// API WHITE LABEL SETTINGS
import WhiteLabelSettingApi from '../features/whiteLabelSettings/WhiteLabelSettingApi';
import ToolApi from '../features/tools/ToolApi';

// API WAREHOUSES
import WarehouseApi from '../features/warehouses/WarehouseApi';

const apiWrapper = new ApiWrapper();

export default {
    // API AUTHORIZATION && AUTHENTICATION
    // authorization: new AuthorizationApi(apiWrapper, `${EndPoints.API}${EndPoints.AUTH}`),
    users: new UsersApi(apiWrapper, `${EndPoints.API}${EndPoints.USERS}`),
    roles: new RoleApi(apiWrapper, `${EndPoints.API}${EndPoints.ROLES}`),
    permissions: new PermissionsApi(apiWrapper, `${EndPoints.API}${EndPoints.PERMISSIONS}`),
    security: new SecurityApi(apiWrapper, `${EndPoints.API}${EndPoints.SECURITY}`),
    staff: new StaffApi(apiWrapper, `${EndPoints.API}${EndPoints.STAFF}`),
    suppliers: new SuppliersApi(apiWrapper, `${EndPoints.API}${EndPoints.SUPPLIERS}`),
    cargoReceptions: new CargoReceptionsApi(apiWrapper, `${EndPoints.API}${EndPoints.CARGO_RECEPTIONS}`),
    brands: new BrandsApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_BRANDS}`),
    productPackingTypes: new ProductPackingTypesApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_PACKING_TYPES}`),
    productPackingUnits: new ProductPackingUnitsApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_PACKING_UNITS}`),
    products: new ProductsApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCTS}`),
    productCategories: new ProductCategoryApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_CATEGORIES}`),
    measureUnits: new MeasureUnitApi(apiWrapper, `${EndPoints.API}${EndPoints.MEASURE_UNITS}`),
    productRacks: new ProductRacksApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_RACKS}`),
    productRackLocations: new ProductRackLocationsApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_RACK_LOCATIONS}`),
    productReceptions: new ProductReceptionApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_RECEPTIONS}`),
    productDevolutions: new ProductDevolutionApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_DEVOLUTIONS}`),
    productDeliveries: new ProductDeliveryApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_DELIVERIES}`),
    productRequests: new ProductRequestApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_REQUESTS}`),
    productSeeker: new ProductSeekerApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_SEEKER}`),
    productInventories: new ProductInventoryApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_INVENTORIES}`),
    productWastes: new ProductWasteApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_WASTES}`),
    shoppingCarts: new ShoppingCartApi(apiWrapper, `${EndPoints.API}${EndPoints.SHOPPING_CARTS}`),
    settings: new SettingsApi(apiWrapper, `${EndPoints.API}${EndPoints.SETTINGS}`),
    dashboard: new DashboardApi(apiWrapper, `${EndPoints.API}${EndPoints.DASHBOARD}`),
    wasteAreas: new WasteAreaApi(apiWrapper, `${EndPoints.API}${EndPoints.WASTE_AREAS}`),
    wasteReasons: new WasteReasonApi(apiWrapper, `${EndPoints.API}${EndPoints.WASTE_REASONS}`),
    whiteLabelSettings: new WhiteLabelSettingApi(apiWrapper, `${EndPoints.API}${EndPoints.WHITE_LABEL_SETTINGS}`),
    tools: new ToolApi(apiWrapper, `${EndPoints.API}${EndPoints.TOOLS}`),
    warehouses: new WarehouseApi(apiWrapper, `${EndPoints.API}${EndPoints.WAREHOUSES}`)
}