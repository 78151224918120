import BaseList from '../../infraestructure/BaseList'
import WasteAreaViewModel from '../../../core/features/wasteAreas/WasteAreaViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'WasteArea',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR AREAS DE MERMA',
                onUploadImport: 'on-upload-import-waste-areas',
                onCancelImport: 'on-cancel-import-waste-areas',
            },
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-waste-areas', this.onFilters)
        this.onMountedEvent('on-submited-waste-areas', this.onSearch)
        this.onMountedEvent('on-upload-import-waste-areas', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-waste-areas', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-waste-areas', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-waste-areas', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-waste-areas', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-waste-areas', this.onCancelImport);
    },
    created() {
        this.viewModel = new WasteAreaViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true,
                wasteReasons: []
            })
        }
    }
}