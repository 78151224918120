<script>
import SessionBoxComponent from '../features/SessionBoxComponent'

export default {
    name: "SessionBoxView",
    mixins: [SessionBoxComponent]
}
</script>

<template>
  <el-dropdown
    size="medium"
    :placement="'bottom'"
    @command="onChangeMenuOptions"
  >
    <div class="nav-link pe-0 leading-none">
      <span class="header-avatar1">
        <img
          src="../../../assets/images/users/2.jpg"
          alt="img"
          class="avatar avatar-md brround"
        >
      </span>
    </div>
    <el-dropdown-menu
      slot="dropdown"
      class="dropdown-menu-end dropdown-menu-arrow animated"
    >
      <el-dropdown-item
        size="tiny"
        disabled
      >
        <div class="text-center">
          <div class="text-center user pb-0 font-weight-bold">
            {{ getSessionName() }}
          </div>
          <span class="text-center user-semi-title">{{ getRoleName() }}</span>
          <div class="dropdown-divider" /> 
          <div class="text-center user pb-0 font-weight-bold">
            {{ getEmail() }}
          </div>
          <div class="dropdown-divider" />
        </div>
      </el-dropdown-item>
      <!-- <el-dropdown-item
        command="logout"
        size="tiny"
        class="dropdown-item d-flex"
      >
        <i class="header-icon fa fa-user-circle-o" />
        <div class="fs-13">
          Profile
        </div>
      </el-dropdown-item>
      <el-dropdown-item
        command="logout"
        size="tiny"
        class="dropdown-item d-flex"
      >
        <i class="header-icon fa fa-cog" />
        <div class="fs-13">
          Settings
        </div>
      </el-dropdown-item>
      <el-dropdown-item
        command="logout"
        size="tiny"
        class="dropdown-item d-flex"
      >
        <i class="header-icon fa fa-comment-o" />
        <div class="fs-13">
          Messages
        </div>
      </el-dropdown-item> -->
      <el-dropdown-item
        command="logout"
        size="tiny"
        class="dropdown-item d-flex"
      >
        <i class="header-icon fa fa-sign-out" />
        <div
          v-loading.fullscreen.lock="isLogoutLoading"
          class="fs-13"
          :element-loading-text="logoutText"
        >
          Cerrar Sesión
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>