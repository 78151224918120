import BaseList from '../../infraestructure/BaseList'
import ProductPackingUnitsViewModel from '../../../core/features/productPackingUnits/ProductPackingUnitsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'ProductPackingUnit',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR TIPOS DE EMBALAJE',
                onUploadImport: 'on-upload-import-productPackingUnits',
                onCancelImport: 'on-cancel-import-productPackingUnits',
            },
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-productPackingUnits', this.onFilters)
        this.onMountedEvent('on-submited-productPackingUnits', this.onSearch)
        this.onMountedEvent('on-upload-import-productPackingUnits', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-productPackingUnits', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-productPackingUnits', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-productPackingUnits', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-productPackingUnits', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-productPackingUnits', this.onCancelImport);
    },
    created() {
        this.viewModel = new ProductPackingUnitsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true
            })
        }
    }
}