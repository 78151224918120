<script>
import NotFoundComponent from "../features/NotFoundComponent";

export default {
  name: "NotFoundView",
  mixins: [NotFoundComponent],
};
</script>

<template>
  <div class="h-100vh">
    <div class="box">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div class="page">
      <div class="page-content">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-12">
              <div class="">
                <div class="text-primary">
                  <div class="display-1 mb-5 font-weight-bold error-text">
                    404
                  </div>
                  <h1 class="h3 mb-3 font-weight-bold">
                    Lo sentimos, la pagina que estas buscando no se encuentra!
                  </h1>
                  <p class="h5 font-weight-normal mb-7 leading-normal">
                    Quizas ingresaste el nombre de la pagina incorrecta.
                  </p>
                  <a
                    class="btn btn-primary text-light mb-5 fs-18"
                    href="javascript:void(0);"
                  >Ayuda</a>
                  <a
                    class="btn text-primary border-primary mb-5 ms-2 fs-18"
                    href="/"
                  >Regresar al inicio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
