import BaseForm from '../../infraestructure/BaseForm'
import AuthorizationViewModel from '../../../core/features/authorization/AuthorizationViewModel';

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            formData: {
                email: ''
            },
            isLoading: false,
            rules: {
                email: [
                    { required: true, message: 'Correo requerido.', trigger: 'blur' },
                    { type: 'email', message: 'Correo inválido.', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    created() {
        this.viewModel = new AuthorizationViewModel(this);
    },
    methods: {
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.isLoading = true
                    this.viewModel.doRecoveryPassword(this.formData.email)
                    return true
                }
                return false
            })
        },
        onRecoveryPasswordResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.notifySuccess(response.message)
            }, 400);
            setTimeout(() => {
                this.$router.push({ name: "LoginView" });
            }, 800);
        }
    }
}