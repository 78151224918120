import BaseList from '../../infraestructure/BaseList'
import PermissionsViewModel from '../../../core/features/permissions/PermissionsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Permissions',
            viewModel: null,
            activeName: null,
            roles: [],
            rolesIds: [],
            checkAllRoles: false,
            isRolesIndeterminate: true,
            checkedRole: null,

            modules: [],
        }
    },
    created() {
        this.viewModel = new PermissionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.viewModel.bindFormView()
        },
        onBindFormResponse(response) {
            setTimeout(() => {
                this.roles = response.roles
                this.rolesIds = response.roles.map((item) => item.id)
                this.modules = response.modules
                // this.permissions = response.permissions
                this.onComponentLoading(this.modalConfig, false, '')
            }, 500);
        },
        onChangeRoles(data) {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.viewModel.findPermissionsByRole(data)
        },
        onCheckPermission() {
            this.onComponentLoading(this.modalConfig, true, 'Guardando...')
            this.viewModel.updateRolePermissions({
                roleId: this.checkedRole,
                permissions: this.viewModel.mapPermissionsChecked(this.modules)
            })
            this.$forceUpdate()
        },
        onCheckAllChange(data) {
            this.onComponentLoading(this.modalConfig, true, 'Guardando...')
            this.viewModel.mapCheckAllPermissions(data.checkAll, data.permissions)
            this.viewModel.updateRolePermissions({
                roleId: this.checkedRole,
                permissions: this.viewModel.mapPermissionsChecked(this.modules)
            })
            this.$forceUpdate()
        },
        onFindPermissionsByRoleResponse(response) {
            setTimeout(() => {
                this.$set(this, 'modules', this.viewModel.bindModulesPermissions(this.modules, response.permissions))
                this.$forceUpdate()
                this.onComponentLoading(this.modalConfig, false, '')
            }, 500);
        },
        onUpdateRolePermissionsResponse(response) {
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.onComponentLoading(this.modalConfig, false, '')
            }, 500);
        }
    }
}