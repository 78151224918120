<script>
import ModuleProductRackLocationsComponent from '../features/ModuleProductRackLocationsComponent'
import ListProductRackLocationsView from "./ListProductRackLocationsView";

export default {
  components: {
    "list-product-rack-locations-view": ListProductRackLocationsView,
  },
  extends: ModuleProductRackLocationsComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-th" /> Catálogos / Ubicaciones / Existencias
        </h4>
      </div>
      <div class="page-centerheader">
        <el-row type="flex" class="row-bg wrapper-filters" :gutter="20">
          <el-col :md="{span: 8, offset: 16}" :lg='{span: 8, offset: 16}' :xl='{span: 8, offset: 16}' align="center">
            
          </el-col>
        </el-row>
        <el-drawer custom-class="filters-drawer" :visible.sync="isMoreFilterVisible" direction="rtl" size="20%">
          <template slot="title">
            <span class="title">Filtros</span> <el-button class="btn-text" type="text" @click="onClearFilters">Borrar todos los filtros</el-button>
          </template>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Rack:</span>
                <el-select
                  v-model="filtersSelected.rack"
                  filterable
                  placeholder="Buscar por rack"
                  size="small"
                  @change="onRackChange"
                  clearable
                >
                  <el-option
                    v-for="item in racks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">¿Activo?:</span>
                <el-select size="small"
                  v-model="filtersSelected.isActive"
                  filterable
                  clearable
                  placeholder="Seleccionar"
                  @change="onActiveChange"
                  value-key="id">
                  <el-option v-for="item in activeStates" :key="item.id" :label="item.name" :value="item.state"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
        <div class="btn-list">
          <el-button
            type="default"
            plain
            icon="fa fa-filter"
            size="small"
            @click="onMoreFilters"
          >
            Filtrar por:
          </el-button>
        </div>
      </div>
    </div>
    <!--End Page header-->
    <list-product-rack-locations-view />
  </div>
</template>
