import BaseViewModel from "../../infraestructure/BaseViewModel";
import ProductDevolution from "./ProductDevolution";
import Product from '../../features/products/Product';
import Staff from '../../features/staff/Staff';

export default class ProductDevolutionViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new ProductDevolution(data, "create");
    }

    edit(data) {
        return new ProductDevolution(data, "update", "traces", data.traces);
    }

    save(data) {
        if (data.state === "create") {
            this.api.productDevolutions
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.productDevolutions
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.productDevolutions
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.productDevolutions
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.productDevolutions
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.productDevolutions.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    exportPdf(id) {
        this.api.productDevolutions
            .exportPdf(id)
            .then((response) => this.view.onExportPdfResponse(response.data))
            .catch(this.view.onError);
    }

    exportExcel(id) {
        this.api.productDevolutions.exportToExcel(id)
            .then(response => {
                console.log(response)
                this.view.onExportResponse(response.data)
            })
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            consecutiveNumber: '',
            receptionDate: '',
            responsible: '',
            status: null,
            isActive: null,
        };

        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Status');
        this.view.removeFilter('IsActive');
        this.view.removeFilter('StartDate');
        this.view.removeFilter('EndDate');
        this.view.onFilter();
    }

    loadTheoretical(file) {
        this.api.productDevolutions.loadTheoretical(file)
            .then((response) => this.view.onLoadTheoreticalResponse(response.data))
            .catch(this.view.onLoadTheoreticalError);
    }

    bindFormView(formData) { 
        if(formData.state === "update") {
            this.view.responsibles = this.mapStaff([formData.responsible]);
            formData.setUpdateTraces()
            formData.bindProductTraces()
        }
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
        }, 400);
    }

    bindList() {
        this.api.staff.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.staffs = response.data.data
            })
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new ProductDevolution(item));
    }

    //#endregion

    searchProduct(query) {
        this.api.products
            .find(query)
            .then((response) => this.view.onSearchProductResponse(response.data))
            .catch(this.view.onError);
    }

    mapProducts(collection) {
        return collection.map((item) => new Product(item));
    }

    searchStaff(query) {
        this.api.staff
            .find(query)
            .then((response) => this.view.onSearchStaffResponse(response.data))
            .catch(this.view.onError);
    }

    mapStaff(collection) {
        return collection.map((item) => new Staff(item));
    }

}