import BaseForm from '../../infraestructure/BaseForm';
import ProductSeekerViewModel from "../../../core/features/productSeeker/ProductSeekerViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            isCategoriesLoading: false,
            isBrandsLoading: false,
            isMeasureUnitsLoading: false,
            isWarehouseLoading: false,
            filters: {
                categories: [],
                brands: [],
                measureUnits: [],
                warehouses: []
            },
            categories: [],
            brands: [],
            measureUnits: [],
            warehouses: []
        }
    },
    created() {
        this.viewModel = new ProductSeekerViewModel(this);
        this.onActive();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    watch: {
        'filters.categories'() {
            this.onFilter()
        },
        'filters.brands'() {
            this.onFilter()
        },
        'filters.measureUnits'() {
            this.onFilter()
        },
        'filters.warehouses'() {
            this.onFilter()
        }
    },
    methods: {
        onActive() {
            this.viewModel.bindFiltersView()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-product-seeker', this.filters)
        },
        onClearCategories() {
            this.isCategoriesLoading = true
            this.filters.categories = []
            setTimeout(() => {
                this.isCategoriesLoading = false
            }, 400);
        },
        onClearBrands() {
            this.isBrandsLoading = true
            this.filters.brands = []
            setTimeout(() => {
                this.isBrandsLoading = false
            }, 400);
        },
        onClearMeasureUnits() {
            this.isMeasureUnitsLoading = true
            this.filters.measureUnits = []
            setTimeout(() => {
                this.isMeasureUnitsLoading = false
            }, 400);
        },
        onClearWarehouses() {
            this.isWarehouseLoading = true
            this.filters.warehouses = []
            setTimeout(() => {
                this.isWarehouseLoading = false
            }, 400);
        }
    }
}