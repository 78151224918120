<script>
import ModalProductInventoryComponent from "../features/ModalProductInventoryComponent";
import ModalImportView from "../../shared/views/ModalImportView";
export default {
  name: "ModalProductInventoryComponent",
  components: {
    ModalImportView,
  },
  extends: ModalProductInventoryComponent,
};
</script>

<template>
  <div>
    <el-dialog
      title="Inventario de Productos"
      :visible.sync="config.isVisible"
      custom-class="modal-product-inventory"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">
        <i class="fa fa-list-alt" /> Inventario de Productos
      </span>
      <el-form
        ref="formData"
        v-loading="config.isLoading"
        :element-loading-text="config.isLoadingText"
        :model="formData"
        :rules="rules"
        status-icon
        :label-position="'top'"
      >
        <fieldset>
          <legend>Información del Inventario</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :md="8" :lg="4" :xl="4">
              <el-form-item label="Folio" prop="consecutiveNumber">
                <el-input
                  v-model="formData.consecutiveNumber"
                  size="small"
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :md="8" :lg="4" :xl="4">
              <el-form-item label="Fecha de Reporte" prop="reportDate">
                <el-date-picker
                  v-model="formData.reportDate"
                  type="date"
                  placeholder="Seleccionar"
                  size="small"
                />
              </el-form-item>
            </el-col>
            <el-col :md="8" :lg="4" :xl="4">
              <el-form-item label="Estatus" prop="reportStatus">
                <el-select
                  v-model="formData.reportStatus"
                  placeholder="seleccionar"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in reportStatuses"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="10" :lg="5" :xl="5">
              <el-form-item label="Responsable" prop="responsible">
                <el-select
                  v-model="formData.responsible"
                  placeholder="seleccionar"
                  value-key="id"
                  size="small"
                  filterable
                  clearable
                  remote
                  :remote-method="onFindResponsibles"
                >
                  <el-option
                    v-for="item in responsibles"
                    :key="item.id"
                    :label="item.getFullName()"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="10" :lg="5" :xl="5">
              <el-form-item label="Operador" prop="operator">
                <el-select
                  v-model="formData.operator"
                  placeholder="seleccionar"
                  value-key="id"
                  size="small"
                  filterable
                  clearable
                  remote
                  :remote-method="onFindOperators"
                >
                  <el-option
                    v-for="item in operators"
                    :key="item.id"
                    :label="item.getFullName()"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="2" :xl="2" align="center">
              <el-form-item label="¿Activo?" prop="isActive">
                <el-switch
                  style="display: block"
                  v-model="formData.isActive"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Si"
                  inactive-text="No"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
            style="margin-top: 25px"
          >
            <el-col :span="24">
              <el-form-item label="" prop="description">
                <el-tabs type="border-card">
                  <el-tab-pane label="Listado de Productos">
                    <div>
                      <el-row class="row-bg" :gutter="20">
                        <el-col :md="11" :lg="12" :xl="14">
                          <el-form-item prop="racks">
                            <el-select
                              v-model="formData.racks"
                              value-key="id"
                              multiple
                              clearable
                              filterable
                              remote
                              reserve-keyword
                              placeholder="Buscar categorías de racks por nombre o código..."
                              :remote-method="onFindAllRacksWithProducts"
                              size="medium"
                            >
                              <el-option
                                v-for="item in productRacks"
                                :key="item.id"
                                :label="item.name"
                                :value="item"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :md="13" :lg="12" :xl="10" align="right">
                          <el-button-group>
                            <el-button
                              type="primary"
                              size="small"
                              icon="el-icon-circle-plus"
                              @click="onAddProduct"
                            >
                              Agregar Producto
                            </el-button>
                            <el-dropdown
                              size="medium"
                            >
                              <el-button type="plain" size="small">
                                <i class="fa fa-bars" /> M&aacute;s Opciones
                                <i class="el-icon-arrow-down el-icon--right" />
                              </el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item class="my-2">
                                  <el-button
                                    class="w-100"
                                    type="default"
                                    size="small"
                                    icon="el-icon-upload"
                                    @click="onAddProductByRacks(formData.racks)"
                                    >Agregar por Rack</el-button
                                  >
                                </el-dropdown-item>
                                <el-dropdown-item class="my-2">
                                  <el-button
                                    class="w-100"
                                    type="default"
                                    size="small"
                                    icon="el-icon-upload"
                                    @click="onLoadTheoretical"
                                  >
                                    Cargar Teórico
                                  </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item class="my-2">
                                  <el-button
                                    class="w-100"
                                    type="default"
                                    size="small"
                                    icon="el-icon-upload"
                                    @click="onRemoveSelected"
                                  >
                                    Eliminar Seleccionados
                                  </el-button>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-button-group>
                        </el-col>
                      </el-row>
                    </div>
                    <el-table
                      v-loading="isTableLoading"
                      :data="formData.getCollection()"
                      style="width: 100%"
                      height="400"
                      @select-all="onSelectChange"
                      @select="onSelectChange"
                      
                    >
                      <el-table-column type="expand">
                        <template slot-scope="props">
                          <el-row type="flex" class="row-bg" :gutter="20">
                            <el-col :span="4">
                              <p v-if="props.row.product">
                                Sku: {{ props.row.product.sku }}
                              </p>
                            </el-col>
                            <el-col :span="20">
                              <p v-if="props.row.product">
                                Rack(s): {{ props.row.racks }}
                              </p>
                            </el-col>
                          </el-row>

                          <!-- <p>Ciudad: {{ props.row.city }}</p>
                        <p>Dirección: {{ props.row.address }}</p>
                        <p>Código postal: {{ props.row.zip }}</p> -->
                        </template>
                      </el-table-column>
                      <el-table-column type="selection" width="55"/>
                      <el-table-column label="Producto" min-width="250">
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.product"
                            placeholder="seleccionar"
                            value-key="id"
                            size="small"
                            remote
                            filterable
                            clearable
                            :remote-method="onFindProducts"
                            @focus="onProductFocus(scope.$index)"
                            @change="onProductChange"
                            :disabled="!scope.row.isEditable()"
                          >
                            <el-option
                              v-for="item in scope.row.products"
                              :key="item.id"
                              :label="item.name"
                              :value="item"
                            />
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Unidad"
                        width="140"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <div
                            v-if="
                              scope.row.product && scope.row.product.measureUnit
                            "
                          >
                            {{ scope.row.product.measureUnit.name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Total Teorico"
                        prop="theoreticalTotal"
                        width="140"
                        align="center"
                      />
                      <el-table-column
                        label="Total Fisico"
                        width="140"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-input-number
                            v-model="scope.row.physicalTotal"
                            :min="0"
                            size="small"
                            :disabled="!scope.row.isEditable()"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Diferencia"
                        prop="difference"
                        width="180"
                        align="center"
                      >
                        <template slot-scope="scope">
                          {{
                            scope.row.physicalTotal - scope.row.theoreticalTotal
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Opciones"
                        width="120"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-button-group>
                            <el-button
                              v-if="scope.row.id"
                              type="default"
                              size="small"
                              icon="el-icon-edit"
                              @click="onEditProduct(scope.$index)"
                            />
                            <el-button
                              type="danger"
                              size="small"
                              icon="el-icon-delete"
                              @click="onRemoveProduct(scope.$index)"
                            />
                          </el-button-group>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </el-form-item>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" class="bg-cancel" @click="onCancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          class="btn-red"
          size="small"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          >Guardar</el-button
        >
      </span>
    </el-dialog>
    <modal-import-view :conf="modalTheoretical" />
  </div>
</template>
