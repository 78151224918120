import BaseComponent from '../../infraestructure/BaseComponent'

export default {
    extends: BaseComponent,
    data() {
        return {

        };
    },
    created() {
    },
    mounted() { },
    methods: {
        
    },
    computed: {}
}