import BaseModule from '../../infraestructure/BaseModule'
import WhiteLabelSettingViewModel from '../../../core/features/whiteLabelSettings/WhiteLabelSettingViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            modulePermissions: 'SystemSettings',
            viewModel: null,
            whiteLabelSetting: null,
            rulesWhiteLabelSettings: {},
            whiteLabelSettingLoading: false,
            bindProductImagesLoading: false,
            themeColors: WhiteLabelSettingViewModel.themeColors,
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new WhiteLabelSettingViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindModule()
        },
        onFindDefaultResponse(response) {
            this.whiteLabelSetting = this.viewModel.edit(response.data)
        },
        saveWhiteLabelSetting(formName){
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.whiteLabelSettingLoading = true
                    this.viewModel.save(this.whiteLabelSetting)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            setTimeout(() => {
                this.setWhiteLabelSettings(response.data)
                this.whiteLabelSettingLoading = false
                window.location.reload()
            }, 400);
        },
        migrateProductImages(){
            this.bindProductImagesLoading = true
            this.viewModel.migrateProductImages()
        },
        onMigrateProductImagesResponse(response){
            this.notifySuccess(response.message)
            setTimeout(() => {
                this.bindProductImagesLoading = false
            }, 400);
        },
        onMigrateProductImagesError(error){
            setTimeout(() => {
                this.bindProductImagesLoading = false
            }, 400);
            this.onError(error)
        }
    }
}