import BaseApi from "../../infraestructure/BaseApi";

export default class ProductsApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    findByCategories(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-categories${parameters}`)
    }

    findByRacks(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-racks${parameters}`)
    }

    printProductQrToPdf(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/generate-qrs-pdf${parameters}`)
    }

    batchProductQrToPdf(data) {
        return this.client.post(`${this.endPoint}/batch-qrs-pdf`, data)
    }

    importStock(file){
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${this.endPoint}/import-stock`, fd)
    }
}