import BaseForm from '../../infraestructure/BaseForm'
import ProductWasteViewModel from '../../../core/features/productWastes/ProductWasteViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'ProductWastes',
            viewModel: null,
            responsibles: [],
            products: [],
            productRequests: [],
            isTableLoading: false,
            indexTraceSelected: null,
            wasteAreas: [],
            modalTheoretical: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR PRODUCTOS',
                onUploadImport: 'on-upload-import-load-theoretical',
                onCancelImport: 'on-cancel-import-load-theoretical',
            },
            rules: {
                reportDate: [{
                    required: true,
                    message: 'Fecha requerido.',
                    trigger: 'blur',
                }],
                responsible: [{
                    required: true,
                    message: 'Responsable requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.onMountedEvent('on-upload-import-load-theoretical', this.onUploadImportLoadTheoretical);
        this.onMountedEvent('on-cancel-import-load-theoretical', this.onCancelImportLoadTheoretical);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-upload-import-load-theoretical', this.onUploadImportLoadTheoretical);
        this.onBeforeDestroyEvent('on-cancel-import-load-theoretical', this.onCancelImportLoadTheoretical);
    },
    created() {
        this.viewModel = new ProductWasteViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-product-wastes', null)
        },
        onAddProduct() {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.addTrace({
                    quantity: 1,
                    product: null,
                    products: [],
                    wasteReasons: []
                })
                this.isTableLoading = false
            }, 400);
        },
        onRemoveProduct(index) {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.remove(index)
                this.isTableLoading = false
            }, 400);
        },
        onEditProduct(index) {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.setUpdateState(index)
                this.isTableLoading = false
            }, 400);
        },
        onProductFocus(index) {
            this.indexTraceSelected = index
        },
        onSearchProduct(data) {
            this.formData.getCollection()[this.indexTraceSelected].products = []
            if(data)
                this.viewModel.searchProduct(`FullSearch=${data}&IsActive=true`)
        },
        onSearchProductResponse(response) {
            this.formData.getCollection()[this.indexTraceSelected].products = this.viewModel.mapProducts(response.data)
            this.$forceUpdate()
        },
        onSearchStaff(data) {
            this.responsibles = []
            if(data)
                this.viewModel.searchStaff(`Name=${data}&IsActive=true`)
        },
        onSearchStaffResponse(response) {
            this.responsibles = this.viewModel.mapStaff(response.data)
        },
        onFindProductRequestAvailables(data){
            this.productRequests = []
            if(data)
                this.viewModel.findProductRequestAvailable(`FolNumber=${data}&Status=1`)
        },
        onFindProductRequestAvailableResponse(response){
            this.productRequests = response.data
        },
        onLoadByProductRequest(data){
            if(data) {
                this.isTableLoading = true
                this.viewModel.loadByProductRequest(data)
            }
                
        },
        onLoadByProductRequestResponse(response) {
            setTimeout(() => {
                response.data.forEach((item) => {
                    item.products = [item.product]
                    this.formData.addTrace(item)
                })
                this.isTableLoading = false
            }, 400);
        },
        onLoadByProductRequestError(error) {
            this.isTableLoading = false
            this.onError(error)
        },
        onLoadTheoretical() {
            this.modalTheoretical.isVisible = true
        },
        onUploadImportLoadTheoretical(file) {
            this.modalTheoretical.isLoading = true
            this.viewModel.loadTheoretical(file)
        },
        onLoadTheoreticalResponse(response) {
            setTimeout(() => {
                this.formData.setTraces(response.data)
                this.modalTheoretical.isLoading = false
                this.modalTheoretical.isVisible = false
            }, 400);
        },
        onLoadTheoreticalError(error) {
            setTimeout(() => {
                this.modalTheoretical.isLoading = false
                this.onError(error)
            }, 400);
        },
        onCancelImportLoadTheoretical() {
            this.modalTheoretical.isVisible = false
        },
        onChangeArea(data) {
            if(data) {
                this.isTableLoading = true
                this.viewModel.findArea(data.id)
            }
        },
        onFindAreaResponse(data) {            
            setTimeout(() => {
                this.formData.getCollection()[this.indexTraceSelected].wasteReasons = data.wasteReasons
                this.$forceUpdate()
                this.isTableLoading = false
            }, 400);
        },
        onWasteReasonFocus(index) {
            this.indexTraceSelected = index
            let wasteArea = this.formData.getCollection()[this.indexTraceSelected].wasteArea
            if(wasteArea) {
                this.isTableLoading = true
                this.viewModel.findArea(wasteArea.id)
            }
        }
    }
}