import BaseModule from '../../infraestructure/BaseModule'
import ProductInventoryViewModel from '../../../core/features/productInventories/ProductInventoryViewModel'
import _ from 'lodash';

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: ProductInventoryViewModel.activeStates,
            filtersSelected: {
                folio: '',
                receptionDate: '',
                responsible: '',
                supplier: '',
                isActive: null,
            },
            responsibles: [],
            operators: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new ProductInventoryViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-product-inventory', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByConsecutiveNumber: _.debounce(function (text) {
            (text) ? this.addFilter('ConsecutiveNumber', text) : this.removeFilter('ConsecutiveNumber')
            this.onFilter();
        }, 500),
        onSearchByReceptionDate: _.debounce(function (text) {
            if (text) {
                this.addFilter('StartDate', text[0])
                this.addFilter('EndDate', text[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onFilter();
        }, 500),
        onResponsibleChange(responsibleId) {
            (responsibleId) ? this.addFilter('ResponsibleId', responsibleId) : this.removeFilter('ResponsibleId')
            this.onFilter();
        },
        onOperatorChange(operatorId) {
            (operatorId) ? this.addFilter('OperatorId', operatorId) : this.removeFilter('OperatorId')
            this.onFilter();
        },
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}