<script>
import ModalProductRackQrComponent from "../features/ModalProductRackQrComponent";

export default {
  name: "ModalProductRackQrView",
  extends: ModalProductRackQrComponent,
};
</script>
<template>
  <el-dialog
    :title="conf.title"
    custom-class="modal-product-qr"
    :visible.sync="conf.isVisible"
    :before-close="onAccept"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="fa fa-cubes" /> {{conf.title}}
    </span>
    <el-form
      ref="formData"
      v-loading="conf.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>FILTROS DE BUSQUEDA</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="block">
              <span class="block-title">Codigo:</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar por Codigo.."
                placement="top"
              >
                <el-input
                  v-model="formData.codeFilter"
                  size="small"
                  placeholder="Buscar Code..."
                  icon="search"
                  suffix-icon="el-icon-search"
                  @change="addFilter('code', formData.codeFilter)"
                />
              </el-tooltip>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="block">
              <span class="block-title">Nombre:</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar por Nombre."
                placement="top"
              >
                <el-input
                  v-model="formData.nameFilter"
                  size="small"
                  placeholder="Buscar Nombre..."
                  icon="search"
                  suffix-icon="el-icon-search"
                  @change="addFilter('Name', formData.nameFilter)"
                />
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :sm="24" :md="24" :lg="24" :xl="24">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                class="btn-gold"
                icon="fa fa-file-excel-o"
                @click="onPrintPdf"
                :disabled="!onCanRead(modulePermissions)"
              >
                Exportar PDF
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onAccept"
        >Aceptar</el-button
      >
    </span>
  </el-dialog>
</template>