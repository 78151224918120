import BaseForm from '../../infraestructure/BaseForm'
import WasteAreaViewModel from '../../../core/features/wasteAreas/WasteAreaViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'WasteArea',
            viewModel: null,
            selectedWasteReasonIndex: null,
            isTableLoading: false,
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur',
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new WasteAreaViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-waste-areas', null)
        },
        onFindWasteReasons(data){
            if(data)
                this.viewModel.findWasteReasons(`Skip=0&Limit=100000&IsActive=true&Name=${data}`)
        },
        onFindWasteReasonsResponse(response){
            this.formData.wasteReasons[this.selectedWasteReasonIndex].wasteReasons = response.data
        },
        onFocusWasteReason(index){
            this.selectedWasteReasonIndex = index
        },
        onAddWasteReason() {
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.wasteReasons.push({
                    state: 'create',
                    wasteReasons: []
                })
                this.isTableLoading = false
            }, 400);
        },
        onDeleteWasteReason(index){
            this.isTableLoading = true
            setTimeout(() => {
                this.formData.wasteReasons.splice(index, 1)
                this.isTableLoading = false
            }, 400);
        },
        onEditWasteReason(index) {
            this.isTableLoading = true
            setTimeout(() => {
                this.$set(this.formData.wasteReasons[index], 'state', 'update')
                this.isTableLoading = false
            }, 400);
        }
    }
}