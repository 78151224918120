import BaseModule from "../../infraestructure/BaseModule";
import DashboardViewModel from "../../../core/features/dashboard/DashboardViewModel";

export default {
  extends: BaseModule,
  data() {
    return {
      viewModel: null,
      pendingProductRequests: [],
      productsTopLowInventory: [],
      datacollection: null,
      dataPie: null,
      dataDoughnut: null,
      dataLine: null,
      dataBar: {},
      totalMovementLoading: false,
      pendingRequestsLoading: false,
      paginationLowInventory: {},
      pageSizes: [50, 100, 150, 200],
      currentPageLowInventory: 1,
      limitLowInventory: 50,
    };
  },
  mounted() {
    // this.fillBarData();
    // this.fillLineData();
    // this.fillPieData();
    // this.fillDoughnutData();
  },
  beforeDestroy() {},
  created() {
    this.viewModel = new DashboardViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.totalMovementLoading = true;
      this.pendingRequestsLoading = true;
      this.viewModel.findPendingProductRequests();
      this.viewModel.findTotalMovements();
      this.viewModel.findTotalUsersActive();
      this.viewModel.findTopLowInventory();
    },
    onFindPendingProductRequestsResponse(response) {
      setTimeout(() => {
        this.pendingRequestsLoading = false;
        this.pendingProductRequests = response.data;
      }, 400);
    },
    onFindPendingProductRequestsError(error){
      this.pendingRequestsLoading = false;
      this.onError(error);
    },
    onFindTotalMovementsResponse(response) {
      setTimeout(() => {
        this.totalMovementLoading = false;
        this.dataBar = response.data;
      }, 400);
    },
    onFindTotalMovementsError(error){
      this.totalMovementLoading = false;
      this.onError(error);
    },
    onFindTotalUsersActiveResponse(response) {
      this.dataDoughnut = response.data;
    },
    onFindTotalUsersActiveError(error){
      this.onError(error);
    },
    onFindTopLowInventoryResponse(headers, response) {
      this.paginationLowInventory = this.getPagination(headers);
      this.paginationLowInventory.limit = response.limit;
      this.productsTopLowInventory = response.data;
    },
    getPagination(headers) {
      if (!headers) throw new Error("Parametro headers requerido.");
      const pagination = JSON.parse(headers["x-pagination"]);
      return {
        totalItems: pagination.totalItems,
        totalPages: pagination.totalPages,
      };
    },
    onPageChangeLowInventory(data){
      this.currentPageLowInventory = data;
      this.viewModel.findTopLowInventory(`Skip=${this.currentPageLowInventory}&Limit=${this.limitLowInventory}`);
    },
    onPageSizeChangeLowInventory(data) {
      this.limitLowInventory = data;
      this.viewModel.findTopLowInventory(`Skip=${this.currentPageLowInventory}&Limit=${this.limitLowInventory}`);
    },
    onFindTopLowInventoryError(error){
      this.onError(error);
    },
    fillBarData() {
      this.dataBar = {
        labels: [
          "Bimestre 1",
          "Bimestre 2",
          "Bimestre 3",
          "Bimestre 4",
          "Bimestre 5",
          "Bimestre 6",
        ],
        datasets: [
          {
            label: "No. de Productos",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
            borderColor: "rgb(85, 91, 109)",
            backgroundColor: [
              "#555b6d",
              "rgba(54, 162, 235)",
              "rgba(255, 206, 86)",
              "#f87979",
              "rgba(153, 102, 255)",
              "rgba(255, 159, 64)",
            ],
          },
        ],
      };
    },
    fillLineData() {
      this.dataLine = {
        labels: [
          "Bimestre 1",
          "Bimestre 2",
          "Bimestre 3",
          "Bimestre 4",
          "Bimestre 5",
          "Bimestre 6",
        ],
        datasets: [
          {
            label: "No. de Productos",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
            borderColor: "rgb(85, 91, 109)",
            backgroundColor: "rgb(85, 91, 109, 0.6)",
          },
          {
            label: "No. de Productos",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
            borderColor: "rgba(255, 205, 86)",
            backgroundColor: "rgb(255, 205, 86, 0.6)",
          },
        ],
      };
    },
    fillPieData() {
      this.dataPie = {
        labels: [
          "Bimestre 1",
          "Bimestre 2",
          "Bimestre 3",
          "Bimestre 4",
          "Bimestre 5",
          "Bimestre 6",
        ],
        datasets: [
          {
            label: "No. de Productos",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
            backgroundColor: [
              "#555b6d",
              "rgba(54, 162, 235)",
              "rgba(255, 206, 86)",
              "#f87979",
              "rgba(153, 102, 255)",
              "rgba(255, 159, 64)",
            ],
          },
        ],
      };
    },
    fillDoughnutData() {
      this.dataDoughnut = {
        labels: ["Administrador", "Supervisor", "Almacenista"],
        datasets: [
          {
            label: "Busquedas",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
            backgroundColor: [
              "rgb(85, 91, 109)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
            ],
            hoverOffset: 4,
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
  },
};
