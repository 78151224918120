<script>
import ModuleProductDeliveryComponent from '../features/ModuleProductDeliveryComponent'
import ListProductDeliveryView from "./ListProductDeliveryView";

export default {
  components: {
    "list-product-delivery-view": ListProductDeliveryView,
  },
  extends: ModuleProductDeliveryComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-handshake-o" /> Reportes / Entregas de Productos
        </h4>
      </div>
      <div class="page-centerheader">
        <el-drawer custom-class="filters-drawer" :visible.sync="isMoreFilterVisible" direction="rtl" size="20%">
          <template slot="title">
            <span class="title">Filtros</span> <el-button class="btn-text" type="text" @click="onClearFilters">Borrar todos los filtros</el-button>
          </template>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Folio:</span>
                <el-input
                  type="text"
                  placeholder="Buscar por folio"
                  v-model="filtersSelected.consecutiveNumber"
                  clearable
                  size="small"
                  v-on:input="onSearchByFolio"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Responsable:</span>
                <el-select
                  v-model="filtersSelected.responsible"
                  filterable
                  placeholder="Seleccionar"
                  size="small"
                  @change="onResponsibleChange"
                  clearable
                >
                  <el-option
                    v-for="item in staffs"
                    :key="item.id"
                    :label="item.firstName + ' ' + item.lastName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Fecha de Reporte:</span>
                <el-date-picker
                  v-model="filtersSelected.receptionDate"
                  type="daterange"
                  size="small"
                  clearable
                  range-separator="-"
                  start-placeholder="F. Inicio"
                  end-placeholder="F. Fin"
                  value-format="yyyy-MM-dd"
                  v-on:input="onSearchByReceptionDate"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">¿Activo?:</span>
                <el-select size="small"
                  v-model="filtersSelected.isActive"
                  filterable
                  clearable
                  placeholder="Seleccionar"
                  @change="onActiveChange"
                  value-key="id">
                  <el-option v-for="item in activeStates" :key="item.id" :label="item.name" :value="item.state"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
        <div class="btn-list">
          <el-button
            type="default"
            plain
            icon="fa fa-filter"
            size="small"
            @click="onMoreFilters"
          >
            Filtrar por:
          </el-button>
        </div>
      </div>
    </div>
    <!--End Page header-->
    <list-product-delivery-view />
  </div>
</template>
