<script>
import ModalProductQrComponent from "../features/ModalProductQrComponent";

export default {
  name: "ModalProductQrView",
  extends: ModalProductQrComponent,
};
</script>
<template>
  <el-dialog
    :title="conf.title"
    custom-class="modal-product-qr"
    :visible.sync="conf.isVisible"
    :before-close="onAccept"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="fa fa-cubes" /> {{conf.title}}
    </span>
    <el-form
      ref="formData"
      v-loading="conf.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>FILTROS DE BUSQUEDA</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="block">
              <span class="block-title">Sku Internacional:</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar por Sku Int.."
                placement="top"
              >
                <el-input
                  v-model="formData.skuIntFilter"
                  size="small"
                  placeholder="Buscar Sku Int..."
                  icon="search"
                  suffix-icon="el-icon-search"
                  @change="addFilter('IntSku', formData.skuIntFilter)"
                />
              </el-tooltip>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="block">
              <span class="block-title">Sku Interno:</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar por Sku Sap."
                placement="top"
              >
                <el-input
                  v-model="formData.skuFilter"
                  size="small"
                  placeholder="Buscar Sku Sap..."
                  icon="search"
                  suffix-icon="el-icon-search"
                  @change="addFilter('Sku', formData.skuFilter)"
                />
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="block">
              <span class="block-title">Nombre:</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar por Nombre."
                placement="top"
              >
                <el-input
                  v-model="formData.nameFilter"
                  size="small"
                  placeholder="Buscar Nombre..."
                  icon="search"
                  suffix-icon="el-icon-search"
                  @change="addFilter('Name', formData.nameFilter)"
                />
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="block">
              <span class="block-title">Categoría:</span>
              <el-select
                v-model="formData.categoryFilter"
                size="small"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="Seleccionar"
                :remote-method="onFindProductCategories"
                value-key="id"
                @change="addFilter('CategoryId', formData.categoryFilter.id)"
              >
                <el-option
                  v-for="item in productCategories"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="block">
              <span class="block-title">Tipo de Empaque:</span>
              <el-select
                v-model="formData.packingTypeFilter"
                size="small"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="Seleccionar"
                :remote-method="onFindProductPackingTypes"
                value-key="id"
                @change="
                  addFilter('PackingTypeId', formData.packingTypeFilter.id)
                "
              >
                <el-option
                  v-for="item in productPackingTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="block">
              <span class="block-title">Unidades de Empaque:</span>
              <el-select
                v-model="formData.packingUnitFilter"
                size="small"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="Seleccionar"
                :remote-method="onFindProductPackingUnits"
                value-key="id"
                @change="
                  addFilter('PackingUnitId', formData.packingUnitFilter.id)
                "
              >
                <el-option
                  v-for="item in productPackingUnits"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="block">
              <span class="block-title">Almacén:</span>
              <el-select
                v-model="formData.warehouse"
                size="small"
                filterable
                clearable
                placeholder="Seleccionar"
                value-key="id"
                @change="
                  addFilter('WarehouseId', formData.warehouse.id)
                "
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :sm="24" :md="24" :lg="24" :xl="24">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                class="btn-gold"
                icon="fa fa-file-excel-o"
                @click="onPrintPdf"
                :disabled="!onCanRead(modulePermissions)"
              >
                Exportar PDF
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onAccept"
        >Aceptar</el-button
      >
    </span>
  </el-dialog>
</template>