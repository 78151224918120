<script>
import ModuleProductPackingTypesComponent from '../features/ModuleProductPackingTypesComponent'
import ListProductPackingTypesView from "./ListProductPackingTypesView";

export default {
  components: {
    "list-productPackingTypes-view": ListProductPackingTypesView,
  },
  extends: ModuleProductPackingTypesComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-cube" /> Catálogos / Artículos / Empaques
        </h4>
      </div>
      <div class="page-centerheader">
        <el-row class="row-bg wrapper-filters" :gutter="20">
          <el-col :md="{span: 8, offset: 8}" :lg='{span: 8, offset: 8}' :xl='{span: 8, offset: 8}' align="center">
            
          </el-col>
          <el-col :md="8" :lg='8' :xl='8' align="center">
            
          </el-col>
        </el-row>
        <el-drawer custom-class="filters-drawer" :visible.sync="isMoreFilterVisible" direction="rtl" size="20%">
          <template slot="title">
            <span class="title">Filtros</span> <el-button class="btn-text" type="text" @click="onClearFilters">Borrar todos los filtros</el-button>
          </template>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Codigo:</span>
                <el-input
                  type="text"
                  placeholder="Buscar por código"
                  v-model="filtersSelected.code"
                  clearable
                  size="small"
                  v-on:input="onSearchByCode"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">Nombre:</span>
                <el-input
                  type="text"
                  placeholder="Buscar por nombre"
                  v-model="filtersSelected.name"
                  clearable
                  size="small"
                  v-on:input="onSearchByName"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24"  :lg='24' :xl='24'>
              <div class="block">
                <span class="block-title">¿Activo?:</span>
                <el-select size="small"
                  v-model="filtersSelected.isActive"
                  filterable
                  clearable
                  placeholder="Seleccionar"
                  @change="onActiveChange"
                  value-key="id">
                  <el-option v-for="item in activeStates" :key="item.id" :label="item.name" :value="item.state"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
        <div class="btn-list">
          <el-button
            type="default"
            plain
            icon="fa fa-filter"
            size="small"
            @click="onMoreFilters"
          >
            Filtrar por:
          </el-button>
        </div>
      </div>
    </div>
    <!--End Page header-->
    <list-productPackingTypes-view />
  </div>
</template>
