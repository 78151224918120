import BaseList from '../../infraestructure/BaseList'
import SuppliersViewModel from '../../../core/features/suppliers/SuppliersViewModel'

export default {
    extends: BaseList,
    data(){
        return {
            modulePermissions: 'Suppliers',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR PROVEEDORES',
                onUploadImport: 'on-upload-import-suppliers',
                onCancelImport: 'on-cancel-import-suppliers',
            },
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-suppliers', this.onFilter)
        this.onMountedEvent('on-submited-suppliers', this.onSearch)
        this.onMountedEvent('on-upload-import-suppliers', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-suppliers', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-suppliers', this.onFilter)
        this.onBeforeDestroyEvent('on-submited-suppliers', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-suppliers', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-suppliers', this.onCancelImport);
    },
    created() {
        this.viewModel = new SuppliersViewModel(this)
        this.onActive()
    },
    methods: { 
        onActive() {
            this.onSearch()
        },
        onFilter(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate(){
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true
            })
        }
    }
}