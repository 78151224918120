import ChildrenList from "../../generics/childrenList/ChildrenList"
import Product from '../products/Product'
export default class ProductRackLocation extends ChildrenList{
    constructor(options, state, childrenListName, childrenList) {
        super(options, state, childrenListName || 'products', childrenList || [])
    }

    toSend() {
        return {
            id: this.id,
            rackId: (this.rack) ? this.rack.id : null,
            products: this.products.map(product => product.toRackSend()),
            isActive: this.isActive
        }
    }

    addProduct(data) {
        data.updateState = this.UPDATE_STATUS.ADDED
        this.products.push(new Product(data))
    }
}