import BaseModel from "../../infraestructure/BaseModel"

export default class CargoReceptionConfigurationNotification extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id,
            applicationUserId: (this.applicationUser) ? this.applicationUser.id : null,
            updateStatus: this.updateStatus,
        }
    }
}