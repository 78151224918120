<script>
import ModalProductRackLocationComponent from "../features/ModalProductRackLocationComponent";
export default {
  name: "ModalProductRackLocationsView",
  extends: ModalProductRackLocationComponent,
};
</script>

<template>
  <el-dialog
    title="Productos en Rack"
    :visible.sync="config.isVisible"
    width="45%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="fa fa-th" /> Existencias
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Existencias</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :md="24" :lg="20" :xl="20">
            <el-form-item label="Rack" prop="rack">
              <el-select
                v-model="formData.rack"
                size="small"
                filterable
                remote
                placeholder="Buscar rack..."
                :remote-method="onFindRacks"
                clearable
                @change="onChangeRack"
                value-key="id"
              >
                <el-option
                  v-for="item in racks"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="4" :xl="4" align="center">
            <el-form-item label="¿Activo?" prop="isActive">
              <el-switch
                style="display: block"
                v-model="formData.isActive"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Si"
                inactive-text="No"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item
              label="Categorias de Productos"
              prop="productCategories"
            >
              <el-select
                v-model="formData.productCategories"
                value-key="id"
                multiple
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="Buscar producto..."
                :remote-method="onFindCategories"
                size="medium"
                :disabled="!formData.rack"
              >
                <el-option
                  v-for="item in productCategories"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="right">
            <el-button-group>
              <el-button
                type="default"
                size="medium"
                icon="el-icon-plus"
                @click="onAddProductByCategories(formData.productCategories)"
              >
                Agregar Por Categoria
              </el-button>
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-plus"
                @click="onAddProduct(formData.rack)"
              >
                Agregar Producto
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-table
              v-loading="isTableLoading"
              :data="formData.getCollection()"
              style="width: 100%"
              height="420"
            >
              <el-table-column label="Producto">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`products.${scope.$index}.product`"
                    :rules="{
                      required: true,
                      message: 'Producto requerido...',
                      trigger: 'blur, change',
                    }"
                  >
                    <el-select
                      v-model="scope.row.product"
                      size="small"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="Buscar producto..."
                      :remote-method="onFindProducts"
                      clearable
                      value-key="id"
                      @focus="onProductFocus(scope.$index)"
                    >
                      <el-option
                        v-for="item in scope.row.elements"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="Opciones"
                width="120"
                align="center"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <el-dropdown @command="onChangeOption">
                    <el-button size="small">
                      <i class="el-icon-more" />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{
                            command: 'delete',
                            item: scope.row,
                            index: scope.$index,
                          }"
                        >
                          <i class="el-icon-delete" /> Eliminar
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
