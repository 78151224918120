import _ from 'lodash';
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Permission from "./Permission";

export default class PermissionsViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    create(data) {
        return new Permission(data, "create");
    }

    edit(data) {
        return new Permission(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.permissions
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.permissions
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.permissions
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.permissions
            .find(this.getQueryParameters(filters))
            .then((response) =>
                this.view.onSearchResponse(response.data, response.headers)
            )
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.permissions
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.permissions.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindFormView() {
        var result = {
            roles: [],
            modules: []
        }
        this.api.roles.find()
            .then((response) => {
                result.roles = response.data.data
                return this.api.security.getPermissions()
            })
            .then((response) => {
                result.modules = response.data.data
                this.view.onBindFormResponse(result)
            })
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Permission(item));
    }

    mapCheckAllPermissions(checkAll, permissions) {
        permissions.forEach((item) => {
            item.isChecked = checkAll
        })
    }

    mapPermissionsChecked(modules) {
        var permissions = modules.flatMap((item) => item.permissions);
        return permissions.filter((item) => item.isChecked).map((item) => item.codePermission)
    }

    //#endregion

    //#region PERMISSIONS
    
    findPermissionsByRole(roleId) {
        this.api.security.getPermissionsByRole(roleId)
            .then((response) => this.view.onFindPermissionsByRoleResponse(response.data))
            .catch(this.view.onError);
    }

    updateRolePermissions(data) {
        this.api.security.updateRolePermissions(data)
            .then((response) => this.view.onUpdateRolePermissionsResponse(response.data))
            .catch(this.view.onError);
    }

    bindModulesPermissions(modules, permissions) {
        modules.flatMap((item) => item.permissions).forEach((item) => {
            let currentPermission = permissions.find((permission) => permission.code === item.codePermission)
            item.isChecked = (!_.isNil(currentPermission))
        });
        modules.forEach((item) => { 
            item.checkAll = item.permissions.every((permission) => permission.isChecked)
        });
        return modules
    }

    //#endregion

}