import BaseForm from "../../infraestructure/BaseForm";
import ProductSeekerViewModel from "../../../core/features/productSeeker/ProductSeekerViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      product: null,
      viewModel: null,
      coverProduct: null,
      isLoading: false,
      shopping: {
        quantity: 1,
      },
    };
  },
  created() {
    this.viewModel = new ProductSeekerViewModel(this);
    this.onActive();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onActive() {
      this.viewModel.bindFormView(this.$route.params.id);
      this.shopping.productId = this.$route.params.id;
    },
    onFindProductResponse(response) {
        this.product = response.data;
        this.coverImage = response.data.productImages.find((x) => x.isCover)
        this.coverProduct = (this.coverImage) ? this.coverImage.fileUrl : this.onMapProductImage(this.coverImage);
    },
    onSelectImage(productImage) {
      this.coverProduct = productImage.fileUrl;
    },
    onAddToRequest() {
      this.isLoading = true;
      this.viewModel.addToRequest(this.shopping);
    },
    onAddToRequestResponse() {
      this.onNotifyEvent("on-update-shopping-cart", null);
      this.isLoading = false;
    },
    onAddToRequestError(error) {
      this.isLoading = false;
      this.onError(error);
    },
    onMapProductImage(fileUrl) {
      return fileUrl ? fileUrl : "/assets/logo_default_product_stock.png";
    },
  },
};
