import BaseForm from '../../infraestructure/BaseForm'
import ProductCategoryViewModel from '../../../core/features/productCategories/ProductCategoryViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'ProductCategory',
            viewModel: null,
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur',
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ProductCategoryViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-product-category', null)
        }
    }
}