import BaseForm from '../../infraestructure/BaseForm'
import ShoppingCartViewModel from '../../../core/features/shoppingCarts/ShoppingCartViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            cartItems: [],
            isLoading: false,
            isVisible: false,
            totalProducts: 0,
            isBadgeLoading: false,
            responsibles: [],
            formData: {
                responsible: '',
            },
            rules: {
                responsible: [{
                    required: true,
                    message: 'Responsable requerido',
                }]
            }
        }
    },
    props: { 
        
    },
    mounted() {
        this.onMountedEvent('on-update-shopping-cart', this.onUpdateShoppingCart)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-update-shopping-cart', this.onUpdateShoppingCart)
    },
    created() {
        this.viewModel = new ShoppingCartViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.isBadgeLoading = true
            this.viewModel.findItems()
        },
        onFindItemsResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.isBadgeLoading = false
                this.totalProducts = response.totalProducts
                this.cartItems = response.data
            }, 400);
        },
        onFindItemsError(error) {
            this.onError(error)
        },
        onUpdateShoppingCart() {
            this.isLoading = true
            this.isBadgeLoading = true
            this.viewModel.findItems()
        },
        onRemoveAllItemsCart() {
            this.isLoading = true
            this.viewModel.removeAllItems()
        },
        onRemoveAllItemsResponse(response){
            setTimeout(() => {
                this.isLoading = false
                this.totalProducts = response.totalProducts
                this.cartItems = response.data
            }, 400);
        },
        onRemoveAllItemsError(error){
            this.isLoading = false
            this.onError(error)
        },
        onChangeViewItems() {
            this.isVisible = !this.isVisible
        },
        onQuantityChange(item) {
            this.isLoading = true
            this.viewModel.updateItem(item.id, {
                quantity: item.quantity,
            })
        },
        onUpdateItemResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.totalProducts = response.totalProducts
                this.cartItems = response.data
            }, 400);
        },
        onUpdateItemError(error) {
            this.isLoading = false
            this.onError(error)
        },
        onRemoveItem(item) {
            this.isLoading = true
            this.viewModel.removeItem(item)
        },
        onRemoveItemResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.totalProducts = response.totalProducts
                this.cartItems = response.data
            }, 400);
        },
        onRemoveItemError(error) {
            this.isLoading = false
            this.onError(error)
        },
        showItems() {
            return this.cartItems.length > 0 && this.isVisible
        },
        onCreateProductRequest(formName){
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.isLoading = true
                    this.viewModel.createProductRequestReport({
                        responsibleId: this.formData.responsible.id,
                    })
                    return true
                }
                return false
            })
        },
        onCreateProductRequestReportResponse(response) {
            setTimeout(() => {
                this.isVisible = false
                this.isLoading = false
                this.totalProducts = response.totalProducts
                this.cartItems = response.data
                this.notifySuccess(response.message)
            }, 400);
        },
        onCreateProductRequestReportError(error) {
            this.isLoading = false
            this.onError(error)
        },
        onFindResponsibles(query) {
            this.responsibles =  []
            this.viewModel.findResponsibles(`FullSearch=${query}`)
        },
        onFindResponsiblesResponse(response) {
            this.responsibles = this.viewModel.mapUsers(response.data)
        },
    }
}