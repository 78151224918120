import BaseForm from '../../infraestructure/BaseForm'
import CargoReceptionsViewModel from '../../../core/features/cargoReceptions/CargoReceptionsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'CargoDeliverySchedule',
            viewModel: null,
            suppliers: [],
            users: [],
            selectedNotificationIndex: null,
            isInvoiceFileLoading: false,
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                receptionDate: [{
                    required: true,
                    message: 'F. Recepción requerida.',
                    trigger: 'blur'
                }],
                supplier: [{
                    required: true,
                    message: 'Paquetería requerida.',
                    trigger: 'blur'
                }],
                responsible: [{
                    required: true,
                    message: 'Responsable requerido.',
                    trigger: 'blur'
                }],
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CargoReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-cargo-reception', null)
        },
        onFindSuppliers(data) {
            this.suppliers = []
            if (data) {
                this.viewModel.findSuppliers(`AliasName=${data}Skip=0&Limit=100000&IsActive=true`)
            }
        },
        onFindSuppliersResponse(response) {
            console.log(response)
            this.suppliers = response.data
        },
        onAddPackage() {
            this.formData.addPackage({
                description: '',
            })
        },
        onEditPackage(item) {
            item.updateStatus =  2 // BaseModel.UPDATE_STATUS.UPDATED
            this.$forceUpdate()
        },
        onDeletePackage(index) {
            this.$confirm("¿Desea eliminar el paquete seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.formData.removePackage(index)
                    this.$forceUpdate()
                })
                .catch(() => { });
        },
        onAddConfigurationNotification() {
            this.formData.addConfigurationNotification({
                applicationUser: '',
                users: []
            })
        },
        onEditConfigurationNotification(item){
            item.updateStatus = 2 // BaseModel.UPDATE_STATUS.UPDATED
            this.$forceUpdate()
        },
        onDeleteConfigurationNotification(index) {
            this.$confirm("¿Desea eliminar la notificación seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.formData.removeConfigurationNotification(index)
                    this.$forceUpdate()
                })
                .catch(() => { });
        },
        onFindResponsible(data) {
            // this.formData.responsible = ''
            this.users = []
            if (data) this.viewModel.findResponsible(`Username=${data}Skip=0&Limit=100000&IsActive=true`)
        },
        onFindResponsiblesResponse(response) {
            this.$set(this, 'users', this.viewModel.mapUsers(response.data))
        },
        onFindUser(data) {
            if (data) this.viewModel.findUsers(`Username=${data}Skip=0&Limit=100000&IsActive=true`)
        },
        onFocusUser(index) {
            this.selectedNotificationIndex = index
        },
        onChangeUser() {
            this.selectedNotificationIndex = null
        },
        onFindUsersResponse(response) {
            this.formData.setUsersForSelector(this.selectedNotificationIndex, this.viewModel.mapUsers(response.data))
            this.$forceUpdate()
        },
        async onUploadInvoiceChange(file) {
            this.isInvoiceFileLoading = true
            setTimeout(async () => {
                this.formData.invoiceFile = file.raw
                this.formData.invoiceFileUrl = await this.viewModel.convertFileToBase64(file.raw)
                this.$forceUpdate()
                this.isInvoiceFileLoading = false
            }, 600);
        },
        onDownloadInvoiceFile(item) {
            this.isInvoiceFileLoading = true
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.invoiceFileUrl)
                this.isInvoiceFileLoading = false
            }, 600);
        },
        onRemoveInvoiceFile(item) {
            this.$confirm("¿Desea eliminar el archivo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.isInvoiceFileLoading = true
                    setTimeout(() => {
                        item.invoiceFileUrl = null
                        this.isInvoiceFileLoading = false
                    }, 600);

                })
                .catch(() => { });
        },
    }
}