export default class WhiteLabelSetting {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            companyName: this.companyName,
            companyColor: this.companyColor,
            companyLogo: this.companyLogo,
        }
    }
}