import BaseViewModel from '../../infraestructure/BaseViewModel';
export default class AuthorizationViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }

    bindLoginView() {
        this.api.whiteLabelSettings.findDefault()
            .then(response => this.view.bindLoginViewResponse(response.data))
            .catch(this.view.onError)
    }

    doLogin(formData) {
        this.api.security.login(formData)
            .then(response => this.view.onLoginResponse(response))
            .catch(this.view.onError)
    }

    getCurrentSession() { 
        this.api.security.getCurrenSession()
            .then(response => this.view.onFinCurrentSessionResponse(response))
            .catch(this.view.onError)
    }

    doRecoveryPassword(email) {
        this.api.security.doForgotPassword({email: email})
            .then(response => this.view.onRecoveryPasswordResponse(response.data))
            .catch(this.view.onError)
    }

    doRestorePassword(formData) {
        this.api.security.doRecoveryPassword(formData)
            .then(response => this.view.onRestorePasswordResponse(response.data))
            .catch(this.view.onRestorePasswordError)
    }
}