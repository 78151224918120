import ApiWrapper from '../infraestructure/ApiWrapper';
import EndPoints from '../infraestructure/EndPoints';
import WhiteLabelSettingApi from '../features/whiteLabelSettings/WhiteLabelSettingApi';

function getColorName(companyColor) {
    switch (companyColor) {
        case '#e9b610':
            return 'golden'
        case '#252525':
            return 'grey'
        case '#004FAC':
            return 'blue'
        default:
            return null
    }
}


const apiWrapper = new ApiWrapper();
var whiteLabelSettingsApi = new WhiteLabelSettingApi(apiWrapper, `${EndPoints.API}${EndPoints.WHITE_LABEL_SETTINGS}`);

(async () => {
    var response = await whiteLabelSettingsApi.findDefault();
    var color = getColorName(response.data.data.companyColor)
    
    if (!color) {
        await import (`../../sass/theme/element-variables.scss`)
    } else{
        await import (`../../sass/theme/element-variables-${color}.scss`)
    }
    
})()
