<script>
import ModalProductDevolutionComponent from "../features/ModalProductDevolutionComponent";
import ModalImportView from "../../shared/views/ModalImportView";
export default {
  name: "ModalProductDevolutionView",
  extends: ModalProductDevolutionComponent,
  components: {
    ModalImportView,
  },
};
</script>

<template>
  <div>
    <el-dialog
    title="Devolución de Productos"
    :visible.sync="config.isVisible"
    custom-class="modal-product-devolution"
    :before-close="onCancel"
    :close-on-click-modal="false"
    >
    <span
        slot="title"
        class="dialog-title"
      >
        <i class="fa fa-retweet" /> Devolución de Productos
      </span>
      <el-form
        ref="formData"
        v-loading="config.isLoading"
        :element-loading-text="config.isLoadingText"
        :model="formData"
        :rules="rules"
        status-icon
        :label-position="'top'"
      >
        <fieldset>
          <legend>Información de Devolución</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :md='6' :lg='6' :xl='6'>
              <el-form-item label="Folio" prop="consecutiveNumber">
                <el-input
                  v-model="formData.consecutiveNumber"
                  size="small"
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :md='6' :lg='6' :xl='6'>
              <el-form-item label="Fecha de Reporte" prop="reportDate">
                <el-date-picker
                  v-model="formData.reportDate"
                  type="date"
                  placeholder="Seleccionar"
                  size="small"
                />
              </el-form-item>
            </el-col>
            <el-col :md='8' :lg='8' :xl='9'>
              <el-form-item label="Responsable" prop="responsible">
                <el-select
                  v-model="formData.responsible"
                  placeholder="seleccionar"
                  value-key="id"
                  size="small"
                  filterable
                  clearable
                  remote
                  :remote-method="onSearchStaff"
                >
                  <el-option
                    v-for="item in responsibles"
                    :key="item.id"
                    :label="item.getFullName()"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md='4' :lg='4' :xl='3' align="center">
              <el-form-item label="¿Activo?" prop="isActive">
                <el-switch
                  style="display: block"
                  v-model="formData.isActive"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Si"
                  inactive-text="No">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20" style="margin-top:25px;">
            <el-col :span="24">
              <el-tabs type="border-card">
                <el-tab-pane label="Productos">
                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24" align="right">
                      <el-button-group>
                        <el-button
                          type="default"
                          size="small"
                          icon="el-icon-upload"
                          @click="onLoadTheoretical"
                        >
                          Cargar Productos
                        </el-button>
                        <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-circle-plus"
                          @click="onAddProduct"
                        >
                          Agregar Producto
                        </el-button>
                      </el-button-group>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24">
                      <el-form-item prop="productReceptionTraces">
                        <el-table
                          v-loading="isTableLoading"
                          :data="formData.getCollection()"
                          style="width: 100%"
                        >
                          <el-table-column prop="product" label="Producto">
                            <template slot-scope="scope">
                              <el-form-item :prop="'traces.' + scope.$index  + '.product'" :rules="{ required: true, message: 'Producto requerido.'}">
                                <el-select
                                  v-model="scope.row.product"
                                  placeholder="seleccionar"
                                  value-key="id"
                                  size="small"
                                  remote
                                  filterable
                                  clearable
                                  :remote-method="onSearchProduct"
                                  @focus="onProductFocus(scope.$index)"
                                  :disabled="!scope.row.isEditable()"
                                >
                                  <el-option
                                    v-for="item in scope.row.products"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item"
                                  />
                                </el-select>
                              </el-form-item>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="Cantidad"
                            width="160"
                            align="center"
                          >
                            <template slot-scope="scope">
                              <el-form-item :prop="'traces.' + scope.$index  + '.quantity'" :rules="{ required: true, message: 'Cantidad requerida.'}">
                                <el-input-number
                                  v-model="scope.row.quantity"
                                  :min="1"
                                  size="small"
                                  :disabled="!scope.row.isEditable()"
                                />
                              </el-form-item>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Opciones"
                            width="160"
                            align="center"
                          >
                            <template slot-scope="scope">
                              <el-button-group>
                                <el-button
                                  v-if="scope.row.id"
                                  type="default"
                                  size="small"
                                  icon="el-icon-edit"
                                  @click="onEditProduct(scope.$index)"
                                />
                                <el-button
                                  type="danger"
                                  size="small"
                                  icon="el-icon-delete"
                                  @click="onRemoveProduct(scope.$index)"
                                />
                              </el-button-group>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" class="bg-cancel" @click="onCancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          class="btn-red"
          size="small"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          >Guardar</el-button
        >
      </span>
    </el-dialog>
    <modal-import-view :conf="modalTheoretical" />
  </div>
</template>
