import BaseModel from "../../infraestructure/BaseModel"

export default class CargoReceptionPackage extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id,
            trackingNumber: this.trackingNumber,
            qty: this.qty,
            updateStatus: this.updateStatus,
        }
    }
}