<script>
import MainMenuComponent from "../features/MainMenuComponent";

export default {
  name: "MainMenuView",
  mixins: [MainMenuComponent],
};
</script>

<template>
  <div v-if="isAuthenticated()" class="horizontal-main hor-menu clearfix">
    <div class="horizontal-mainwrapper container clearfix">
      <nav class="horizontalMenu clearfix">
        <el-menu
          :default-active="activeLink"
          class="horizontalMenu-list"
          mode="horizontal"
          :router="true"
          menu-trigger="click"
          unique-opened
        >
          <el-menu-item
            index="/"
            aria-haspopup="true"
            class="sub-icon"
            v-if="onCanShow('Dashboard')"
          >
            <i class="el-icon-menu" />
            Principal
          </el-menu-item>
          <el-menu-item
            index="/buscador-de-productos"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['ProductSeekers'])"
          >
            <template slot="title">
              <i class="fa fa-search" aria-hidden="true"></i> Buscador de
              Productos
            </template>
          </el-menu-item>
          <el-submenu
            index="1"
            aria-haspopup="true"
            class="sub-icon"
            v-if="
              canShowMultiple([
                'ProductReceptions',
                'ProductRequests',
                'ProductDeliveries',
                'ProductDevolutions',
                'ProductInventories',
                'ProductWastes'
              ])
            "
          >
            <template slot="title">
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              Reportes
            </template>
            <el-menu-item
              index="/reportes/recepcion-de-productos"
              v-if="onCanShow('ProductReceptions')"
            >
              <template slot="title">
                <i class="fa fa-truck" aria-hidden="true"></i>
                <span>Recepciones</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reportes/entrega-de-productos"
              v-if="onCanShow('ProductDeliveries')"
            >
              <template slot="title">
                <i class="fa fa-handshake-o" aria-hidden="true"></i>
                <span>Entregas</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reportes/devolucion-de-productos"
              v-if="onCanShow('ProductDevolutions')"
            >
              <template slot="title">
                <i class="fa fa-retweet" aria-hidden="true"></i>
                <span>Devoluciones</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reportes/orden-de-productos"
              v-if="onCanShow('ProductRequests')"
            >
              <template slot="title">
                <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                <span>Ordenes</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reportes/inventario-de-productos"
              v-if="onCanShow('ProductInventories')"
            >
              <template slot="title">
                <i class="fa fa-list-alt" aria-hidden="true"></i>
                <span>Inventarios</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reportes/merma-de-productos"
              v-if="onCanShow('ProductWastes')"
            >
              <template slot="title">
                <i class="fa fa-trash" aria-hidden="true"></i>
                <span>Mermas</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="2"
            aria-haspopup="true"
            class="sub-icon"
            v-if="
              canShowMultiple([
                'Products',
                'ProductInRack',
                'Racks',
                'ProductBrands',
                'ProductPackingType',
                'ProductCategory',
                'MeasureUnit',
                'ProductPackingUnit',
                'Warehouses',
                'WasteArea', 
                'WasteReason'
              ])
            "
          >
            <template slot="title">
              <i class="fa fa-cubes" aria-hidden="true"></i>
              Catálogos
            </template>

            

            <el-submenu
              index="2-1"
              v-if="canShowMultiple(['Racks', 'ProductInRack', 'Warehouses'])"
            >
              <template slot="title">
                <i class="fa fa-th" aria-hidden="true"></i>
                <span>Ubicaciones</span>
              </template>
              <el-menu-item
                index="/catalogos/ubicaciones/existencias"
                v-if="onCanShow('ProductInRack')"
              >
                <template slot="title">
                  <i class="fa fa-th" aria-hidden="true"></i>
                  <span>Existencias</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/ubicaciones/estantes"
                v-if="onCanShow('Racks')"
              >
                <template slot="title">
                  <i class="fa fa-th" aria-hidden="true"></i>
                  <span>Estantes</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/ubicaciones/almacenes"
                v-if="onCanShow('Warehouses')"
              >
                <template slot="title">
                  <i class="fa fa-th" aria-hidden="true"></i>
                  <span>Almacenes</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="1-2"
              v-if="
                canShowMultiple([
                  'Products',
                  'ProductBrands',
                  'ProductPackingType',
                  'ProductCategory',
                  'MeasureUnit',
                  'ProductPackingUnit',
                ])
              "
            >
              <template slot="title">
                <i class="fa fa-cube" aria-hidden="true"></i>
                <span>Artículos</span>
              </template>

              <el-menu-item
              index="/catalogos/articulos/productos"
              v-if="onCanShow('Products')"
            >
              <template slot="title">
                <i class="fa fa-cube" aria-hidden="true"></i>
                <span>Productos</span>
              </template>
            </el-menu-item>

              <el-menu-item
                index="/catalogos/articulos/marcas"
                v-if="onCanShow('ProductBrands')"
              >
                <template slot="title">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                  <span>Marcas</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/articulos/empaques"
                v-if="onCanShow('ProductPackingType')"
              >
                <template slot="title">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                  <span>Empaques</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/articulos/categorias"
                v-if="onCanShow('ProductCategory')"
              >
                <template slot="title">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                  <span>Categorias</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/articulos/unidades-de-medida"
                v-if="onCanShow('MeasureUnit')"
              >
                <template slot="title">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                  <span>Unidades de Medida</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/articulos/embalajes"
                v-if="onCanShow('ProductPackingUnit')"
              >
                <template slot="title">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                  <span>Embalajes</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="1-3"
              v-if="
                canShowMultiple(['WasteArea', 'WasteReason'])
              "
            >
              <template slot="title">
                <i class="fa fa-trash" aria-hidden="true"></i>
                <span>Merma</span>
              </template>
              <el-menu-item
                index="/catalogos/merma/areas"
                v-if="onCanShow('WasteArea')"
              >
                <template slot="title">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span>Áreas</span>
                </template>
              </el-menu-item>
              <el-menu-item
                index="/catalogos/merma/motivos"
                v-if="onCanShow('WasteReason')"
              >
                <template slot="title">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span>Motivos</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-submenu
            index="3"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['Suppliers'])"
          >
            <template slot="title">
              <i class="fa fa-industry" aria-hidden="true"></i> Proveedores
            </template>
            <el-menu-item index="/proveedores" v-if="onCanShow('Suppliers')">
              <i class="fa fa-university" aria-hidden="true"></i>
              <span>Empresas</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="4"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['CargoDeliverySchedule'])"
          >
            <template slot="title">
              <i class="fa fa-inbox" aria-hidden="true"></i> Paquetería
            </template>
            <el-menu-item
              index="/paqueteria/recepcion-de-paquetes"
              v-if="onCanShow('CargoDeliverySchedule')"
            >
              <i class="fa fa-envelope-open" aria-hidden="true"></i>
              <span>Recepción de Paquetes</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="5"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['Staff'])"
          >
            <template slot="title">
              <i class="fa fa-building-o" aria-hidden="true"></i> Empresa
            </template>
            <el-menu-item index="/empresa/personal" v-if="onCanShow('Staff')">
              <template slot="title">
                <i class="fa fa-address-card" aria-hidden="true"></i>
                <span>Personal</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="6"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['Users', 'Roles', 'Permissions'])"
          >
            <template slot="title">
              <i class="fa fa-shield" aria-hidden="true"></i> Autorización
            </template>
            <el-menu-item
              index="/autorizacion/usuarios"
              v-if="onCanShow('Users')"
            >
              <template slot="title">
                <i class="fa fa-user" aria-hidden="true"></i> Usuarios
              </template>
            </el-menu-item>
            <el-menu-item index="/autorizacion/roles" v-if="onCanShow('Roles')">
              <template slot="title">
                <i class="fa fa-users" aria-hidden="true"></i> Roles
              </template>
            </el-menu-item>
            <el-menu-item
              index="/autorizacion/permisos"
              v-if="onCanShow('Permissions')"
            >
              <template slot="title">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i> Permisos
              </template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="/configuraciones-del-sistema"
            aria-haspopup="true"
            class="sub-icon"
            v-if="canShowMultiple(['SystemSettings'])"
          >
            <template slot="title">
              <i class="fa fa-cog" aria-hidden="true"></i> Configuraciones del
              Sistema
            </template>
          </el-menu-item>
        </el-menu>
      </nav>
      <!--Nav-->
    </div>
  </div>
</template>
