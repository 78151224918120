import BaseModule from '../../infraestructure/BaseModule'
import ProductRackLocationsViewModel from '../../../core/features/productRackLocations/ProductRackLocationsViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: ProductRackLocationsViewModel.activeStates,
            filtersSelected: {
                code: '',
                rack: '',
                isActive: null,
            },
            racks: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new ProductRackLocationsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-product-rack-locations', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onRackChange(rackId) {
            (rackId) ? this.addFilter('ProductRackId', rackId) : this.removeFilter('ProductRackId')
            this.onFilter();
        },
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}