import BaseViewModel from '../../infraestructure/BaseViewModel'
import CargoReception from './CargoReception'
import User from '../users/User'
import FileHelper from '../../helpers/FileHelper'

export default class CargoReceptionsViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    //#region  INICIALIZADORES
    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]
    //#endregion

    //#region CRUD

    create(data) {
        return new CargoReception(data, 'create')
    }

    edit(data) {
        return new CargoReception(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.cargoReceptions.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)

        } else {
            this.api.cargoReceptions.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.cargoReceptions.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.cargoReceptions.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.cargoReceptions.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    import(file) {
        this.api.cargoReceptions.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            folio: '',
            receptionDate: '',
            responsible: '',
            supplier: '',
            isActive: null,
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('FolNumber');
        this.view.removeFilter('ResponsibleId');
        this.view.removeFilter('SupplierId');
        this.view.removeFilter('StartDate');
        this.view.removeFilter('EndDate');
        this.view.removeFilter('IsActive');
        this.view.onFilter();
    }
    //#endregion

    // #region BINDING DATA
    bindForm(formData) {
        if (formData.state === 'update') {
            this.view.suppliers = (formData.supplier) ? [formData.supplier] : null
            this.view.users = (formData.responsible) ? this.mapUsers([formData.responsible]) : null
            this.bindUsersNotifications(formData.configurationNotifications)
        }
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
        }, 400);
    }

    bindList() {
        this.api.staff.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.staffs = response.data.data
                return this.api.suppliers.find(`Skip=0&Limit=1000000&IsActive=true`)
            })
            .then((response) => {
                this.view.suppliers = response.data.data
            })
            .catch(this.view.onError);
    }

    bindUsersNotifications(collection) {
        return collection.map((item) => {
            item.users = this.mapUsers([item.applicationUser])
            item.applicationUser = new User(item.applicationUser)
        })
    }

    //#endregion

    // #region QUERIES

    findSuppliers(query) {
        return this.api.suppliers.find(query)
            .then((response) => this.view.onFindSuppliersResponse(response.data))
            .catch(this.view.onError)
    }

    findUsers(query) {
        return this.api.users.find(query)
            .then((response) => this.view.onFindUsersResponse(response.data))
            .catch(this.view.onError)
    }

    findResponsible(query) {
        return this.api.staff.find(query)
            .then((response) => this.view.onFindResponsiblesResponse(response.data))
            .catch(this.view.onError)
    }

    // #endregion

    //#region MAPPING DATA

    mapCollection(collection) {
        return collection.map((item) => new CargoReception(item))
    }

    mapUsers(collection) {
        return collection.map((item) => new User(item))
    }

    // #endregion

    // #region VALIDATIONS

    // #endregion

    // #region HELP
    getGuid() {
        return FileHelper.generateGuid()
    }

    getFileExtension(fileName) {
        return FileHelper.getExtension(fileName)
    }

    convertFileToBase64(file) {
        return FileHelper.toBase64(file);
    }
    //#endregion
}