import BaseModule from '../../infraestructure/BaseModule'
import CargoReceptionsViewModel from '../../../core/features/cargoReceptions/CargoReceptionsViewModel'
import _ from 'lodash';

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: CargoReceptionsViewModel.activeStates,
            filtersSelected: {
                folio: '',
                receptionDate: '',
                responsible: '',
                supplier: '',
                isActive: null,
            },
            staffs: [],
            suppliers: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new CargoReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-cargo-receptions', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByFolio: _.debounce(function (text) {
            (text) ? this.addFilter('FolNumber', text) : this.removeFilter('FolNumber')
            this.onFilter();
        }, 500),
        onSearchByReceptionDate: _.debounce(function (text) {
            if (text) {
                this.addFilter('StartDate', text[0])
                this.addFilter('EndDate', text[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onFilter();
        }, 500),
        onResponsibleChange(responsibleId) {
            (responsibleId) ? this.addFilter('ResponsibleId', responsibleId) : this.removeFilter('ResponsibleId')
            this.onFilter();
        },
        onSupplierChange(supplierId) {
            (supplierId) ? this.addFilter('SupplierId', supplierId) : this.removeFilter('SupplierId')
            this.onFilter();
        },
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}