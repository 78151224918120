<script>
import ModuleWhiteLabelSettingComponent from "../features/ModuleWhiteLabelSettingComponent";

export default {
  components: {},
  extends: ModuleWhiteLabelSettingComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-cog" aria-hidden="true"></i> Configuraciones del Sistema
        </h4>
      </div>
      <div class="page-centerheader"></div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
      </div>
    </div>
    <!--End Page header-->
    <div class="wrapper-list">
      <el-form
        ref="formDataWhiteLabelSetting"
        :model="whiteLabelSetting"
        :rules="rulesWhiteLabelSettings"
        v-if="whiteLabelSetting"
        v-loading="whiteLabelSettingLoading"
      >
        <legend>Empresa</legend>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          style="magin-bottom: 1px solid #ccc"
        >
          <el-col :span="6">
            <el-form-item label="Nombre de la empresa">
              <el-input
                size="medium"
                v-model="whiteLabelSetting.companyName"
                placeholder="Ingresar nombre de la empresa"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Logo">
              <el-input
                size="medium"
                v-model="whiteLabelSetting.companyLogo"
                placeholder="Seleccionar logo de la empresa"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Color base de la plataforma">
              <el-select v-model="whiteLabelSetting.companyColor" placeholder="Seleccionar Color de Tema" size="medium">
                <el-option
                  v-for="item in themeColors"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" align="left">
            <el-button
              type="primary"
              size="medium"
              style="margin-top: 40px; width:100%;"
              icon="el-icon-refresh"
              @click="saveWhiteLabelSetting('formDataWhiteLabelSetting')"
              :disabled="!onCanDo('UpdateCompany')"> Actualizar Información de Empresa</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-loading="bindProductImagesLoading">
        <legend>Productos</legend>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          style="magin-bottom: 1px solid #ccc"
        >
          <el-col :span="6" align="center">
            <el-form-item label="Vincular imagenes con productos">
              <el-button icon="el-icon-refresh" type="primary" size="medium" style="margin-top: 40px; width:100%;"
              @click="migrateProductImages"
              :disabled="!onCanDo('UpdateProductImages')"> Vincular Imagenes de Productos</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
