<script>
import DetailProductSeekerComponent from "../features/DetailProductSeekerComponent";
export default {
  extends: DetailProductSeekerComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <router-link to="/buscador-de-productos">
            <i class="fa fa-search" aria-hidden="true"></i> Buscador de Productos
          </router-link>
          / Detalle
        </h4>
      </div>
    </div>
    <!--End Page header-->
    <div v-if="product" class="container product__seeker__detail">
      <el-row class="row-bg" :gutter="20">
        <el-col
          :xs="24"
          :sm="12"
          align="center"
          class="product__seeker__detail__gallery"
        >
          <div class="product__seeker__detail__selected__img">
            <el-image
            shape="square"
            fit="contain"
            :src="coverProduct"
          />
          </div>
          <el-row
            v-for="rowIdx in Math.ceil(product.productImages.length / 6)"
            :key="rowIdx"
            class="row-bg product__seeker__detail__gallery__row"
            :gutter="20"
            
          >
            <el-col
              v-for="(item, col) in product.productImages.slice(
                6 * (rowIdx - 1),
                6 * rowIdx
              )"
              :key="col"
              :span="4"
              align="center"
            >
              <el-image
                style="
                  width: 100px;
                  height: 80px;
                  border: 4px solid #fff;
                  cursor: pointer;
                "
                fit="cover"
                :src="item.fileUrl"
                @click="onSelectImage(item)"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" class="product__seeker__detail__info">
          <h1>{{ product.name }}</h1>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
            style="margin-bottom: 20px"
          >
            <el-col :span="8"> <strong>SAP SKU:</strong> {{ product.sku }} </el-col>
            <el-col :span="8"> <strong>INT SKU:</strong> {{ product.intSku }} </el-col>
          </el-row>

          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
            style="margin-bottom: 10px"
          >
            <el-col :span="24">
              <el-tabs type="border-card">
                <el-tab-pane label="Detalles">
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Descripción:</h3>
                      <p>{{ product.description }}</p>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Categor&iacute;a:</h3>
                      <p>{{ product.category.name }}</p>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Marca:</h3>
                      <p>
                        {{ product.brand.name }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Empaque:</h3>
                      <p>
                        {{ product.packingType.name }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Unidad de Medida:</h3>
                      <p>
                        {{ product.measureUnit.name }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="24">
                      <h3>Uso:</h3>
                      <p v-if="product.usageTags">
                        <el-tag v-for="(tag, indexTag) in product.usageTags.split(',')" v-bind:key="indexTag">
                          {{ tag }}
                        </el-tag>
                      </p>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="Embalaje">
                  <el-table
                    :data="product.productPackagings"
                    style="width: 100%">
                    <el-table-column
                      prop="productPackingUnit.description"
                      label="Embalaje">
                    </el-table-column>
                    <el-table-column
                      prop="qty"
                      label="Unidades"
                      width="180" align="center">
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>

          <el-row
            class="row-bg"
            :gutter="20"
          >
            <el-col :xs="24" :sm="12" :lg="9"  style="margin-bottom: 10px; margin-top: 10px" align="center">
              <el-input-number v-model="shopping.quantity" :min="1" style="width:100%;"/>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="15"  style="margin-bottom: 10px; margin-top: 10px" align="center">
              <el-button
                v-loading="isLoading"
                type="primary"
                size="medium"
                icon="el-icon-shopping-cart-1"
                @click="onAddToRequest"
                style="width:100%;"
              >
                AGREGAR
              </el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
