import BaseForm from '../../infraestructure/BaseForm'
import AuthorizationViewModel from '../../../core/features/authorization/AuthorizationViewModel';

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            formData: {
                password: '',
                passwordConfirmation: ''
            },
            isLoading: false,
            rules: {
                password: [
                    { required: true, message: 'Contraseña requerida.', trigger: 'blur' },
                ],
                passwordConfirmation: [ 
                    { required: true, message: 'Confirmar contraseña requerida.', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    created() {
        this.viewModel = new AuthorizationViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.formData.token = encodeURIComponent(this.$route.query.token)
            this.formData.email = this.$route.query.email
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.isLoading = true
                    this.viewModel.doRestorePassword(this.formData)
                    return true
                }
                return false
            })
        },
        onRestorePasswordResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.notifySuccess(response.message)
            }, 400);
            setTimeout(() => {
                this.$router.push({ name: "LoginView" });
            }, 800);
        },
        onRestorePasswordError(error) {
            setTimeout(() => {
                this.isLoading = false
                this.onError(error)
            }, 400);
        }
    }
}