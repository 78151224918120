import BaseComponent from '../../infraestructure/BaseComponent'

export default {
    extends: BaseComponent,
    data() {
        return {
            whiteLabelSettings: null,
        };
    },
    created() {
        this.onActive()
    },
    mounted() {},
    methods: {
        onActive() {
            this.whiteLabelSettings = this.getWhiteLabelSettings()
        }
    }
}