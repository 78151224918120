import BaseApi from "../../infraestructure/BaseApi";

export default class ProductWasteApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    exportPdf(id) {
        return this.client.get(`${this.endPoint}/export-pdf/${id}`);
    }

    exportToExcel(id) {
        return this.client.get(`${this.endPoint}/export-excel/${id}`);
    }
}