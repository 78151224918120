import BaseViewModel from "../../infraestructure/BaseViewModel";
import ProductRack from "./ProductRack";

export default class ProductRacksViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new ProductRack(data, "create");
    }

    edit(data) {
        return new ProductRack(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.productRacks
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.productRacks
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.productRacks
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.productRacks
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.productRacks
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.productRacks.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.productRacks.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.onFilter();
    }

    bindForm(formData) {
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
            if(formData.state == 'update') {
                this.view.productRacks = (formData.parent) ? [formData.parent] : []
            }
        }, 400);
    }

    bindList() {}

    printProductRackQrToPdf(criteria) {
        //this.view.showLoading(false, '');
        this.api.productRacks.printProductRackQrToPdf(criteria)
            .then((response) => this.view.onProductRackQrToPdfResponse(response.data))
            .catch(this.view.onError);
    }

    batchProductRacksQr(data) {
        this.api.productRacks.batchProductRacksQrToPdf({ racks: data })
        .then((response) => this.view.onProductRackBatchResponse(response.data))
        .catch(this.view.onProductRackQrToPdfError)
    }

    getRequestProductRacksQrsChunks(products, chunkSize = 100) {
        return products.map((e, i) => { 
            return i % chunkSize === 0 ? products.slice(i, i + chunkSize) : null; 
       }).filter(e => { return e; })
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new ProductRack(item));
    }

    //#endregion

    findProductRacks(query){
        this.api.productRacks
            .find(query)
            .then((response) => this.view.onFinRackResponse(response.data))
            .catch(this.view.onError);
    }

}