import BaseForm from "../../infraestructure/BaseForm";
import ProductRackLocationsViewModel from "../../../core/features/productRackLocations/ProductRackLocationsViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: "ProductInRack",
            viewModel: null,
            products: [],
            productCategories: [],
            racks: [],
            productIndex: null,
            isTableLoading: false,
            rules: {
                code: [
                    {
                        required: true,
                        message: "Código(s) requerido.",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "Nombre requerido.",
                        trigger: "blur",
                    },
                ],
                rack: [
                    {
                        required: true,
                        message: "Rack requerido.",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
        formData: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.viewModel = new ProductRackLocationsViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                    return true;
                }
                return false;
            });
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message);
            this.onNotifyEvent("on-submited-product-rack-locations", null);
        },
        onAddProduct(rack) {
            if (rack) {
                this.isTableLoading = true;
                setTimeout(() => {
                    this.formData.addProduct({
                        elements: [],
                    });
                    this.$forceUpdate();
                    this.isTableLoading = false;
                }, 400);
            } else {
                this.notifyWarning("Seleccione un rack.");
            }
        },
        onAddProductByCategories(productCategories) {
            if (productCategories.length > 0) {
                this.isTableLoading = true;
                this.viewModel.findProductByCategories(`ProductCategories=${this.viewModel.mapProductCategories(productCategories)}`);
            } else {
                this.notifyWarning("Seleccione una categoría.");
            }
        },
        onFindProductByCategoriesResponse(response) {
            setTimeout(() => {
                let responseProducts = this.viewModel.filterDuplicateProducts(this.formData.products, response.data);
                responseProducts.forEach(product => {
                    this.formData.addProduct({
                        elements: [product],
                        product: product,
                    });
                });

                this.$forceUpdate();
                this.isTableLoading = false;
            }, 400);
        },
        onProductFocus(index) {
            this.productIndex = index;
        },
        onFindCategories(query) {
            this.productCategories = [];
            if (query) this.viewModel.findCategories(`Name=${query}&IsActive=true`);
        },
        onFindCategoriesResponse(response) {
            this.productCategories = response.data;
        },
        onFindProducts(query) {
            this.formData.products[this.productIndex].elements = [];
            if (query) this.viewModel.findProducts(`Name=${query}&IsActive=true`);
        },
        onFindProductsResponse(response) {
            this.$set(
                this.formData.products[this.productIndex],
                "elements",
                response.data
            );
            this.$forceUpdate();
        },
        onFindRacks(query) {
            this.racks = [];
            if (query) this.viewModel.findRacks(`Name=${query}&IsActive=true`);
        },
        onFindRacksResponse(response) {
            this.racks = response.data;
        },
        onChangeRack() {
            this.formData.products = [];
        },
        onChangeOption(item) {
            if (item.command === "delete") {
                this.$confirm("¿Desea eliminar el producto seleccionado?", "Eliminar", {
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    type: "warning",
                })
                    .then(() => {
                        this.isTableLoading = true;
                        setTimeout(() => {
                            this.formData.remove(item.index);
                            this.isTableLoading = false;
                        }, 400);
                    })
                    .catch(() => { });
            }
        },
    },
};
