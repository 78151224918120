export default {
    API: '/api',
    AUTH: '/auth',
    USERS: '/users',
    ROLES: '/roles',
    PERMISSIONS: '/permissions',
    SECURITY: '/security',
    SUPPLIERS: '/suppliers',
    USER_IDENTIFICATIONS: '/user-identifications',
    STAFF: '/staff',
    CARGO_RECEPTIONS: '/cargo-receptions',
    PRODUCT_BRANDS: '/brands',
    PRODUCT_PACKING_TYPES: '/product-packing-types',
    PRODUCT_PACKING_UNITS: '/product-packing-units',
    PRODUCT_CATEGORIES: '/product-categories',
    MEASURE_UNITS: '/product-measure-units',
    PRODUCTS: '/products',
    PRODUCT_RACKS: '/product-racks',
    PRODUCT_RACK_LOCATIONS: '/product-rack-locations',
    PRODUCT_RECEPTIONS: '/product-receptions',
    PRODUCT_DELIVERIES: '/product-deliveries',
    PRODUCT_REQUESTS: '/product-requests',
    PRODUCT_SEEKER: '/product-seeker',
    PRODUCT_INVENTORIES: '/product-inventories',
    PRODUCT_DEVOLUTIONS: '/product-devolutions',
    PRODUCT_WASTES: '/product-wastes',
    SHOPPING_CARTS: '/shopping-carts',
    SETTINGS: '/settings',
    DASHBOARD: '/dashboard',
    WASTE_AREAS: '/waste-areas',
    WASTE_REASONS: '/waste-reasons',
    WHITE_LABEL_SETTINGS: '/white-labels-settings',
    TOOLS: '/tools',
    WAREHOUSES: '/warehouses'
}