import BaseApi from "../../infraestructure/BaseApi";

export default class SettingsApi extends BaseApi {
  
    constructor(client, endPoint) {
    super(client, endPoint);
  }

  findProductRequestStatuses() {
    return this.client.get(`${this.endPoint}/product-request-statuses`)
  }

  findProductInventoryStatuses() {
    return this.client.get(`${this.endPoint}/product-inventory-statuses`)
  }

}