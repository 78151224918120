import _ from 'lodash';
import BaseViewModel from "../../infraestructure/BaseViewModel";
import ProductRackLocation from "./ProductRackLocation";
import Product from '../products/Product';

export default class ProductRackLocationsViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new ProductRackLocation(data, "create");
    }

    edit(data) {
        return new ProductRackLocation(data, "update", "products", data.products);
    }

    save(data) {
        if (data.state === "create") {
            this.api.productRackLocations
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.productRackLocations
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.productRackLocations
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.productRackLocations
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.productRackLocations
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.productRackLocations.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.productRackLocations.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
        };

        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.onFilter();
    }

    findRacks(query) {
        this.api.productRacks.find(query)
            .then((response) => this.view.onFindRacksResponse(response.data))
            .catch(this.view.onError);
    }

    findProducts(query) {
        this.api.products.find(query)
            .then((response) => this.view.onFindProductsResponse(response.data))
            .catch(this.view.onError);
    }

    findProductByCategories(query) {
        this.api.products.findByCategories(query)
            .then((response) => this.view.onFindProductByCategoriesResponse(response.data))
            .catch(this.view.onError);
    }

    findCategories(query) {
        this.api.productCategories.find(query)
            .then((response) => this.view.onFindCategoriesResponse(response.data))
            .catch(this.view.onError);
    }

    bindFormView(formData) {
        if (formData.state === "update") {
            this.view.racks = (formData.rack) ? [formData.rack] : [];
            formData.products = formData.products.map((item) => {
                item.elements = (item.product) ? [item.product] : [];
                return new Product(item);
            })
        }
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
        }, 400);
    }

    bindList() {
        this.api.productRacks.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.racks = response.data.data
            })
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new ProductRackLocation(item));
    }

    mapProductCategories(data) {
        let collection = _.map(data, 'name');
        return collection;
    }

    filterDuplicateProducts(existenceCollection, newCollection) {
        return _.differenceWith(newCollection, existenceCollection.map(item => item.product), (a, b) => a.id === b.id);
    }
    //#endregion

}