import BaseViewModel from "../../infraestructure/BaseViewModel";
import WhiteLabelSetting from "./WhiteLabelSetting";

export default class WhiteLabelSettingViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static themeColors = [{
        name: "Azul",
        value: "#004FAC"
    },
    {
        name: "Dorado",
        value: "#e9b610"
    },
    {
        name: "Gris",
        value: "#252525"
    }]

    create(data) {
        return new WhiteLabelSetting(data, "create");
    }

    edit(data) {
        return new WhiteLabelSetting(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.whiteLabelSettings
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.whiteLabelSettings
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.whiteLabelSettings
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.whiteLabelSettings
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.whiteLabelSettings
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.whiteLabelSettings.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.whiteLabelSettings.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    
    bindForm() {
        
    }

    bindList() {}

    bindModule() {
        this.api.whiteLabelSettings.findDefault()
            .then((response) => this.view.onFindDefaultResponse(response.data))
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new WhiteLabelSetting(item));
    }

    //#endregion

    migrateProductImages() {
        this.api.tools.migrateProductImages()
            .then((response) => this.view.onMigrateProductImagesResponse(response.data))
            .catch(this.view.onMigrateProductImagesError);
    }

}