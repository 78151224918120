import BaseModel from '../../infraestructure/BaseModel';

export default class ChildrenList extends BaseModel {
    constructor(options, state, childrenName, collection) {
        super(options, state)
        this.childrenName = childrenName
        this[childrenName] = collection; 
    }

    getCollection() {
        return this[this.childrenName].filter(child => child.updateState !== this.UPDATE_STATUS.REMOVED)
    }

    getItem(selectedIndex) { 
        return this[this.childrenName][selectedIndex]
    }

    add(child) {
        child.updateState = this.UPDATE_STATUS.ADDED
        this[this.childrenName].push(child);
    }

    remove(selectedIndex) {
        var child = this.getItem(selectedIndex)
        if(child) {
            var index = this[this.childrenName].indexOf(child)
            if(child.id) {
                let register = this[this.childrenName][index]
                register.updateState = this.UPDATE_STATUS.REMOVED
            } else {
                this[this.childrenName].splice(index, 1)
            }
        } else {
            throw new Error('Child not found')
        }
    }

    update(selectedIndex, data) {
        var child = this.getItem(selectedIndex)
        if(child) {
            var index = this[this.childrenName].indexOf(child)
            let register = this[this.childrenName][index]
            register.updateState = (child.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
            Object.assign(register, data)
        } else {
            throw new Error('Child not found')
        }
    }

    setUpdateState(selectedIndex) {
        var child = this.getItem(selectedIndex)
        if(child) {
            var index = this[this.childrenName].indexOf(child)
            let register = this[this.childrenName][index]
            register.updateState = (child.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
        } else {
            throw new Error('Child not found')
        }
    }

    notify(event, ...args) {
        this.children.forEach(child => child.notify(event, ...args));
    }
}