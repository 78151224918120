import BaseForm from '../../infraestructure/BaseForm'
import SuppliersViewModel from '../../../core/features/suppliers/SuppliersViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Suppliers',
            viewModel: null,
            rules: {
                aliasName: [{
                    required: true,
                    message: 'Nombre/Alias requerido.',
                    trigger: 'blur'
                }],
                legalResponsible: [{
                    required: true,
                    message: 'Representante Legal requerido.',
                    trigger: 'blur'
                }],
                fiscalNumber: [{
                    required: true,
                    message: 'R.F.C. requerido.',
                    trigger: 'blur'
                }],
                fiscalName: [{
                    required: true,
                    message: 'Razón Social requerido.',
                    trigger: 'blur'
                }],
                contactName: [{
                    required: true,
                    message: 'Nombre Contacto requerido.',
                    trigger: 'blur'
                }],
                contactPhone: [{
                    required: true,
                    message: 'Telefono Contacto requerido.',
                    trigger: 'blur'
                }],
                contactEmail: [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SuppliersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-suppliers', null)
        }
    }
}