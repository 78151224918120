<script>
import ModalWasteAreaComponent from "../features/ModalWasteAreaComponent";
export default {
  name: "ModalWasteAreaComponent",
  extends: ModalWasteAreaComponent,
};
</script>

<template>
  <el-dialog
    title="Area de Merma"
    :visible.sync="config.isVisible"
    custom-class="modal-waste-area"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="fa fa-trash" /> Area de Merma
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Area</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="6">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small" />
            </el-form-item>
          </el-col>

          <el-col :span="14">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="4" align="center">
            <el-form-item label="¿Activo?" prop="isActive">
              <el-switch
                v-model="formData.isActive"
                active-color="#13ce66"
                inactive-color="#cccccc"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          style="margin-bottom: 20px"
        >
          <el-col :span="24">
            <el-form-item label="Descripción" prop="description">
              <el-input
                v-model="formData.description"
                size="small"
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label">Motivos de Merma</span>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24" align="right">
                <el-button
                  icon="el-icon-circle-plus"
                  size="small"
                  type="primary"
                  @click="onAddWasteReason"
                  >Agregar Motivo</el-button
                >
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item prop="wasteReasons">
                  <el-table :data="formData.wasteReasons" style="width: 100%" v-loading="isTableLoading">
                    <el-table-column label="Motivo">
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.wasteReason"
                          filterable
                          remote
                          placeholder="Buscar por nombre..."
                          :remote-method="onFindWasteReasons"
                          value-key="id"
                          @focus="onFocusWasteReason(scope.$index)"
                          size="medium"
                          :disabled="!(scope.row.state && (scope.row.state === 'update' || scope.row.state === 'create'))"
                        >
                          <el-option
                            v-for="item in scope.row.wasteReasons"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Opciones"
                      width="110"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-button-group>
                          <el-button
                            icon="el-icon-delete"
                            size="mini"
                            type="danger"
                            @click="onDeleteWasteReason(scope.$index)"
                          ></el-button>
                          <el-button
                            icon="el-icon-edit"
                            size="mini"
                            type="default"
                            @click="onEditWasteReason(scope.$index)"
                            v-if="scope.row.wasteReason && scope.row.wasteReason.id"
                          ></el-button>
                        </el-button-group>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
