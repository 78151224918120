//import BaseComponent from '../infraestructure/BaseComponent'
import DateTimeHelper from '../../../core/helpers/DateTimeHelper'

export default {
    //extends: BaseComponent,
    data() {
        return {
            currentYear: null,
        };
    },
    created() {
        this.onActive()
    },
    mounted() {},
    methods: {
        onActive() {
            this.getCurrentYear()
        },
        getCurrentYear() {
            this.currentYear = DateTimeHelper.getCurrentYear();
        }
    }
}