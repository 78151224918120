export default class WasteReason {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            isActive: this.isActive
        }
    }
}