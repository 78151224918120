import BaseForm from '../../infraestructure/BaseForm'
import ProductViewModel from '../../../core/features/products/ProductsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Products',
            viewModel: null,
            brands: [],
            productCategories: [],
            measureUnits: [],
            productPackingTypes: [],
            productPackingUnits: [],
            usageTags: [],
            warehouses: [],
            usageTagInputVisible: false,
            usageTagInputValue: '',
            tabProductUsageActive: 'product-usage',
            rules: {
                sku: [{
                    required: true,
                    message: 'SKU requerido.',
                    trigger: 'blur'
                }],
                intSku: [{
                    required: true,
                    message: 'SKUInt requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                brand: [{
                    required: true,
                    message: 'Marca requerido.',
                    trigger: 'blur'
                }],
                category: [{
                    required: true,
                    message: 'Categoría requerido.',
                    trigger: 'blur'
                }],
                measureUnit: [{
                    required: true,
                    message: 'Unidad de medida requerido.',
                    trigger: 'blur'
                }],
                packingType: [{
                    required: true,
                    message: 'Tipo de empaque requerido.',
                    trigger: 'blur'
                }],
                warehouse: [{
                    required: true,
                    message: 'Almacén requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ProductViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.formData.setUsageTags(this.usageTags)
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-products', null)
        },
        onFindBrands(data) {
            this.brands = []
            if (data) this.viewModel.findBrands(`Name=${data}&IsActive=true`)
        },
        onFindBrandsResponse(response) {
            this.brands = this.viewModel.mapBrands(response.data)
        },
        onFindProductCategories(data) {
            this.productCategories = []
            if (data) this.viewModel.findProductCategories(`Name=${data}&IsActive=true`)
        },
        onFindProductCategoriesResponse(response) {
            this.productCategories = this.viewModel.mapProductCategories(response.data)
        },
        onFindMeasureUnits(data) {
            this.measureUnits = []
            if (data) this.viewModel.findProductMeasureUnits(`Name=${data}&IsActive=true`)
        },
        onFindMeasureUnitsResponse(response) {
            this.measureUnits = this.viewModel.mapMeasureUnits(response.data)
        },
        onFindProductPackingTypes(data) {
            this.productPackingTypes = []
            if (data) this.viewModel.findProductPackingTypes(`Name=${data}&IsActive=true`)
        },
        onFindProductPackingTypesResponse(response) {
            this.productPackingTypes = this.viewModel.mapProductPackingTypes(response.data)
        },
        onUsageTagsClose(tag) {
            this.usageTags.splice(this.usageTags.indexOf(tag), 1);
        },
        showUsageTagInput() {
            this.usageTagInputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveUsageTagInput.$refs.input.focus();
            });
        },
        onUsageTagConfirm() {
            if (this.usageTagInputValue) {
                this.usageTags.push(this.usageTagInputValue);
            }
            this.usageTagInputVisible = false;
            this.usageTagInputValue = '';
        },
        onAddPackging() {
            this.formData.addProductPackaging()
        },
        onEditPackaging(index) {
            this.formData.editProductPackaging(index)
            this.$forceUpdate()
        },
        onDeletePackaging(index) {
            this.$confirm("¿Desea eliminar el registo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.formData.removeProductPackaging(index)
                    this.$forceUpdate()
                })
                .catch(() => { });
        },
        async onUploadPicture(file) {
            this.formData.pictureFiles.push(file)
            this.formData.addImage({
                fileName: file.uid,
                fileUrl: await this.viewModel.fileToBase64(file),
                fileExtension: this.viewModel.getFileExtension(file.name),
            })
        },
        onRemoveImage(index) {
            this.$confirm("¿Desea eliminar la imagen seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.formData.removeImage(index)
                    this.$forceUpdate()
                })
                .catch(() => { });
            
        },
        mapFileUrl(collection) {
            return collection.map(item => item.fileUrl)
        },
        onChangeCoverImage() {
            this.$forceUpdate()
        }
    }
}