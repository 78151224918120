<script>
import ListProductSeekerComponent from "../features/ListProductSeekerComponent";
import FilterBoxView from "./FilterBoxView";

export default {
  components: {
    FilterBoxView,
  },
  extends: ListProductSeekerComponent,
};
</script>
<template>
  <div class="wrapper-list">
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-input
          v-model="searchText"
          placeholder="Buscar producto..."
          class="search-box"
          size="medium"
          v-on:input="onFilterFullSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onFindProducts"
          />
        </el-input>
      </el-col>
    </el-row>
    <el-row class="row-bg" :gutter="20">
      <el-col :md='6' :lg='6' :xl='4'>
        <filter-box-view />
      </el-col>
      <el-col v-loading="isListLoading" :md='18' :lg='18' :xl='20'>
        <el-row
          v-for="rowIdx in Math.ceil(collection.length / 3)"
          :key="rowIdx"
          class="row-bg"
          :gutter="20"
        >
          <el-col
            v-for="(item, col) in collection.slice(3 * (rowIdx - 1), 3 * rowIdx)"
            :key="col"
            :md='12' :lg='10' :xl='8'
          >
              <el-card
                class="box-card"
                shadow="hover"
              >
                <div slot="header" class="clearfix">
                  <span>SKU: {{ item.sku }} / INT.SKU: {{item.intSku}}</span>
                  <!-- <p v-if="item.warehouse">Almacén: {{ item.warehouse }}</p> -->
                </div>
                <div class="clearfix">
                  <el-row class="row-bg" :gutter="20">
                    <el-col :md='12' :lg='10' :xl='8'>
                      <el-image
                        class="image-cover"
                        fit="scale-down"
                        :src="onMapProductImage(item.coverImageUrl)"
                      />
                    </el-col>
                    <el-col :md='12' :lg='14' :xl='16' class="details">
                      <el-row class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <span class="product-name">{{ item.name }}</span>
                        </el-col>
                        <el-col :span="24">
                          <span>Categoria: {{ item.category }}</span>
                        </el-col>
                        <el-col :span="24">
                          <span>Unidad de Medida: {{ item.measureUnit }}</span>
                        </el-col>
                        <el-col :span="24">
                          <span>Marca: {{ item.brand }}</span>
                        </el-col>
                        <el-col :span="24">
                          <span>Existencias: {{ item.totalStock }}</span>
                        </el-col>
                        <el-col :span="24" v-if="item.warehouse">
                          <span>Almacén: {{ item.warehouse }}</span>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
                <div class="bottom clearfix card-footer" align="right">
                  <el-button-group>
                    <el-button type="default" size="small" @click="onShowProductDetails(item)">
                      <i class="fa fa-hand-pointer-o" aria-hidden="true" /> Ver Detalles
                    </el-button>
                    <el-button type="primary" size="small" @click="onAddToOrder(item)" class="btn-add-to-order">
                      <i class="fa fa-shopping-cart" aria-hidden="true" /> Agregar a Orden
                    </el-button>
                  </el-button-group>
                </div>
              </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="8" align="left">
        <p class="mb-sm-0">
          Mostrar 1 a {{ pagination.limit }} de
          {{ pagination.totalItems }} registros
        </p>
      </el-col>
      <el-col :span="16" align="right">
        <el-pagination
          :page-count="pagination.totalPages"
          layout="sizes, prev, pager, next"
          @current-change="onPageChange"
          @size-change="onPageSizeChange"
          :page-sizes="pageSizes"
          :page-size="pagination.limit"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
