import BaseApi from "../../infraestructure/BaseApi";

export default class ProductDeliveryApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    findPendingProductRequests(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/pending-product-requests${parameters}`);
    }

    findTotalMovements(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/total-movements${parameters}`);
    }

    findTotalUsersActive(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/total-users-active${parameters}`);
    }

    findTopLowInventory(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/top-low-inventory${parameters}`);
    }
}