import _ from 'lodash';
import BaseViewModel from "../../infraestructure/BaseViewModel";
import ProductInventory from "./ProductInventory";
// import ProductInventoryTrace from "./ProductInventoryTrace";
import User from "../users/User";

export default class ProductInventoryViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new ProductInventory(data, "create");
    }

    edit(data) {
        var item = new ProductInventory(data, "update", "traces", data.traces);
        item.setUpdateTraces()
        return item
    }

    save(data) {
        if (data.state === "create") {
            this.api.productInventories
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.productInventories
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.productInventories
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.productInventories
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.productInventories
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.productInventories.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    exportPdf(id, type) {
        switch (type) {
            case 'report':
                this.api.productInventories
                    .exportPdf(id)
                    .then((response) => this.view.onExportPdfResponse(response.data))
                    .catch(this.view.onError);
                break;
            
            case 'price':
                this.api.productInventories
                    .exportPricePdf(id)
                    .then((response) => this.view.onExportPdfResponse(response.data))
                    .catch(this.view.onError);
                break;

            default:
                break;
        }
    }

    exportExcel(id) {
        this.api.productInventories.exportToExcel(id)
            .then(response => {
                this.view.onExportResponse(response.data)
            })
            .catch(this.view.onError)
    }

    exportPricePdf(id) {
        this.api.productInventories.exportToPriceExcel(id)
            .then(response => {
                this.view.onExportResponse(response.data)
            })
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            supplier: '',
            receptionDate: '',
            isActive: null,
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('SupplierId');
        this.view.removeFilter('StartDate');
        this.view.removeFilter('EndDate');
        this.view.removeFilter('IsActive');
        this.view.onFilter();
    }

    bindFormView(formData) {
        this.api.settings.findProductInventoryStatuses()
            .then((response) => {
                this.view.reportStatuses = response.data.data;
                if(formData.state === 'update') {
                    this.view.responsibles = (formData.responsible) ? [new User(formData.responsible)] : [];
                    this.view.operators = (formData.operator) ? [new User(formData.operator)] : [];
                    formData.bindProductTraces()
                    formData.traces.forEach((trace) => {
                        trace.products = [trace.product]
                        trace.racks = trace.product.racks.map((item) => item.name).join(", ")
                    })
                }
                setTimeout(() => {
                    this.view.onComponentLoading(this.view.config, false, '')
                }, 400);
            })
            .catch(this.view.onError);
    }

    bindList() {
        this.api.staff.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.responsibles = this.mapUsers(response.data.data)
                this.view.operators = this.mapUsers(response.data.data)
            })
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new ProductInventory(item));
    }

    mapUsers(collection) {
        return collection.map((item) => new User(item));
    }

    mapProductRacks(data) {
        let collection = _.map(data, 'id');
        return collection;
    }

    filterDuplicateProducts(existenceCollection, newCollection) {
        return _.differenceWith(newCollection, existenceCollection.map(item => item.product), (a, b) => a.id === b.id);
    }

    //#endregion

    findRacks(query) {
        this.api.productRacks.find(query)
            .then((response) => this.view.onFindRacksResponse(response.data))
            .catch(this.view.onError);
    }

    findAllRacksWithProducts(query) {
        this.api.productRacks.findAllRacksWithProducts(query)
            .then((response) => this.view.onFindAllRacksWithProductsResponse(response.data))
            .catch(this.view.onError);
    }

    findProductByRacks(query) {
        this.api.products.findByRacks(query)
            .then((response) => this.view.onFindProductByRacksResponse(response.data))
            .catch(this.view.onError);
    }

    findProducts(query) {
        this.api.products
            .find(query)
            .then((response) => this.view.onFindProductsResponse(response.data))
            .catch(this.view.onError);
    }

    findProduct(id) {
        this.api.products
            .findOne(id)
            .then((response) => this.view.onProductChangeResponse(response.data))
            .catch(this.view.onError);
    }

    findResponsibles(query) {
        this.api.staff
            .find(query)
            .then((response) => this.view.onFindResponsiblesResponse(response.data))
            .catch(this.view.onError);
    }

    findOperators(query) {
        this.api.staff
            .find(query)
            .then((response) => this.view.onFindOperatorsResponse(response.data))
            .catch(this.view.onError);
    }

    loadTheoretical(file) {
        this.api.productInventories.loadTheoretical(file)
            .then((response) => this.view.onLoadTheoreticalResponse(response.data))
            .catch(this.view.onLoadTheoreticalError);
    }
    
    removeSelectedProducts(products, selectedProducts) {
        return _.differenceWith(products, selectedProducts, _.isEqual);
    }
}