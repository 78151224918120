export default class Supplier {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            aliasName: this.aliasName,
            legalResponsible: this.legalResponsible,
            fiscalNumber: this.fiscalNumber,
            fiscalName: this.fiscalName,
            contactName: this.contactName,
            contactPhone: this.contactPhone,
            contactEmail: this.contactEmail,
            isActive: this.isActive
        }
    }
}