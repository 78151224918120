import _ from 'lodash';
import BaseList from "../../infraestructure/BaseList";
import ProductSeekerViewModel from "../../../core/features/productSeeker/ProductSeekerViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      filters: [],
      isProductList: false,
      searchText: "",
      limit: 50,
      pageSizes: [50, 100, 150, 200],
    };
  },
  mounted() {
    this.onMountedEvent("on-filters-product-seeker", this.onFilter);
    this.onMountedEvent("on-submited-product-seeker", this.onSearch);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-filters-product-seeker", this.onFilter);
    this.onBeforeDestroyEvent("on-submited-product-seeker", this.onSearch);
  },
  created() {
    this.viewModel = new ProductSeekerViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onFindProducts();
      this.onSearch();
    },
    onFindProducts() {
      this.onFilterFullSearch = _.debounce(() =>{
        this.removeFilter('FullSearch')
        if (this.searchText) { 
          this.addFilter('FullSearch', this.searchText) 
          this.currentPage = 1;
        }
        this.onSearch();
      }, 400)
    },
    onFilter(filters) {
      this.removeFilter('categories')
      this.removeFilter('brands')
      this.removeFilter('measureUnits')
      this.removeFilter('warehouses')

      if(!_.isEmpty(filters.categories)) {
        this.addFilter('categories', filters.categories.join(","))
      }
      
      if(!_.isEmpty(filters.brands)) {
        this.addFilter('brands', filters.brands.join(","))
      }

      if(!_.isEmpty(filters.measureUnits)) {
        this.addFilter('measureUnits', filters.measureUnits.join(","))
      }

      if(!_.isEmpty(filters.warehouses)) {
        this.addFilter('warehouses', filters.warehouses.join(","))
      }
      
      this.onSearch()
    },
    onMapProductImage(fileUrl) {
      return (fileUrl) ? fileUrl : '/assets/logo_default_product_stock.png'
    },
    onShowProductDetails(item){
      this.$router.push(`/buscador-de-productos/producto/${item.id}`)
    },
    onAddToOrder(item){
      this.isProductList = true
      this.viewModel.addToRequest({
        productId: item.id,
        quantity: 1
      })
    },
    onAddToRequestResponse() {
      this.onNotifyEvent('on-update-shopping-cart', null)
      this.isProductList = false
    },
    onAddToRequestError(error) {
        this.isProductList = false
        this.onError(error)
    }
  },
  computed: {
    nrows() {
      return Math.floor((this.collection.length - 1) / 3) + 1;
    },
  },
};
