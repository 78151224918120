import BaseApi from "../../infraestructure/BaseApi";

export default class WasteAreaApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    /* findAllRacksWithProducts(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/with-products${parameters}`)
    } */
}