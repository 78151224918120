<script>
import ModalImportComponent from "../features/ModalImportComponent";

export default {
  name: "ModalImportView",
  extends: ModalImportComponent,
};
</script>
<template>
  <el-dialog
    custom-class="modal-import"
    :visible.sync="conf.isVisible"
    width="25%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="el-icon-upload" /> {{ conf.title }}
    </span>
    <el-form
      ref="formData"
      v-loading="conf.isLoading"
      element-loading-text="Cargando registros..."
      element-loading-spinner="el-icon-loading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <el-row>
          <el-col
            :span="24"
            align="center"
          >
            <legend>Seleccionar Archivo</legend>
            <el-upload
              class="upload-demo"
              action=""
              :before-upload="onUpload"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >
                Click para subir archivo
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                Solo archivos en formato Excel
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
    </span>
  </el-dialog>
</template>
