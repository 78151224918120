<script>
import _ from "lodash";
import { EventBus } from "../../core/helpers/EventsHelper";
import PermissionHelper from "../../core/helpers/PermissionHelper";
import SecurityStorageHelper from "../../core/helpers/SecurityStorageHelper";
import WhiteLabelSettingsHelper from "../../core/helpers/WhiteLabelSettingsHelper";

export default {
  methods: {
    onError(error) {
      this.isLoading = false;
      if (error.response) {
        switch (error.response.status) {
          case 400:
          case 404:
          case 403:
          case 409:
            // Mensaje Warning de notificación
            if (error.response.data.message)
              this.notifyWarning(error.response.data.message);
            if (error.response.data.errors) {
              var messages = _.flattenDeep(
                _.toArray(_.toArray(error.response.data.errors)[0])
              );
              messages.forEach((message) => {
                setTimeout(() => {
                  this.notifyWarning(message);
                }, 150);
              });
            }
            break;
          case 401:
            this.removeToken();
            this.removeSession();
            setTimeout(() => {
              this.$router.go({ name: "LoginView" });
            }, 400);
            break;
          case 500:
            if (error.response.data.message)
              this.notifyError(error.response.data.message);
            else if (error.response.statusText)
              this.notifyError(error.response.statusText);
            break;
          default:
            break;
        }
      }
    },
    isAuthenticated() {
      return SecurityStorageHelper.isAuthenticated();
    },
    setToken(token) {
      SecurityStorageHelper.setToken(token);
    },
    removeToken() {
      SecurityStorageHelper.removeToken();
    },
    getPermissions(moduleName) {
      return moduleName;
    },
    setSession(data) {
      SecurityStorageHelper.setSession(data);
    },
    getSession() {
      return SecurityStorageHelper.getSession();
    },
    removeSession() {
      SecurityStorageHelper.removeSession();
    },
    setWhiteLabelSettings(data){
      WhiteLabelSettingsHelper.setSettings(data);
    },
    getWhiteLabelSettings(){
      return WhiteLabelSettingsHelper.getSettings();
    },
    setProfilePermissions(data) {
      const permissions = _.map(
        data.permissions,
        (permission) => permission.code
      );
      PermissionHelper.setPermissions(permissions);
    },
    onMountedEvent(featureName, actionMethod) {
      EventBus.$on(featureName, actionMethod);
    },
    onBeforeDestroyEvent(featureName, actionMethod) {
      EventBus.$off(featureName, actionMethod);
    },
    onNotifyEvent(featureName, data) {
      EventBus.$emit(featureName, data);
    },
    onDoImportFile(featureName, file) {
      EventBus.$emit(featureName, file);
    },
    onDoCancel(featureName, data) {
      EventBus.$emit(featureName, data);
    },
    notifySuccess(message) {
      this.$notify({
        title: "CONFIRMACIÓN",
        message: message,
        type: "success",
      });
    },
    notifyWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: message,
        type: "warning",
      });
    },
    notifyError(message) {
      this.$notify({
        title: "ERROR",
        message: message,
        type: "error",
      });
    },
    onComponentLoading(component, loading, message) {
      component.isLoading = loading;
      component.isLoadingText = message;
    },
    onComponentVisible(component, visible) {
      component.isVisible = visible;
    },
    onCanSaveOrUpdate() {
      return this.onCanCreate() || this.onCanUpdate();
    },
    onCanShow(moduleName) {
      return PermissionHelper.canShow(
        moduleName,
        PermissionHelper.getPermissions()
      );
    },
    canShowMultiple(modules) {
      return PermissionHelper.canShowMultiple(
        modules,
        PermissionHelper.getPermissions()
      );
    },
    onCanCreate() {
      return PermissionHelper.canCreate(
        this.modulePermissions,
        PermissionHelper.getPermissions()
      );
    },
    onCanRead() {
      return PermissionHelper.canRead(
        this.modulePermissions,
        PermissionHelper.getPermissions()
      );
    },
    onCanUpdate() {
      return PermissionHelper.canUpdate(
        this.modulePermissions,
        PermissionHelper.getPermissions()
      );
    },
    onCanDelete() {
      return PermissionHelper.canDelete(
        this.modulePermissions,
        PermissionHelper.getPermissions()
      );
    },
    onCanDo(permissionName) {
      return PermissionHelper.canDo(this.modulePermissions, permissionName, PermissionHelper.getPermissions())
    }
  },
};
</script>
