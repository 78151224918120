import PDFMerger from 'pdf-merger-js/browser';
import ApiContext from './ApiContext'
import DateTimeHelper from '../helpers/DateTimeHelper'
import _ from 'lodash'

export default class BaseViewModel {
  constructor(view) {
    this.view = view
    this.api = ApiContext
  }

  getQueryParameters(filters) {
    return filters.map((item) => `${item.filterName}=${item.filterValue}`).join('&')
  }

  mapPdfFile(pdfObject, fileName) {
    const url = window.URL.createObjectURL(new Blob([pdfObject]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  downloadLinkFile(link) {
    var a = document.createElement('A');
    var filePath = link;
    a.href = filePath;
    a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  addDownloadEvent(base64FileString, fileName, type, onFinish) {
    let blobFile = this.dataURItoBlob(base64FileString, type)
    const url = window.URL.createObjectURL(blobFile);
    const link = document.createElement('a');
    link.href = url;
    switch (type) {
      case 'application/pdf':
        link.setAttribute('download', `${fileName}.pdf`); //or any other extension
        break;
      case 'application/vnd.ms-excel':
        link.setAttribute('download', `${fileName}`); //or any other extension
        break;
    }
    document.body.appendChild(link);
    link.click();
    onFinish();
  }

  dataURItoBlob(dataURI, type) {
    const byteArray = new Uint8Array(window.atob(dataURI).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], { type });
  }

  async mergePdfFiles(base64Files, mergedFileName, onFinish) {
    (async() => {
      var merger = new PDFMerger();
      let blobFiles = _.sortBy(base64Files.map((baseFile) => this.dataURItoBlob(baseFile, 'application/pdf')), ['size'], ['desc'])
      for(const blobFile of blobFiles) {
        await merger.add(blobFile)
      }
      await merger.save(mergedFileName)
      onFinish()
    })()
  }

  /**
   * Metodo para verificar que la fecha que tiene modifiedAt no sea la inicial .
   * @param {*} date 
   */
   checkModifiedAt(date) {
    return (!_.isUndefined(date) && _.isNil(date)) ? false : DateTimeHelper.checkWithInitDate(date);
  }
}
