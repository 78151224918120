<script>
import ModalCargoReceptionComponent from "../features/ModalCargoReceptionComponent";
export default {
  name: "ModalCargoReceptionView",
  extends: ModalCargoReceptionComponent,
};
</script>

<template>
  <el-dialog
    title="Recepción de Paqueteria"
    :visible.sync="config.isVisible"
    custom-class="modal-cargo-reception"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
  <span
      slot="title"
      class="dialog-title"
    >
      <i class="fa fa-envelope-open" /> Recepción de Paqueteria
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"
            ><i class="el-icon-date" /> Información General</span
          >
          <fieldset>
            <legend>Información de Paquetería</legend>
            <el-row type="flex" class="row-bg" :gutter="20" />
            <el-row class="row-bg" :gutter="20">
              <el-col :md='7' :lg='7' :xl='7'>
                <el-form-item label="Folio" prop="consecutiveNumber">
                  <el-input
                    v-model="formData.consecutiveNumber"
                    size="small"
                    :disabled="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :md='7' :lg='7' :xl='7'>
                <el-form-item label="Fecha de Recepción" prop="receptionDate">
                  <el-date-picker
                    v-model="formData.receptionDate"
                    type="date"
                    size="small"
                    clearable
                    placeholder="Seleccionar Fecha"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </el-col>
              <el-col :md='4' :lg='4' :xl='4' align="center">
                <el-form-item label="¿Activo?" prop="isActive">
                  <el-switch
                    style="display: block"
                    v-model="formData.isActive"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Si"
                    inactive-text="No">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col v-loading="isInvoiceFileLoading" :md='6' :lg='6' :xl='6' align="center">
                <el-form-item label="Archivo de Factura" prop="invoiceFile">
                  <el-tooltip v-if="!formData.invoiceFileUrl" placement="top">
                    <div slot="content">
                      Solo archivos pdf con un<br />tamaño menor a 2MB.
                    </div>
                    <el-upload
                      ref="uploadInvoice"
                      class="upload-demo"
                      action=""
                      :show-file-list="false"
                      :on-change="onUploadInvoiceChange"
                      :auto-upload="false"
                      :multiple="false"
                    >
                      <el-button slot="trigger" size="small" type="primary">
                        Selecciona un archivo
                      </el-button>
                    </el-upload>
                  </el-tooltip>
                  <div v-else>
                    <el-button-group>
                      <el-button
                        v-if="formData.invoiceFileUrl"
                        type="button"
                        size="small"
                        @click="onDownloadInvoiceFile(formData)"
                      >
                        <i class="el-icon-download" />
                      </el-button>
                      <el-button
                        type="button"
                        size="small"
                        :disabled="!formData.invoiceFileUrl"
                        @click="onRemoveInvoiceFile(formData)"
                      >
                        <i class="el-icon-delete" />
                      </el-button>
                    </el-button-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :md='24' :lg='12' :xl='12'>
                <el-form-item label="Paquetería" prop="supplier">
                  <el-select
                    v-model="formData.supplier"
                    placeholder="Busar y seleccionar..."
                    size="small"
                    value-key="id"
                    remote
                    :remote-method="onFindSuppliers"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in suppliers"
                      :key="item.id"
                      :label="item.aliasName"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md='24' :lg='12' :xl='12'>
                <el-form-item label="Responsable" prop="responsible">
                  <el-select
                    v-model="formData.responsible"
                    placeholder="Busar y seleccionar..."
                    size="small"
                    value-key="id"
                    remote
                    :remote-method="onFindResponsible"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in users"
                      :key="item.id"
                      :label="item.getFullName()"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Comentarios/Observaciones" prop="comments">
                  <el-input
                    v-model="formData.comments"
                    size="small"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="Paquetes">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24" align="right">
              <el-button type="primary" size="small" @click="onAddPackage">
                Agregar Paquete
              </el-button>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24" align="right">
              <el-table :data="formData.getPackages()" style="width: 100%">
                <el-table-column label="Numero de Guía">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.trackingNumber"
                      type="textarea"
                      size="small"
                      :disabled="!scope.row.updateStatus"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Cant. Paquetes" width="140">
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.qty"
                      size="small"
                      :disabled="!scope.row.updateStatus"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Opciones" width="180" align="center">
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        v-if="scope.row.id"
                        size="small"
                        @click="onEditPackage(scope.row)"
                      >
                        Editar
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        @click="onDeletePackage(scope.$index)"
                      >
                        Eliminar
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <!-- <el-tab-pane label="Notificaciones">
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="24"
              align="right"
            >
              <el-button
                type="primary"
                size="small"
                @click="onAddConfigurationNotification"
              >
                Agregar Notificación
              </el-button>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="24"
              align="right"
            >
              <el-table
                :data="formData.getConfigurationNotifications()"
                style="width: 100%"
              >
                <el-table-column label="Descripción">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.applicationUser"
                      :disabled="!scope.row.updateStatus"
                      filterable
                      remote
                      placeholder="Buscar usuario a notificar..."
                      :remote-method="onFindUser"
                      value-key="id"
                      size="small"
                      clearable
                      @focus="onFocusUser(scope.$index)"
                      @change="onChangeUser"
                    >
                      <el-option
                        v-for="item in scope.row.users"
                        :key="item.id"
                        :label="item.getUserNameEmail()"
                        :value="item"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Opciones"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        v-if="scope.row.id"
                        size="small"
                        @click="onEditConfigurationNotification(scope.row)"
                      >
                        Editar
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        @click="onDeleteConfigurationNotification(scope.$index)"
                      >
                        Eliminar
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>-->
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
