import _ from 'lodash';
import BaseModel from '../../infraestructure/BaseModel';
import ProductPackaging from './ProductPackaging';
import ProductImage from './ProductImage';

export default class Product extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        const fd = new FormData()
        this.bindPictureFiles(fd)
        this.bindData(fd)
        return fd
    }

    toRackSend() {
        return {
            id: this.id,
            productId: (this.product) ? this.product.id : null,
            updateState: this.updateState,
        }
    }

    bindPictureFiles(formData) {
        if(!_.isEmpty(this.pictureFiles)){
            this.pictureFiles.forEach((file) => {
                formData.append('pictureFiles', file, file.uid);
            })
        }
    }

    bindData(formData) {
        formData.append('product', JSON.stringify({
            sku: this.sku,
            intSku: this.intSku,
            name: this.name,
            description: this.description,
            price: this.price,
            unitSize: this.unitSize,
            lowInventory: this.lowInventory,
            brandId: (this.brand) ? this.brand.id : null,
            usageTags: this.usageTags,
            categoryId: (this.category) ? this.category.id : null,
            measureUnitId: (this.measureUnit) ? this.measureUnit.id : null,
            packingTypeId: (this.packingType) ? this.packingType.id : null,
            warehouseId: (this.warehouse) ? this.warehouse.id : null,
            productPackagings: this.productPackagings.map((item) => item.toSend()),
            isActive: this.isActive,
            productImages: this.productImages.map((item) => {
                // console.log(this.coverImage, item.fileUrl, (this.coverImage === item.fileUrl))
                item.isCover = (this.coverImage == item.fileUrl)
                // console.log(item.isCover)
                return item.toSend()
            }),
        }))
    }

    setUsageTags(usageTags) {
        this.usageTags = usageTags.join(',')
    }

    getPackagings() {
        return this.productPackagings.filter((item) => (item.updateState !== this.UPDATE_STATUS.REMOVED));
    }

    addProductPackaging() {
        this.productPackagings.push(new ProductPackaging({
            updateState: this.UPDATE_STATUS.ADDED
        }, this.UPDATE_STATUS.ADDED));
    }

    editProductPackaging(index) {
        var item = this.getPackagings()[index]
        if(item) {
            var currentIndex = this.productPackagings.indexOf(item);
            if(currentIndex != -1) {
                this.productPackagings[currentIndex].updateState = this.UPDATE_STATUS.UPDATED;
            } else {
                throw new Error('Emabalaje no encontrado');
            }
        } else {
            throw new Error('Emabalaje no encontrado');
        }
    }

    removeProductPackaging(index) {
        let item = this.productPackagings[index];
        if (item.id) {
            this.productPackagings[index].updateState = this.UPDATE_STATUS.REMOVED;
        } else {
            this.productPackagings.splice(index, 1);
        }
    }

    getImages() {
        return this.productImages.filter((item) => (item.updateState !== this.UPDATE_STATUS.REMOVED));
    }

    addImage(data) {
        data.updateState = this.UPDATE_STATUS.ADDED
        this.productImages.push(new ProductImage(data, 'create'));
    }

    removeImage(selectedIndex) {
        var item = this.getImages()[selectedIndex]
        let currentIndex = this.productImages.indexOf(item);
        if(currentIndex != -1) {
            if (item.id) {
                this.productImages[currentIndex].updateState = this.UPDATE_STATUS.REMOVED
            } else {
                this.productImages.splice(currentIndex, 1)
                this.pictureFiles.splice(currentIndex, 1)
            }
        } else {
            throw new Error('Imagen no encontrada');
        }
    }
}