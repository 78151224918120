import BaseViewModel from '../../infraestructure/BaseViewModel'
import Supplier from './Supplier'

export default class SuppliersViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    //#region  INICIALIZADORES
    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]
    //#endregion

    //#region CRUD

    create(data) {
        return new Supplier(data, 'create')
    }

    edit(data) {
        return new Supplier(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.suppliers.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)

        } else {
            this.api.suppliers.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.suppliers.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.suppliers.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.suppliers.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    import(file) {
        this.api.suppliers.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.suppliers.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }
    
    allClearFilters() {
        this.view.filtersSelected = {
            aliasName: '',
            email: '',
            fiscalNumber: '',
            fiscalName: '',
            isActive: null,
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Aliasname');
        this.view.removeFilter('ContactEmail');
        this.view.removeFilter('FiscalNumber');
        this.view.removeFilter('FiscalName');
        this.view.removeFilter('IsActive');
        this.view.onFilter();
    }
    //#endregion

    // #region BINDING DATA
    bindForm(formData) {
        console.log(formData)
        setTimeout(() => {
            this.view.onComponentLoading(this.view.config, false, '')
        }, 400);
    }
    bindList() {}
    //#endregion

    // #region QUERIES

    // #endregion

    //#region MAPPING DATA

    mapCollection(collection) {
        return collection.map((item) => new Supplier(item))
    }

    // #endregion

    // #region VALIDATIONS

    // #endregion
}