import BaseViewModel from '../../infraestructure/BaseViewModel'

export default class SessionBoxViewModel extends BaseViewModel{

    logout() {
        this.view.$nextTick(() => {
            this.view.removeToken();
            this.view.removeSession();
            setTimeout(() => {
                this.view.$router.go({ name: "LoginView" });
            }, 600 );
        });
    }
}