import BaseViewModel from "../../infraestructure/BaseViewModel";
import Product from '../../features/products/Product';
export default class ProductSeekerViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    findCollection(filters) {
        this.api.productSeeker
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    bindFormView(productId) {
        this.api.products.findOne(productId)
            .then((response) => this.view.onFindProductResponse(response.data))
            .catch(this.view.onError)
    }

    /* bindListView() {
        this.api.products.find(`Skip=0&Limit=50&IsActive=true`)
            .then((response) => this.view.onFindProductsResponse(response.data))
            .catch(this.view.onError)
    } */

    bindFiltersView() {
        this.view.isCategoriesLoading = true
        this.view.isBrandsLoading = true
        this.view.isMeasureUnitsLoading = true
        this.view.isWarehouseLoading = true
        this.view.isListLoading = true

        this.api.productCategories.find(`Skip=0&Limit=100000&IsActive=true`)
            .then((response) => {
                setTimeout(() => {
                    this.view.categories = response.data.data
                    this.view.isCategoriesLoading = false
                }, 400)
                return this.api.brands.find(`Skip=0&Limit=100000&IsActive=true`)
            })
            .then((response) => {
                setTimeout(() => {
                    this.view.brands = response.data.data
                    this.view.isBrandsLoading = false
                }, 400);

                return this.api.measureUnits.find(`Skip=0&Limit=100000&IsActive=true`)
            })
            .then((response) => {
                setTimeout(() => {
                    this.view.measureUnits = response.data.data
                    this.view.isMeasureUnitsLoading = false
                }, 400);
                return this.api.warehouses.find(`Skip=0&Limit=100000&IsActive=true`)
            })
            .then((response) =>{
                setTimeout(() => {
                    this.view.warehouses = response.data.data
                    this.view.isWarehouseLoading = false
                }, 400);
            })
            .catch(this.view.onError)
    }

    addToRequest(item) {
        this.api.shoppingCarts.create(item)
            .then((response) => this.view.onAddToRequestResponse(response.data))
            .catch((error) => this.view.onAddToRequestError(error));
    }

    mapCollection(collection) {
        return collection.map((item) => new Product(item));
    }
}