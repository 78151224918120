import BaseModule from '../../infraestructure/BaseModule'

export default {
    extends: BaseModule,
    data() {
        return {
            filters: {

            }
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        onFilter() {
            this.onFilter('on-filters-product-receptions', this.filters)
        }
    }
}