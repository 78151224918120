<script>
import ModuleProductSeekerComponent from '../features/ModuleProductSeekerComponent'
import ListProductSeekerView from './ListProductSeekerView'

export default {
  components: {
    'list-product-seeker-view': ListProductSeekerView
  },
  extends: ModuleProductSeekerComponent
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="fa fa-search" aria-hidden="true"></i> Buscador de Productos
        </h4>
      </div>
    </div>
    <!--End Page header-->
    <list-product-seeker-view />
  </div>
</template>
