import BaseModule from '../../infraestructure/BaseModule'
import BrandsViewModel from '../../../core/features/brands/BrandsViewModel'
import _ from 'lodash';

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            activeStates: BrandsViewModel.activeStates,
            filtersSelected: {
                code: '',
                name: '',
                isActive: null,
            },
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new BrandsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent( 'on-filters-brands', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByCode: _.debounce(function (text) {
            (text) ? this.addFilter('Code', text) : this.removeFilter('Code')
            this.onFilter();
        }, 500),
        onSearchByName: _.debounce(function (text) {
            (text) ? this.addFilter('Name', text) : this.removeFilter('Name')
            this.onFilter();
        }, 500),
        onActiveChange(activeState) {
            (activeState.length != 0) ? this.addFilter('IsActive', activeState) : this.removeFilter('IsActive')
            this.onFilter();
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}