export default class User {
  constructor(options, state) {
    Object.assign(this, options)
    if (state) {
      this.state = state
    }
  }

  getFullName() {
    return `${this.firstName}  ${this.lastName}`
  }

  getUserNameEmail() {
    return `${this.username} | ${this.email}`
  }

  getResponsibleFullName() {
    return this.name
  }

  toSend() {
    return {
      id: this.id,
      username: this.username.trim(),
      password: this.password,
      updatePassword: this.updatePassword,
      passwordConfirmation: this.passwordConfirmation,
      roleId: (this.role) ? this.role.id : null,
      email: this.email.trim(),
      isActive: this.isActive
    }
  }
}