<script>
import ModalWarehouseComponent from "../features/ModalWarehouseComponent";
export default {
  name: "ModalWarehouseComponent",
  extends: ModalWarehouseComponent,
};
</script>

<template>
  <el-dialog
    title="Almacén"
    :visible.sync="config.isVisible"
    custom-class="modal-product-category"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
   <span
      slot="title"
      class="dialog-title"
    >
      <i class="fa fa-th" /> Almacén
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información del Almacén</legend>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Código"
              prop="code"
            >
              <el-input
                v-model="formData.code"
                size="small"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Nombre"
              prop="name"
            >
              <el-input
                v-model="formData.name"
                size="small"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Descripción"
              prop="description"
            >
              <el-input
                v-model="formData.description"
                size="small"
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
              <el-form-item label="¿Activo?" prop="isActive">
                <el-switch
                  style="display: block"
                  v-model="formData.isActive"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Si"
                  inactive-text="No">
                </el-switch>
              </el-form-item>
            </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        class="bg-cancel"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>