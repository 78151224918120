<script>
import _ from "lodash";
import BaseComponent from './BaseComponent'

export default {
    extends: BaseComponent,
    data() {
    return {
            viewModel: null,
            filters: [],
            currentPage: 1,
            limit: 50,
        };
    },
    methods: {
        addFilter(filterName, filterValue) {
            this.removeFilter(filterName);
            this.filters.push({ filterName: filterName, filterValue: filterValue });
        },
        removeFilter(filterName) {
            _.remove(this.filters, { filterName: filterName });
        },
        onCancel(){
            this.config.isVisible = false;
        },
        onSave(formData){
            this.viewModel.save(formData);
        }
    }
}
</script>