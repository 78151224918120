import BaseList from "../../infraestructure/BaseList";
import ProductReceptionViewModel from "../../../core/features/productReceptions/ProductReceptionViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      modulePermissions: "ProductReceptions",
      viewModel: null,
      selectedItem: null
    };
  },
  mounted() {
    this.onMountedEvent("on-filters-product-receptions", this.onFilters);
    this.onMountedEvent("on-submited-product-receptions", this.onSearch);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-filters-product-receptions", this.onFilters);
    this.onBeforeDestroyEvent("on-submited-product-receptions", this.onSearch);
  },
  created() {
    this.viewModel = new ProductReceptionViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onFilters(filters) {
      this.filters = filters
      this.onSearch()
    },
    onCreate() {
      this.onComponentLoading(this.modalConfig, true, 'Cargando...')
      this.onComponentVisible(this.modalConfig, true)
      this.item = this.viewModel.create({
        isActive : true,
        traces: [],
      });
      this.modalConfig.isVisible = true;
    },
    onChangeOption(event) {
      if (event.command === "edit") this.onEdit(event.item.id);
      if (event.command === "delete") this.onDelete(event.item.id);
      if (event.command === "export-pdf") this.onExportPdf(event.item.id);
      if (event.command === "export-excel") this.onExportExcel(event.item.id);
      this.selectedItem = event.item;
    },
    onExportPdf(id) {
      this.isListLoading = true;
      this.viewModel.exportPdf(id);
    },
    onExportPdfResponse(response) {
      setTimeout(() => {
        this.isListLoading = false;
        this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () => {
          setTimeout(() => {
            this.notifySuccess("Reporte Generado.");
          }, 400);
        });
      }, 400);
    }
  },
};
