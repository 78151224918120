import BaseModel from '../../infraestructure/BaseModel';

export default class ProductReceptionTrace extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            productId: (this.product) ? this.product.id : null,
            quantity: this.quantity,
            updateState: this.updateState,
        }
    }

    isEditable() {
        return this.updateState == this.UPDATE_STATUS.ADDED || this.updateState == this.UPDATE_STATUS.UPDATED;
    }
}