import _ from 'lodash'
import ChildrenList from "../../generics/childrenList/ChildrenList"
import ProductInventoryTrace from "./ProductInventoryTrace"

export default class ProductInventory extends ChildrenList{
    constructor(options, state, childrenListName, childrenList) {
        super(options, state, childrenListName || 'traces', childrenList || [])
    }

    toSend() {
        return {
            id: this.id,
            reportDate: this.reportDate,
            reportStatus: this.reportStatus,
            responsibleId: (this.responsible) ? this.responsible.id : null,
            operatorId: (this.operator) ? this.operator.id : null,
            traces: this.traces.map(trace => trace.toSend()),
            isActive: this.isActive
        }
    }

    addTrace(data) {
        let trace = new ProductInventoryTrace(data)
        this.add(trace)
    }

    bindProductTraces() {
        this.getCollection().forEach((item) => {
            item.products = [item.product]
        })
    }

    setUpdateTraces() {
        this.traces = this.getCollection().map(item => new ProductInventoryTrace(item, "update"))
    }

    setTraces(collection) {
        var productIds = this.traces.map(item => item.product.id)
        collection.forEach((item) => {
            if (_.isEmpty(productIds) || productIds.indexOf(item.product.id) === -1) {
                let trace = new ProductInventoryTrace(item)
                trace.products = [item.product]
                trace.physicalTotal = 0
                trace.racks = item.product.racks.map(rack => rack.name).join(", ")
                this.add(trace)
            }
        })
       
    }
}