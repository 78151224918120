<script>
import FilterBoxComponent from "../features/FilterBoxComponent";

export default {
  extends: FilterBoxComponent,
};
</script>

<template>
  <div>
    <el-card v-loading="isCategoriesLoading" class="box-card">
      <div slot="header" class="clearfix">
        <span>Categorias</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="onClearCategories"
        >
          Limpiar Filtros
        </el-button>
      </div>
      <el-checkbox-group v-model="filters.categories">
        <el-row class="row-bg" :gutter="20">
          <el-table
            :data="categories"
            style="width: 100%"
            height="150px"
            :show-header="false"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-checkbox :label="scope.row.id">
                  {{ scope.row.name }}
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-checkbox-group>
    </el-card>
    <el-card v-loading="isBrandsLoading" class="box-card">
      <div slot="header" class="clearfix">
        <span>Marcas</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="onClearBrands"
        >
          Limpiar Filtros
        </el-button>
      </div>
      <el-checkbox-group v-model="filters.brands">
        <el-row class="row-bg" :gutter="20">
          <el-table
            :data="brands"
            style="width: 100%"
            height="150px"
            :show-header="false"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-checkbox :label="scope.row.id">
                  {{ scope.row.name }}
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-checkbox-group>
    </el-card>
    <el-card v-loading="isMeasureUnitsLoading" class="box-card">
      <div slot="header" class="clearfix">
        <span>Uni. de Medida</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="onClearMeasureUnits"
        >
          Limpiar Filtros
        </el-button>
      </div>
      <div :height="15">
        <el-checkbox-group v-model="filters.measureUnits">
          <el-row class="row-bg" :gutter="20">
            <el-table
              :data="measureUnits"
              style="width: 100%"
              height="150px"
              :show-header="false"
            >
              <el-table-column>
                <template slot-scope="scope">
                  <el-checkbox :label="scope.row.id">
                    {{ scope.row.name }}
                  </el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-checkbox-group>
      </div>
    </el-card>

    <el-card v-loading="isWarehouseLoading" class="box-card">
      <div slot="header" class="clearfix">
        <span>Almacén</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="onClearWarehouses"
        >
          Limpiar Filtros
        </el-button>
      </div>
      <div :height="15">
        <el-checkbox-group v-model="filters.warehouses">
          <el-row class="row-bg" :gutter="20">
            <el-table
              :data="warehouses"
              style="width: 100%"
              height="150px"
              :show-header="false"
            >
              <el-table-column>
                <template slot-scope="scope">
                  <el-checkbox :label="scope.row.id">
                    {{ scope.row.name }}
                  </el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-checkbox-group>
      </div>
    </el-card>
  </div>
</template>
