import BaseApi from "../../infraestructure/BaseApi";

export default class ShoppingCartApi extends BaseApi {
  constructor(client, endPoint) {
    super(client, endPoint);
  }

  deleteAll() {
    return this.client.delete(`${this.endPoint}/delete-all`)
  }

  createProductRequestReport(data) {
    return this.client.post(`${this.endPoint}/create-product-request`, data)
  }
}