
export default class BaseApi {
    constructor(client, endPoint){
        this.client = client;
        this.endPoint = endPoint;
    }

    all(requestsCollection) {
        
        return this.client.all(requestsCollection)
    }

    create(data) {
        return this.client.post(this.endPoint, data)
    }

    update(id, data) {
        return this.client.put(`${this.endPoint}/${id}`, data)
    }

    delete(id) {
        return this.client.delete(`${this.endPoint}/${id}`)
    }

    find(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}${parameters}`)
    }

    findOne(id) {
        return this.client.get(`${this.endPoint}/${id}`)
    }

    import(file) {
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${this.endPoint}/import`, fd)
    }

    exportToExcel() {
        return this.client.get(`${this.endPoint}/export-excel`)
    }

    exportToPdf() {
        return this.client.get(`${this.endPoint}/export-pdf`)
    }
}