<script>
import ModuleHomeComponent from "../features/ModuleHomeComponent";

import LineChart from "../../shared/features/LineChart";
// import BarChart from "../../shared/features/BarChart";
// import PieChart from "../../shared/features/PieChart";
import DoughnutChart from "../../shared/features/DoughnutChart";

export default {
  components: {
    LineChart,
    // BarChart,
    // PieChart,
    DoughnutChart
  },
  extends: ModuleHomeComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          <i class="el-icon-menu" /> Resumen
        </h4>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
      </div>
    </div>
    <!--End Page header-->
    <el-row
      type="flex"
      class="row-bg"
      style="margin-bottom: 20px;"
      :gutter="20"
    >
      <el-col :span="16" v-loading="totalMovementLoading">
        <el-tabs type="border-card">
          <el-tab-pane label="Reportes Totales de Productos">
            <line-chart
                :chart-data="dataBar"
                :height="100"
              />
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="8" v-loading="totalMovementLoading">
        <el-tabs type="border-card">
          <el-tab-pane label="Empleados Por Rol Activos">
            <doughnut-chart
            v-if="dataDoughnut"
            :chart-data="dataDoughnut"
            :height="210"
          />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px;"
    >
      <el-col :span="24">
        <el-tabs type="border-card">
          <el-tab-pane label="Solicitudes Pendientes">
            <el-table
              :data="pendingProductRequests"
              style="width: 100%"
              v-loading="pendingRequestsLoading">
              <el-table-column
                property="consecutiveNumber"
                label="Folio"
                show-overflow-tooltip
                width="180"
                align="center"
              />
              <el-table-column
                label="Fecha de Reporte"
                width="180"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    type="primary"
                    effect="plain"
                  >
                    <i class="el-icon-date" /> {{ scope.row.reportDate | formatDate }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Total Producto"
                show-overflow-tooltip
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="primary"
                    effect="plain"
                  >
                    <i class="el-icon-box" /> {{ scope.row.totalProducts }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Estatus"
                width="180"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.status === 1"
                    type="warning"
                    effect="plain"
                  >
                    <i class="el-icon-warning" /> {{ scope.row.statusName }}
                  </el-tag>
                  <el-tag
                    v-else-if="scope.row.status === 2"
                    type="success"
                    effect="plain"
                  >
                    <i class="el-icon-success" /> {{ scope.row.statusName }}
                  </el-tag>
                  <el-tag
                    v-else-if="scope.row.status === 3"
                    type="danger"
                    effect="plain"
                  >
                    <i class="el-icon-error" /> {{ scope.row.statusName }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Responsable"
                show-overflow-tooltip
                min-width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.responsible }}
                </template>
              </el-table-column>
              <el-table-column
                property="createdAt"
                label="F. Creación"
                show-overflow-tooltip
                width="140"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.createdAt"
                    type="primary"
                    effect="plain"
                  >
                    <i class="el-icon-date" /> {{ scope.row.createdAt | formatDate }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Inventario Bajo">
            
            <el-table
              :data="productsTopLowInventory"
              style="width: 100%"
              v-loading="pendingRequestsLoading">
              <el-table-column
                label="Sku/Int Sku"
                show-overflow-tooltip
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.sku }} / {{ scope.row.intSku }}
                </template>
              </el-table-column>
              <el-table-column
                label="Descripción"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.description }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="Etiquetas de Uso"
                width="180"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.usageTags }}
                </template>
              </el-table-column> -->
              <!-- <el-table-column
                label="Contenido"
                width="180"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.unitSize }}
                </template>
              </el-table-column>-->
              
              <el-table-column
                label="Total Stock"
                show-overflow-tooltip
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="primary"
                    effect="plain"
                  >
                    <i class="el-icon-box" /> {{ scope.row.totalStock }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Inventario Bajo"
                show-overflow-tooltip
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="primary"
                    effect="plain"
                  >
                    <i class="el-icon-box" /> {{ scope.row.lowInventory }}
                  </el-tag>
                </template>
              </el-table-column>

            </el-table>
            <el-row type="flex" class="row-bg" :gutter="20" style="margin-top:20px;">
              <el-col :span="8" align="left">
                <p class="mb-sm-0">
                  Mostrar 1 a {{ paginationLowInventory.limit }} de
                  {{ paginationLowInventory.totalItems }} registros
                </p>
              </el-col>
              <el-col :span="16" align="right">
                <el-pagination
                  :page-count="paginationLowInventory.totalPages"
                  layout="sizes, prev, pager, next"
                  @current-change="onPageChangeLowInventory"
                  @size-change="onPageSizeChangeLowInventory"
                  :page-sizes="pageSizes"
                  :page-size="paginationLowInventory.limit"
                >
                </el-pagination>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- <el-tab-pane label="Productos en Racks"></el-tab-pane> -->
        </el-tabs>
      </el-col>
    </el-row>
    <!-- <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="6">
        <h3>Salida de Productos</h3>
        <div style="max-width: 600px; margin: 30px auto">
          <bar-chart
            v-if="dataBar"
            :chart-data="dataBar"
          />
        </div>
      </el-col>
      <el-col
        :span="6"
        align=""
      >
        <h3>Recepción de Productos</h3>
        <div style="max-width: 600px; margin: 30px auto">
          <line-chart
            v-if="dataLine"
            :chart-data="dataLine"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <h3>Busquedas de Refacciones</h3>
        <div style="max-width: 600px; margin: 30px auto">
          <pie-chart
            v-if="dataPie"
            :chart-data="dataPie"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <h3>Busquedas de Refacciones</h3>
        <div style="max-width: 600px; margin: 30px auto">
          <doughnut-chart
            v-if="dataDoughnut"
            :chart-data="dataDoughnut"
          />
        </div>
      </el-col>
    </el-row>-->
  </div>
</template>
