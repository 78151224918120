import BaseModel from "../../infraestructure/BaseModel"
import CargoReceptionConfigurationNotification from "./CargoReceptionConfigurationNotification"
import CargoReceptionPackage from "./CargoReceptionPackage"

export default class CargoReception extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        const fd = new FormData()
        this.bindPictureFiles(fd)
        this.bindData(fd)
        return fd
    }

    bindPictureFiles(formData) {    
        if (this.invoiceFile) {
          formData.append('invoiceFile', this.invoiceFile)
        }
    
        if (this.supplierSignatureFile) {
          formData.append('supplierSignatureFile', this.supplierSignatureFile)
        }
      }
    
      bindData(formData) {
        formData.append('cargoReception', JSON.stringify({
            id: this.id,
            comments: this.comments,
            invoiceFileUrl: this.invoiceFileUrl,
            supplierSignatureFileUrl: this.supplierSignatureFileUrl,
            supplierId: (this.supplier) ? this.supplier.id : null,
            responsibleId: (this.responsible) ? this.responsible.id : null,
            receptionDate: this.receptionDate,
            isActive: this.isActive,
            packages: this.packages.map(p => new CargoReceptionPackage(p).toSend()),
            configurationNotifications: this.configurationNotifications.map(p => new CargoReceptionConfigurationNotification(p).toSend())
        }))
      }

    //#region PACKAGES SECTION

    addPackage(data) {
        data.updateStatus = this.UPDATE_STATUS.ADDED
        this.packages.push(new CargoReceptionPackage(data))
    }

    getPackages() {
        return this.packages.filter((item) => item.updateStatus !== this.UPDATE_STATUS.REMOVED)
    }

    removePackage(index) {
        var item = this.packages[index]
        if (item.id) {
            this.packages[index].updateStatus = this.UPDATE_STATUS.REMOVED
        } else {
            this.packages.splice(index, 1)
        }
    }

    //#endregion

    //#region CONFIGURATIONS NOTIFICATIONS SECTION

    addConfigurationNotification(data) {
        data.updateStatus = this.UPDATE_STATUS.ADDED
        this.configurationNotifications.push(new CargoReceptionConfigurationNotification(data))
    }

    getConfigurationNotifications() {
        return this.configurationNotifications.filter((item) => item.updateStatus !== this.UPDATE_STATUS.REMOVED)
    }

    removeConfigurationNotification(index) {
        var item = this.configurationNotifications[index]
        if (item.id) {
            this.configurationNotifications[index].updateStatus = this.UPDATE_STATUS.REMOVED
        } else {
            this.configurationNotifications.splice(index, 1)
        }
    }

    setUsersForSelector(index, users) {
        let item = this.getConfigurationNotifications()[index]
        item.users = users
    }

    //#endregion
}