import BaseForm from '../../infraestructure/BaseForm'
import StaffViewModel from '../../../core/features/staff/StaffViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Staff',
            viewModel: null,
            roles: [],
            rules: {
                role: [
                    { required: true, message: "Selecciona un Role", trigger: "change" }
                ],
                username: [
                    { required: true, message: "Usuario requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                profileNumber: [{
                    required: true,
                    message: 'No. Empleado requerido.',
                    trigger: 'blur'
                }],
                firstName: [{
                    required: true,
                    message: 'Nombres(s) requerido(s).',
                    trigger: 'blur'
                }],
                lastName: [{
                    required: true,
                    message: 'Apellido(s) requerido(s).',
                    trigger: 'blur'
                }],
                email: [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
                password: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                passwordConfirmation: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Verifiación de contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Solo se aceptan valores alfabetico.",
                        trigger: "change"
                    },
                    { validator: this.isValidateCheckPass }
                ],
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new StaffViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        isPasswordRequired() {
            return this.updatePassword || !this.formData.id;
        },
        isValidateCheckPass(rule, value, callback) {
            this.viewModel.verifyConfirmationPassword(value, callback)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onComponentLoading(this.config, true, 'Guardando...')
                    setTimeout(() => {
                        this.onSave(this.formData)
                        return true
                    }, 500);
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onComponentVisible(this.config, false)
            this.onComponentLoading(this.config, false, '')
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-staff', null)
        }
    }
}