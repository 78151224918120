import { EventBus } from '../../../core/helpers/EventsHelper';
import BaseForm from '../../infraestructure/BaseForm'
import ProductRacksViewModel from '../../../core/features/productRacks/ProductRacksViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Products',
            viewModel: null,
            collectionProductRackQr: [],
            brands: [],
            productCategories: [],
            productPackingTypes: [],
            productPackingUnits: [],
            formData: {
                nameFilter: '',
                codeFilter: '',
            },
            modalProductRackQr: {
                isVisible: false,
                isLoading: false,
                title: 'QRS DE RACKS',
                onAcceptProductRackQr: 'onAcceptProductRackQr'
            },
            pdfFileName: null,
            batchGroups: [],
            batchPosition: 0,
            pdfStrings: [],
            rules: {}
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ProductRacksViewModel(this)
    },
    methods: {
        onAccept() {
            this.formData = null;
            EventBus.$emit(this.conf.onAcceptProductRackQr, null);
        },
        onPrintPdf() {
            this.conf.isLoading = true
            this.viewModel.printProductRackQrToPdf((this.viewModel.getQueryParameters(this.filters)))
        },
        onProductRackQrToPdfResponse(response) {
            this.pdfFileName = response.fileName
            this.batchGroups = this.viewModel.getRequestProductRacksQrsChunks(response.data)
            if(this.batchGroups.length > 0){
                this.viewModel.batchProductRacksQr(this.batchGroups[this.batchPosition])
            } else {
                this.conf.isLoading = false
                this.notifyWarning('No se encontraron registros con los parametros de búsqueda seleccionados.')
            }
        },
        async onProductRackBatchResponse(response){  
            this.batchPosition += 1
            this.pdfStrings.push(response.data)
            if(this.batchGroups.length <= this.batchPosition){
                this.batchPosition = 0
                await this.viewModel.mergePdfFiles(this.pdfStrings, this.pdfFileName, () => {
                    this.conf.isLoading = false
                    this.notifySuccess("Etiquetas generadas.")
                    this.pdfStrings = []
                });
            } else {
                this.viewModel.batchProductRacksQr(this.batchGroups[this.batchPosition])
            }
        },
        onProductRackQrToPdfError(error){
            this.conf.isLoading = false
            this.onError(error)
        },
        onFilterChange(data, field) {
            this.viewModel.updateFilters(data, field, '=');
        },
        mapRiskLabel(criticalLavelStatus) {
            return this.viewModel.mapCriticalLevelStatus(criticalLavelStatus)
        },
        mapRiskStyle(criticalLavelStatus) {
            return this.viewModel.mapCriticalLevelStyle(criticalLavelStatus)
        }
    }
}