import moment from 'moment'

export default {
    substractDays(date, numDays, formatIn, formatOut) {
        return moment(date, formatIn).subtract(numDays, 'days').format(formatOut)
    },
    addDays(date, numDays, formatOut) {
        if (formatOut) {
            return moment(date).add(numDays, 'days').format(formatOut)
        }
        return moment(date).add(numDays, 'days')
    },
    diffDays(firstDate, secondDate, formatIn) {
        const elementOne = moment(firstDate, formatIn)
        const elementTwo = moment(secondDate, formatIn)
        return elementOne.diff(elementTwo, 'days')
    },
    formatDate(date, formatIn, formatOut) {
        return moment(date, formatIn).format(formatOut)
    },
    getWeekNumber(date, formatIn) {
        return moment(date, formatIn).isoWeek()
    },
    getFirstDayOfWeek(date, formatIn) {
        const weekNumber = this.getWeekNumber(date, formatIn)
        return moment().isoWeek(weekNumber).isoWeekday(1)
    },
    getLastDayOfWeek(date, formatIn) {
        const weekNumber = this.getWeekNumber(date, formatIn)
        return moment().isoWeek(weekNumber).isoWeekday(7)
    },
    getFirstDayOfMonth(date) {
        const data = date.split('-')
        return moment([data[0], data[1] - 1])
    },
    getLastDayOfMonth(date) {
        const data = date.split('-')
        const startDate = moment([data[0], data[1] - 1])
        return moment(startDate).endOf('month')
    },
    getFirstDayOfYear(date) {
        return moment(date, 'YYYY').startOf('year')
    },
    getLastDayOfYear() {

    },
    addYears(date, numYears, formatIn) {
        return moment(date, formatIn).add(numYears, 'years')
    },
    isValidDate(date, formatIn) {
        return moment(date, formatIn).isValid()
    },
    getCurrentMonth() {
        return moment().format('MM/YYYY')
    },
    transformToDate(date, formatIn) {
        return moment(date, formatIn).toDate()
    },
    getCurrentDate(formatOut) {
        return moment().format(formatOut)
    },
    getDateRangeAsArrayDate(today, differenceDays, formatInt, formatOut) {
        const currentDay = today || moment()
        const startDate = this.substractDays(currentDay, differenceDays, formatInt, formatOut)
        const endDate = this.formatDate(currentDay, formatInt, formatOut)
        return [
            this.transformToDate(startDate, 'DD/MM/YYYY'),
            this.transformToDate(endDate, 'DD/MM/YYYY')
        ]
    },
    getCurrentYear() {
        return moment().year();
    },
    isDateRangeValid(dateRange, formatIn) {
        const startDate = this.isValidDate(dateRange[0], formatIn)
        const endDate = this.isValidDate(dateRange[1], formatIn)
        return (startDate && endDate)
    },
    getDateRangeAsString(dateRange, formatIn, formatOut) {
        const startDate = this.formatDate(dateRange[0], formatIn, formatOut)
        const endDate = this.formatDate(dateRange[1], formatIn, formatOut)
        return `${startDate}-${endDate}`
    },
    addFortnight(date) {
        return moment(date).add(15, 'day').format('YYYY/MM/DD')
    },
    addMonth(date) {
        return moment(date).add(1, 'month').format('YYYY/MM/DD')
    },
    addBimester(date) {
        return moment(date).add(2, 'month').format('YYYY/MM/DD')
    },
    addTrimester(date) {
        return moment(date).add(3, 'month').format('YYYY/MM/DD')
    },
    addQuater(date) {
        return moment(date).add(4, 'month').format('YYYY/MM/DD')
    },
    addSemester(date) {
        return moment(date).add(6, 'month').format('YYYY/MM/DD')
    },
    addYear(date) {
        return moment(date).add(1, 'years').format('YYYY/MM/DD')
    },
    addThreeYear(date) {
        return moment(date).add(3, 'years').format('YYYY/MM/DD')
    },
    addFiveYear(date) {
        return moment(date).add(5, 'years').format('YYYY/MM/DD')
    },
    checkWithInitDate(date) {
        return (moment(date).format('DD/MM/YYYY') == '01/01/0001') ? false : true
    },
    returnWithDateFormat(date) {
        return moment(date).format('DD/MM/YYYY');
    }
}
