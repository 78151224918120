import _ from 'lodash'
import BaseViewModel from "../../infraestructure/BaseViewModel";
import FileHelper from '../../helpers/FileHelper';
import Product from "./Product";
import ProductPackaging from "./ProductPackaging";
import Brand from '../brands/Brand';
import ProductCategory from '../productCategories/ProductCategory';
import MeasureUnit from '../measureUnits/MeasureUnit';
import ProductPackingType from '../productPackingTypes/ProductPackingType';
import ProductPackingUnit from '../productPackingUnits/ProductPackingUnit';
import ProductImage from '../products/ProductImage';
import Warehouse from '../warehouses/Warehouse'

export default class ProductsViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static activeStates = [
        {
            id: 1,
            name: "Si",
            state: true,
        },
        {
            id: 2,
            name: "No",
            state: false,
        },
    ]

    create(data) {
        return new Product(data, "create");
    }

    edit(data) {
        return new Product(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.products
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.products
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.products
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.products
            .find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.products
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.products.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    importStock(file) {
        this.api.products.importStock(file)
            .then((response) => this.view.onImportStockResponse(response.data))
            .catch(this.view.onImportStockError);
    }

    export() {
        this.api.products.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    allClearFilters() {
        this.view.filtersSelected = {
            supplier: '',
            receptionDate: '',
        };
        
        //this.view.$refs.containersTable.clearFilter();
        this.view.removeFilter('Sku');
        this.view.removeFilter('Name');
        this.view.removeFilter('BrandId');
        this.view.onFilter();
    }

    bindFormView(formData) {
        this.api.warehouses.find()
        .then((response) => {
            this.view.warehouses = this.mapWarehouses(response.data.data)
            return this.api.productPackingUnits.find(`Skip=0&Limit=1000000&IsActive=true`)
        })
        .then((response) => {
            this.view.productPackingUnits = this.mapProductPackingUnits(response.data.data);
            if (formData.state === 'update') {
                this.view.brands = (formData.brand) ? [formData.brand] : [];
                this.view.productCategories = (formData.category) ? [formData.category] : [];
                this.view.measureUnits = (formData.measureUnit) ? [formData.measureUnit] : [];
                this.view.productPackingTypes = (formData.packingType) ? [formData.packingType] : [];
                this.view.usageTags = (formData.usageTags) ? formData.usageTags.split(',') : [];
                formData.productPackagings = this.mapProductPackaging(formData.productPackagings);
                formData.productImages = this.mapProductImages(formData.productImages);
                formData.pictureFiles = [];
                if(!_.isEmpty(formData.productImages)) {
                    var currentCover = _.first(formData.productImages.filter(item => item.isCover))
                    if(currentCover) {
                        formData.coverImage = currentCover.fileUrl;
                    }
                    
                }
            }
            setTimeout(() => {
                this.view.onComponentLoading(this.view.config, false, '')
            }, 400);
        })
        .catch(this.view.onError);

    }

    bindList() {
        this.api.brands.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.brands = response.data.data
            })
            .catch(this.view.onError);
    }

    bindFormProductQrView(){
        this.api.warehouses.find()
        .then((response) => this.view.warehouses = this.mapWarehouses(response.data.data))
        .catch(this.view.onError);
    }

    printProductQrToPdf(criteria) {
        this.api.products.printProductQrToPdf(criteria)
            .then((response) => this.view.onProductQrToPdfResponse(response.data))
            .catch(this.view.onProductQrToPdfError);
    }

    batchProductsQr(data) {
        this.api.products.batchProductQrToPdf({ products: data })
        .then((response) => this.view.onProductBatchResponse(response.data))
        .catch(this.view.onProductQrToPdfError)
    }

    getRequestProductQrsChunks(products, chunkSize = 100) {
        return products.map((e, i) => { 
            return i % chunkSize === 0 ? products.slice(i, i + chunkSize) : null; 
       }).filter(e => { return e; })
    }

    

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Product(item));
    }

    mapProductPackaging(collection) {
        return collection.map((item) => new ProductPackaging(item));
    }

    //#endregion


    //#region QUERIES

    findWarehouses(query) {
        this.api.warehouses.find(query)
            .then((response) => this.view.onFindWarehousesResponse(response.data))
            .catch(this.view.onError);
    }

    findBrands(query) {
        this.api.brands.find(query)
            .then((response) => this.view.onFindBrandsResponse(response.data))
            .catch(this.view.onError);
    }

    findProductCategories(query) {
        this.api.productCategories.find(query)
            .then((response) => this.view.onFindProductCategoriesResponse(response.data))
            .catch(this.view.onError);
    }

    findProductMeasureUnits(query) {
        this.api.measureUnits.find(query)
            .then((response) => this.view.onFindMeasureUnitsResponse(response.data))
            .catch(this.view.onError);
    }

    findProductPackingTypes(query) {
        this.api.productPackingTypes.find(query)
            .then((response) => this.view.onFindProductPackingTypesResponse(response.data))
            .catch(this.view.onError);
    }

    findProductPackingUnits(query) {
        this.api.productPackingUnits.find(query)
            .then((response) => this.view.onFindProductPackingUnitsResponse(response.data))
            .catch(this.view.onError);
    }


    //#endregion


    //#region MAPPING

    mapBrands(collection) {
        return collection.map((item) => new Brand(item));
    }

    mapProductCategories(collection) {
        return collection.map((item) => new ProductCategory(item));
    }

    mapMeasureUnits(collection) {
        return collection.map((item) => new MeasureUnit(item));
    }

    mapProductPackingTypes(collection) {
        return collection.map((item) => new ProductPackingType(item));
    }

    mapProductPackingUnits(collection) {
        return collection.map((item) => new ProductPackingUnit(item));
    }

    mapWarehouses(collection){
        return collection.map((item) => new Warehouse(item));
    }

    mapProductImages(collection) {
        return collection.map((data) => {
            let item = new ProductImage(data);
            item.updateState = item.UPDATE_STATUS.UPDATED
            return item
        });
    }

    //#endregion

    getGuid() {
        return FileHelper.generateGuid()
    }

    getFileExtension(fileName) {
        return FileHelper.getExtension(fileName)
    }

    fileToBase64(file) {
        return FileHelper.toBase64(file);
    }

}