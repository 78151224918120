<script>
import _ from "lodash";
import BaseComponent from "./BaseComponent";
export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null,
      filters: [],
      currentPage: 1,
      limit: 50,
    };
  },
  methods: {
    addFilter(filterName, filterValue) {
      this.removeFilter(filterName);
      this.filters.push({ filterName: filterName, filterValue: filterValue });
    },
    removeFilter(filterName) {
      _.remove(this.filters, { filterName: filterName });
    },
    defaultFilters() {
      this.addFilter("skip", this.currentPage - 1);
      this.addFilter("limit", this.limit);
    },
    getQueryParameters(filters) {
      return filters
        .map((item) => `${item.filterName}=${item.filterValue}`)
        .join("&");
    },
  },
};
</script>
