import BaseModule from '../../infraestructure/BaseModule'
import PermissionsViewModel from '../../../core/features/permissions/PermissionsViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
        }
    },
    created() {
        this.viewModel = new PermissionsViewModel(this)
    },
    methods: {
        onActive() {
            
        },
    }
}