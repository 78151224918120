import BaseApi from "../../infraestructure/BaseApi";

export default class ProductRacksApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    findAllRacksWithProducts(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/with-products${parameters}`)
    }

    printProductRackQrToPdf(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/generate-qrs-pdf${parameters}`)
    }

    batchProductRacksQrToPdf(data) {
        return this.client.post(`${this.endPoint}/batch-qrs-pdf`, data)
    }
}