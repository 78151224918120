import Vue from "vue";
import Router from "vue-router";
import SecurityStorageHelper from '../core/helpers/SecurityStorageHelper';
Vue.use(Router);

import NotFoundView from "../components/shared/views/NotFoundView.vue";
// HOME
import ModuleHomeView from '../components/home/views/ModuleHomeView'


// AUTHORIZATION
import ModuleRoleView from '../components/roles/views/ModuleRoleView'
import ModuleUserView from '../components/users/views/ModuleUserView'
import ModulePermissionsView from '../components/permissions/views/ModulePermissionsView'
import LoginView from '../components/authorization/views/LoginView'
import PasswordRecoveryRequestView from '../components/authorization/views/PasswordRecoveryRequestView'
import PasswordRecoveryUpdateView from '../components/authorization/views/PasswordRecoveryUpdateView'

// EMPRESA
import ModuleStaffView from '../components/staff/views/ModuleStaffView'

// SUPPLIERS
import ModuleSuppliersView from '../components/suppliers/views/ModuleSuppliersView'

//CARGO DELIVERY SCHEDULES
import ModuleCargoReceptionsView from '../components/cargoReceptions/views/ModuleCargoReceptionsView'

// PRODUCTS
import ModuleBrandsView from '../components/brands/views/ModuleBrandsView'
import ModuleProductPackingTypesView from '../components/productPackingTypes/views/ModuleProductPackingTypesView'
import ModuleProductPackingUnitsView from '../components/productPackingUnits/views/ModuleProductPackingUnitsView'
import ModuleProductsView from '../components/products/views/ModuleProductsView'
import ModuleProductCategoryView from '../components/productCategories/views/ModuleProductCategoryView'
import ModuleMeasureUnitView from '../components/measureUnits/views/ModuleMeasureUnitView'
import ModuleProductRacksView from '../components/productRacks/views/ModuleProductRacksView'
import ModuleProductRackLocationsView from '../components/productRackLocations/views/ModuleProductRackLocationsView'
import ModuleProductReceptionView from '../components/productReceptions/views/ModuleProductReceptionView'
import ModuleProductDeliveryView from '../components/productDeliveries/views/ModuleProductDeliveryView'
import ModuleProductRequestView from '../components/productRequests/views/ModuleProductRequestView'
import ModuleProductSeekerView from '../components/productSeeker/views/ModuleProductSeekerView'
import DetailProductSeekerView from '../components/productSeeker/views/DetailProductSeekerView'
import ModuleProductInventoryView from '../components/productInventories/views/ModuleProductInventoryView'
import ModuleProductDevolutionView from '../components/productDevolutions/views/ModuleProductDevolutionView'
import ModuleProductWasteView from '../components/productWastes/views/ModuleProductWasteView'
import ModuleWasteReasonView from '../components/wasteReasons/views/ModuleWasteReasonView'
import ModuleWasteAreaView from '../components/wasteAreas/views/ModuleWasteAreaView'
import ModuleWhiteLabelSettingView from '../components/whiteLabelSettings/views/ModuleWhiteLabelSettingView'
// import { nextTick } from "vue/types/umd";

import ModuleWarehouseView from '../components/warehouses/views/ModuleWarehouseView'

const routes = [
    {
        path: "/login",
        name: "LoginView",
        component: LoginView,
        meta: {
            auth: false
        }
    },
    {
        path: "/recuperar-cuenta",
        name: "PasswordRecoveryRequestView",
        component: PasswordRecoveryRequestView,
        meta: {
            auth: false
        }
    },
    {
        path: "/restore-password",
        name: "PasswordRecoveryUpdateView",
        component: PasswordRecoveryUpdateView,
        meta: {
            auth: false
        }
    },
    {
        path: "/",
        name: "ModuleHomeView",
        component: ModuleHomeView,
        meta: {
            auth: true
        }
    },
    {
        path: "/autorizacion/permisos",
        name: "ModulePermissionsView",
        component: ModulePermissionsView,
        meta: {
            auth: true
        }
    },
    {
        path: '/autorizacion/roles',
        name: "ModuleRoleView",
        component: ModuleRoleView,
        meta: {
            auth: true
        }
    },
    {
        path: '/autorizacion/usuarios',
        name: "ModuleUserView",
        component: ModuleUserView,
        meta: {
            auth: true
        }
    },
    {
        path: '/proveedores',
        name: "ModuleSuppliersView",
        component: ModuleSuppliersView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/paqueteria/recepcion-de-paquetes',
        name: "ModuleCargoReceptionsView",
        component: ModuleCargoReceptionsView,
        meta: {
            auth: true
        }
        
    },
    
    {
        path: '/buscador-de-productos',
        name: "ModuleProductSeekerView",
        component: ModuleProductSeekerView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/buscador-de-productos/producto/:id',
        name: "DetailProductSeekerView",
        component: DetailProductSeekerView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/reportes/recepcion-de-productos',
        name: "ModuleProductReceptionView",
        component: ModuleProductReceptionView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/reportes/entrega-de-productos',
        name: "ModuleProductDeliveryView",
        component: ModuleProductDeliveryView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/reportes/devolucion-de-productos',
        name: "ModuleProductDevolutionView",
        component: ModuleProductDevolutionView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/reportes/orden-de-productos',
        name: "ModuleProductRequestView",
        component: ModuleProductRequestView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/reportes/inventario-de-productos',
        name: "ModuleProductInventoryView",
        component: ModuleProductInventoryView,
        meta: {
            auth: true
        }
    },
    {
        path: '/reportes/merma-de-productos',
        name: "ModuleProductWasteView",
        component: ModuleProductWasteView,
        meta: {
            auth: true
        }
    },    
    {
        path: '/catalogos/merma/areas',
        name: "ModuleWasteAreaView",
        component: ModuleWasteAreaView,
        meta: {
            auth: true
        }
    },
    {
        path: '/catalogos/merma/motivos',
        name: "ModuleWasteReasonView",
        component: ModuleWasteReasonView,
        meta: {
            auth: true
        }
    },

    {
        path: '/catalogos/ubicaciones/estantes',
        name: "ModuleProductRacksView",
        component: ModuleProductRacksView,
        meta: {
            auth: true
        }

    },
    {
        path: '/catalogos/ubicaciones/existencias',
        name: "ModuleProductRackLocationsView",
        component: ModuleProductRackLocationsView,
        meta: {
            auth: true
        }

    },
    {
        path: '/catalogos/ubicaciones/almacenes',
        name: "ModuleWarehouseView",
        component: ModuleWarehouseView,
        meta: {
            auth: true
        }
    },
    {
        path: '/catalogos/articulos/marcas',
        name: "ModuleBrandsView",
        component: ModuleBrandsView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/catalogos/articulos/categorias',
        name: "ModuleProductCategoryView",
        component: ModuleProductCategoryView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/catalogos/articulos/empaques',
        name: "ModuleProductPackingTypesView",
        component: ModuleProductPackingTypesView,
        meta: {
            auth: true
        }

    },
    {
        path: '/catalogos/articulos/embalajes',
        name: "ModuleProductPackingUnitsView",
        component: ModuleProductPackingUnitsView,
        meta: {
            auth: true
        }

    },
    {
        path: '/catalogos/articulos/unidades-de-medida',
        name: "ModuleMeasureUnitView",
        component: ModuleMeasureUnitView,
        meta: {
            auth: true
        }
        
    },
    {
        path: '/catalogos/articulos/productos',
        name: "ModuleProductsView",
        component: ModuleProductsView,
        meta: {
            auth: true
        }

    },
    {
        path: '/empresa/personal',
        name: "ModuleStaffView",
        component: ModuleStaffView,
        meta: {
            auth: true
        }
    },
    {
        path: '/configuraciones-del-sistema',
        name: "ModuleWhiteLabelSettingView",
        component: ModuleWhiteLabelSettingView,
        meta: {
            auth: true
        }
    },

    /*{
        path: '/exportaciones/lineas-de-transportacion',
        name: "ModuleCarrierView",
        component: ModuleCarrierView,
        meta: {
            auth: true
        }
    },
    {
        path: '/exportaciones/registros-de-exportaciones',
        name: "ModuleExportationView",
        component: ModuleExportationView,
        meta: {
            auth: true
        }
    },*/
    {
        path: "*",
        name: "NotFoundView",
        component: NotFoundView,
        meta: {
            auth: false
        }
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        if(SecurityStorageHelper.getSession()) {
            next();
        } else {
            next({name: 'LoginView'});
        }
    } else {
        if(to.name === 'LoginView') {
            if(SecurityStorageHelper.getSession()) {
                next({name: 'ModuleHomeView'});
            } else{
                next();
            }
        } else {
            next();
        }
    }
    
});

export default router;