import BaseApi from "../../infraestructure/BaseApi";

export default class ToolApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint);
    }

    migrateProductImages() {
        return this.client.post(`${this.endPoint}/migrate-product-images`, {});
    }
}