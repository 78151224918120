import _ from 'lodash'
import ChildrenList from "../../generics/childrenList/ChildrenList"
import ProductDeliveryTrace from './ProductDeliveryTrace';
export default class ProductDelivery extends ChildrenList {
    constructor(options, state, childrenListName, childrenList) {
        super(options, state, childrenListName || 'traces', childrenList || [])
    }

    toSend() {
        return {
            id: this.id,
            reportDate: this.reportDate,
            isActive: this.isActive,
            responsibleId: (this.responsible) ? this.responsible.id : null,
            traces: this.getCollection().map((item) => item.toSend())
        }
    }

    addTrace(data) {
        let trace = new ProductDeliveryTrace(data)
        this.add(trace)
    }

    removeProductTrace(selectedIndex) {
        var item = this.getProductTraces()[selectedIndex];
        var index = this.getCollection().indexOf(item);
        if(index != -1) {
            if(item.id) {
                this.getCollection()[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.getCollection().splice(index, 1);
            }
        } else {
            throw new Error('No se encontro el producto a eliminar'); 
        }
    }

    setUpdateTraces() {
        this.traces = this.getCollection().map((item) => new ProductDeliveryTrace(item, "update"))
    }

    bindProductTraces() {
        this.getCollection().forEach((item) => {
            item.products = [item.product]
        })
    }

    setTraces(collection) {
        var productIds = this.traces.map(item => item.product.id)
        
        collection.forEach((item) => {
            if (_.isEmpty(productIds) || productIds.indexOf(item.product.id) === -1) {
                let trace = new ProductDeliveryTrace(item)
                trace.products = [item.product]
                trace.quantity = item.quantity
                trace.racks = item.product.racks.map(rack => rack.name).join(", ")
                this.add(trace)
            }
        })
       
    }
}