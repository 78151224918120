import BaseList from '../../infraestructure/BaseList'
import ProductInventoryViewModel from '../../../core/features/productInventories/ProductInventoryViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'ProductInventories',
            viewModel: null,
            selectedItem: null
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-product-inventory', this.onFilters)
        this.onMountedEvent('on-submited-product-inventory', this.onSearch)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-product-inventory', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-product-inventory', this.onSearch)
    },
    created() {
        this.viewModel = new ProductInventoryViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true,
                traces: []
            })
        },
        onChangeOption(event) {
            if (event.command === "edit") this.onEdit(event.item.id);
            if (event.command === "delete") this.onDelete(event.item.id);
            if (event.command === "export-pdf") this.onExportPdf(event.item.id, 'report');
            if (event.command === "export-excel") this.onExportExcel(event.item.id);
            if (event.command === "export-price-pdf") this.onExportPdf(event.item.id, 'price');
            if (event.command === "export-price-excel") this.onExportPriceExcel(event.item.id);
            this.selectedItem = event.item;
        },
        onExportPdf(id, type) {
            this.isListLoading = true;
            this.viewModel.exportPdf(id, type);
        },
        onExportPriceExcel(id) {
            this.isListLoading = true;
            this.viewModel.exportPricePdf(id);
        },
        onExportPdfResponse(response) {
            setTimeout(() => {
                this.isListLoading = false;
                this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () => {
                  setTimeout(() => {
                    this.notifySuccess("Reporte Generado.");
                  }, 400);
                });
              }, 400);
        }
    }
}