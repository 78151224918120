import _ from 'lodash'

export default class WasteArea {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            isActive: this.isActive,
            wasteReasons: this.mapWasteReasons(this.wasteReasons)
        }
    }

    mapWasteReasons(collection) {
        return _.map(collection, item => item.wasteReason.id)
    }
}