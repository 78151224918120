<script>
import ListSuppliersComponent from "../features/ListSuppliersComponent";
import ModalSupplierView from "./ModalSupplierView";
import ModalImportView from "../../shared/views/ModalImportView";
export default {
  components: {
    ModalSupplierView,
    ModalImportView,
  },
  extends: ListSuppliersComponent,
};
</script>
<template>
  <div class="wrapper-list">
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24" align="right">
        <el-button-group>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
          >
            Agregar Proveedor
          </el-button>
          <el-dropdown size="medium" class="more-options-action">
            <el-button type="plain" size="small">
              <i class="fa fa-bars" /> M&aacute;s Opciones
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="my-2">
                <el-button
                  class="w-100 text-start"
                  size="small"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                >
                  <i class="el-icon-upload2" /> Importar
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item class="my-2">
                <el-button
                  class="w-100 text-start"
                  size="small"
                  @click="onExport"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="el-icon-download" /> Exportar
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-table
          v-loading="isListLoading"
          :data="collection"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            property="fiscalNumber"
            label="R.F.C."
            show-overflow-tooltip
            min-width="130"
            align="center"
          />

          <el-table-column
            property="fiscalName"
            label="Razón Social"
            show-overflow-tooltip
            min-width="120"
          />
          <el-table-column
            property="contactEmail"
            label="Correo de Contacto"
            show-overflow-tooltip
            min-width="150"
          />
          <el-table-column
            property="aliasName"
            label="Alias"
            show-overflow-tooltip
          />
          <el-table-column
            label="¿Activo?"
            width="100"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.isActive === false"
                type="warning"
                effect="plain"
              >
                <i class="el-icon-warning" /> No
              </el-tag>
              <el-tag
                v-else-if="scope.row.isActive === true"
                type="success"
                effect="plain"
              >
                <i class="el-icon-success" /> Si
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            property="createdAt"
            label="F. Creación"
            show-overflow-tooltip
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.createdAt" type="primary">
                {{ scope.row.createdAt | formatDate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            property="updatedAt"
            label="F. Actualización"
            show-overflow-tooltip
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="onCheckModifiedAt(scope.row.modifiedAt)" type="primary">
                {{ scope.row.modifiedAt | formatDate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Opciones"
            width="120"
            align="center"
            show-overflow-tooltip
          >
            <template #default="scope">
              <el-dropdown @command="onChangeOption">
                <el-button size="small">
                  <i class="el-icon-more" />
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="{ command: 'edit', item: scope.row }"
                      :disabled="!onCanRead(modulePermissions)"
                    >
                      <i class="el-icon-edit" /> Mostrar
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="{ command: 'delete', item: scope.row }"
                      :disabled="!onCanDelete(modulePermissions)"
                    >
                      <i class="el-icon-delete" /> Eliminar
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="8" align="left">
        <p class="mb-sm-0">
          Mostrar 1 a {{ pagination.limit }} de
          {{ pagination.totalItems }} registros
        </p>
      </el-col>
      <el-col :span="16" align="right">
        <el-pagination
          :page-count="pagination.totalPages"
          layout="sizes, prev, pager, next"
          @current-change="onPageChange"
          @size-change="onPageSizeChange"
          :page-sizes="pageSizes"
          :page-size="pagination.limit"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <modal-supplier-view
      v-if="modalConfig.isVisible"
      :config="modalConfig"
      :form-data="item"
    />
    <modal-import-view :conf="modalImport" />
  </div>
</template>
