import BaseList from '../../infraestructure/BaseList'
import CargoReceptionsViewModel from '../../../core/features/cargoReceptions/CargoReceptionsViewModel'

export default {
    extends: BaseList,
    data(){
        return {
            modulePermissions: 'CargoDeliverySchedule',
            viewModel: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-cargo-receptions', this.onFilters)
        this.onMountedEvent('on-submited-cargo-reception', this.onSearch)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-cargo-receptions', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-cargo-reception', this.onSearch)
    },
    created() {
        this.viewModel = new CargoReceptionsViewModel(this)
        this.onActive()
    },
    methods: { 
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate(){
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                packages: [],
                configurationNotifications: [],
                isActive : true,
            })
        },
        onDownloadInvoiceFile(item){
            this.isListLoading = true
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.invoiceFileUrl)
                this.isListLoading = false
            }, 400);
        },
    }
}