import BaseComponent from '../../infraestructure/BaseComponent'
import SessionBoxViewModel from '../../../core/features/shared/SessionBoxViewModel'

export default {
    extends: BaseComponent,
    data() {
        return {
            session: null,
            isLogoutLoading: false,
            logoutText: 'Cerrando Sesión...'
        };
    },
    created() {
        this.viewModel = new SessionBoxViewModel(this);
        this.session = this.getSession();
    },
    mounted() { },
    methods: {
        onChangeMenuOptions(command) {
            if (command === "logout") {
                this.onLogout();
            } else {
                this.$router.push(`/${command}`);
            }
        },
        onLogout() {
            this.$confirm("¿Desea cerrar sesión?", "Alerta", {
                confirmButtonText: "Cerrar Sesión",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.isLogoutLoading = true
                    this.viewModel.logout()
                })
                .catch(() => { });
        },
        getSessionName() {
            return this.session.name
        },
        getRoleName() {
            return this.session.role
        },
        getEmail() {
            return this.session.email
        }
    },
    computed: {}
}