import { EventBus } from '../../../core/helpers/EventsHelper';
import BaseList from '../../infraestructure/BaseList'
import ProductsViewModel from '../../../core/features/products/ProductsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Products',
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'CARGAR PRODUCTOS',
                onUploadImport: 'on-upload-import-products',
                onCancelImport: 'on-cancel-import-products',
            },
            modalProductQr: {
                isVisible: false,
                isLoading: false,
                title: 'QRS DE PRODUCTOS',
                onAcceptProductQr: 'onAcceptProductQr'
            },
            modalImportStock: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR EXISTENCIA DE PRODUCTOS',
                onUploadImport: 'on-upload-import-product-stocks',
                onCancelImport: 'on-cancel-import-product-stocks',
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-products', this.onFilters)
        this.onMountedEvent('on-submited-products', this.onSearch)
        this.onMountedEvent('on-upload-import-products', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-products', this.onCancelImport);
        EventBus.$on('onAcceptProductQr', this.onAcceptProductQr);
        this.onMountedEvent('on-upload-import-product-stocks', this.onUploadImportStock)
        this.onMountedEvent('on-cancel-import-product-stocks', this.onCancelImportStock)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-products', this.onFilters)
        this.onBeforeDestroyEvent('on-submited-products', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-products', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-products', this.onCancelImport);
        EventBus.$off('onAcceptProductQr', this.onAcceptProductQr);
        this.onBeforeDestroyEvent('on-upload-import-product-stocks', this.onUploadImportStock)
        this.onBeforeDestroyEvent('on-cancel-import-product-stocks', this.onCancelImportStock)
    },
    created() {
        this.viewModel = new ProductsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilters(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate() {
            this.onComponentLoading(this.modalConfig, true, 'Cargando...')
            this.onComponentVisible(this.modalConfig, true)
            this.item = this.viewModel.create({
                isActive : true,
                productPackagings: [],
                pictureFiles: [],
                productImages: [],
            })
        },
        onShowProductQr() {
            //this.viewModel.showModal(this.modalProductQr, true);
            this.modalProductQr.isVisible = true
        },
        onAcceptProductQr() {
            //this.viewModel.showModal(this.modalProductQr, false);
            this.modalProductQr.isVisible = false
        },
        onShowImportStock(){
            this.modalImportStock.isVisible = true
        },
        onUploadImportStock(file){
            this.modalImportStock.isLoading = true
            this.viewModel.importStock(file);
        },
        onCancelImportStock(){
            this.modalImportStock.isVisible = false
        },
        onImportStockResponse(response) {
            setTimeout(() => {
                this.modalImportStock.isLoading = false;
                this.modalImportStock.isVisible = false;
                this.notifySuccess(response.message);
                this.onSearch();
            }, 800);
        },
        onImportStockError(error){
            this.modalImportStock.isLoading = false;
            this.onError(error)
        }
    }
}