import BaseApi from "../../infraestructure/BaseApi";

export default class WhiteLabelSettingApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findDefault(){
        return this.client.get(`${this.endPoint}/default`)
    }
}