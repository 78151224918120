<script>
import ModalProductComponent from "../features/ModalProductComponent";
export default {
  name: "ModalProductComponent",
  extends: ModalProductComponent,
};
</script>

<template>
  <el-dialog
    title="Producto"
    :visible.sync="config.isVisible"
    custom-class="modal-product"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="fa fa-cube" /> Producto
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="Información General">
          <fieldset>
            <legend>Información del Producto</legend>
            <el-row class="row-bg" :gutter="20">
              <el-col :md="4" :lg="4" :xl="4">
                <el-form-item label="SKU Interno" prop="sku">
                  <el-input v-model="formData.sku" size="medium" />
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xl="4">
                <el-form-item label="Sku Internacional" prop="intSku">
                  <el-input v-model="formData.intSku" size="medium" />
                </el-form-item>
              </el-col>
              <el-col :md="16" :lg="16" :xl="16">
                <el-form-item label="Nombre" prop="name">
                  <el-input v-model="formData.name" size="medium" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Descripción" prop="description">
                  <el-input
                    v-model="formData.description"
                    size="medium"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :md="9" :lg="9" :xl="9">
                <el-form-item label="Marca" prop="brand">
                  <el-select
                    v-model="formData.brand"
                    filterable
                    clearable
                    remote
                    placeholder="Seleccionar marca"
                    :remote-method="onFindBrands"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in brands"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="9" :lg="9" :xl="9">
                <el-form-item label="Categoria" prop="category">
                  <el-select
                    v-model="formData.category"
                    filterable
                    clearable
                    remote
                    placeholder="Seleccionar categoria"
                    :remote-method="onFindProductCategories"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in productCategories"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="6" :lg="6" :xl="6">
                <el-form-item label="Unidad de Medida" prop="measureUnit">
                  <el-select
                    v-model="formData.measureUnit"
                    filterable
                    clearable
                    remote
                    placeholder="Seleccionar Unidad de Medida"
                    :remote-method="onFindMeasureUnits"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in measureUnits"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                      size="medium"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex" class="row-bg" :gutter="20">
              
              <el-col :md="5" :lg="5" :xl="5">
                <el-form-item label="Precio" prop="price" align="center">
                  <el-input-number
                    v-model="formData.price"
                    size="small"
                    :min="0"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="5" :lg="5" :xl="5">
                <el-form-item label="Contenido" prop="unitSize" align="center">
                  <el-input-number
                    v-model="formData.unitSize"
                    size="small"
                    :min="0"
                  />
                </el-form-item>
              </el-col>
              
              <el-col :md="5" :lg="5" :xl="5">
                <el-form-item label="¿Activo?" align="center" prop="isActive">
                  <el-switch
                    style="display: block"
                    v-model="formData.isActive"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Si"
                    inactive-text="No"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24" class="product-usage">
                <el-tabs v-model="tabProductUsageActive">
                  <el-tab-pane label="Etiquetas de Uso" name="product-usage">
                    <el-form-item prop="usageTag">
                      <el-tag
                        v-for="tag in usageTags"
                        :key="tag"
                        closable
                        :disable-transitions="false"
                        @close="onUsageTagsClose(tag)"
                      >
                        {{ tag }}
                      </el-tag>
                      <el-input
                        v-if="usageTagInputVisible"
                        ref="saveUsageTagInput"
                        v-model="usageTagInputValue"
                        class="input-new-tag"
                        size="mini"
                        @keyup.enter.native="onUsageTagConfirm"
                        @blur="onUsageTagConfirm"
                      />
                      <el-button
                        v-else
                        class="button-new-tag"
                        size="small"
                        @click="showUsageTagInput"
                      >
                        + Agregar Etiqueta
                      </el-button>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="Almacenamiento">
          <fieldset>
            <legend>Información del Almacenamiento</legend>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :md="8" :lg="8" :xl="8">
                <el-form-item label="Almacén" prop="warehouse">
                  <el-select
                    v-model="formData.warehouse"
                    filterable
                    clearable
                    placeholder="Seleccionar Almacen"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in warehouses"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                      size="medium"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :lg="8" :xl="8">
                <el-form-item label="Tipo de Empaque" prop="packingType">
                  <el-select
                    v-model="formData.packingType"
                    filterable
                    clearable
                    remote
                    placeholder="Seleccionar Tipo de Empaque"
                    :remote-method="onFindProductPackingTypes"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in productPackingTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="5" :lg="5" :xl="5">
                <el-form-item
                  label="Inventario Bajo"
                  prop="lowInventory"
                  align="center"
                >
                  <el-input-number
                    v-model="formData.lowInventory"
                    size="small"
                    :min="0"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
          <fieldset style="margin-top:20px;">
            <legend>Información de Embalaje</legend>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24" align="right">
                <el-button type="primary" size="small" @click="onAddPackging" icon="el-icon-circle-plus">
                  Agregar Embalaje
                </el-button>
              </el-col>
          </el-row>
          </fieldset>
          
          <el-row>
            <el-col :span="24">
              <el-table :data="formData.getPackagings()" style="width: 100%">
                <el-table-column
                  prop="Tipo de Embalaje"
                  label="Tipo de Embalaje"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.productPackingUnit"
                      filterable
                      placeholder="Seleccionar Tipo de Embalaje"
                      value-key="id"
                      size="medium"
                      :disabled="!scope.row.updateState"
                    >
                      <el-option
                        v-for="item in productPackingUnits"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Unidades de Empaque"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.qty"
                      size="small"
                      :disabled="!scope.row.updateState"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Opciones" width="180" align="center">
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        v-if="scope.row.id"
                        size="small"
                        @click="onEditPackaging(scope.$index, scope.row)"
                      >
                        Editar
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        @click="onDeletePackaging(scope.$index)"
                      >
                        Eliminar
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Imagenes">
          <el-col :md="12" :lg="10" :xl="10">
            <el-upload
              class="upload-demo"
              drag
              action=""
              :before-upload="onUploadPicture"
              multiple
              align="center"
              :show-file-list="false"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Suelta tu archivo aquí o <em>haz clic para cargar</em>
              </div>
              <div slot="tip" class="el-upload__tip">
                Solo archivos jpg/png con un tamaño menor de 500kb
              </div>
            </el-upload>
          </el-col>
          <el-col :md="12" :lg="14" :xl="14">
            <el-row
              class="row-bg"
              :gutter="20"
              v-for="rowIdx in Math.ceil(formData.getImages().length / 4)"
              :key="rowIdx"
            >
              <el-col
                v-for="(image, indexImage) in formData
                  .getImages()
                  .slice(4 * (rowIdx - 1), 4 * rowIdx)"
                :key="indexImage"
                :span="6"
                class="product__image__container"
              >
                <el-image
                  :src="image.fileUrl"
                  fit="cover"
                  :preview-src-list="mapFileUrl(formData.productImages)"
                  class="product__image"
                />
                <p>
                  <el-radio
                    @change="onChangeCoverImage"
                    v-model="formData.coverImage"
                    :label="image.fileUrl"
                    class="product__radio__selector"
                  >
                    Usar Como Principal
                  </el-radio>
                </p>

                <div class="product__remove__btn">
                  <i
                    class="el-icon-circle-close"
                    @click="onRemoveImage(indexImage)"
                  />
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
